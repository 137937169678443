import {
  validateEmail,
  validateMultiSelectField,
  validateNoSpaces,
  validateNumber,
  validatePhoneNumber,
  validateRequired,
  validateSelectField,
  validateTextFieldAlpha,
  validateVatNumber,
} from "services/validation/ValidationService";
import { ValidationRules } from "utils/TypeAnnotations";

// basic Tab
export const CompanyBasicTabValidationRules: ValidationRules = {
  vat: [
    validateRequired,
    (value: string) => validateVatNumber(value),
    validateNoSpaces,
  ],
  companyName: [validateRequired],
  officialName: [validateRequired],
  companyType: [validateSelectField],
 // bussinessUnit: [validateMultiSelectField],
};
//address tab
export const CompanyAddressTabValidationRules: ValidationRules = {
  street: [validateRequired],
  number: [validateNumber],
  zipCode: [validateRequired],
  city: [validateRequired],
  country: [validateSelectField],
  biStreet: [validateRequired],
  biZipCode: [validateRequired],
  biNumber: [validateNumber],
  biCity: [validateRequired],
  biCountry: [validateSelectField],
};


// contact person
export const CompanyContactsTabValidationRules: ValidationRules = {
  fName: [validateRequired, validateTextFieldAlpha],
  lName: [validateRequired, validateTextFieldAlpha],
  email: [validateRequired, validateEmail],
  mobileNumber: [validatePhoneNumber],
};
