import React from "react";
import { Link } from "react-router-dom";
import {
  PATH_MANAGE_EMPLOYEE_TYPE,
  PATH_MANAGE_TAGS,
  PATH_MANAGE_BUSINESS_UNIT,
  PATH_EMAIL_TEMPLATE_MANAGE,
  PATH_MANAGE_SHIFTS,
  PATH_MANAGE_TEMPLATE,
  PATH_PC_MANAGE,
  PATH_COMPETENCE_MANAGE,
  PATH_MANAGE_TIMETABLE,
  PATH_CONFIG_ELEMENTS_MANAGE,
  PATH_APPLICATION_CONFIGURATION,
  PATH_HOTLIST,
  PATH_DOCUMENT,
  MANAGE_QUESIONS,
  PATH_SALARY_BENEFITS_MANAGE,
  PATH_TODO_MANAGE,
  PATH_MANAGE_CONTRACT_TEMPLATE,
  PATH_MANAGE_TEMP_AGENCY,
  PATH_MANAGECOEFF_DEVIATION,
  PATH_MANAGE_WAGE_ELEMENT,
  PATH_MANAGE_DOCUMENT_TYPE,
  HOLIDAY_CONFIGS,
  PATH_MANAGE_TRANSLATIONS,
  PATH_MANAGE_BUSINESS_UNIT_CITIES,
} from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import RecruitmentCard from "components/atoms/RecruitmentCard";
import CardTitle from "components/atoms/CardTitle";
import Title from "components/atoms/Title";
import BackButton from "components/atoms/BackButton";
const ConfigAndSettingsPage: React.FC = () => {
  const userData = useSelector(selectAuth);
  return (
    <>
      <div className="search-bar" style={{ paddingBottom: "1vw" }}>
        <Title title={t("Configuration and settings")} />
      </div>      <div className="row recruiter-action-tabs mt-1">
        <div
          className="col-md-12 d-grid"
          style={{
            marginBottom: "1.5vw",
            paddingLeft: "0.75vw",
            paddingRight: "0.75vw",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "1.5vw",
          }}
        >
          {/* Application  and configuration*/}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Application configuration",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_APPLICATION_CONFIGURATION}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Application configurations")}</div>
            </Link>
          </AccessControl>
          {/* Business unit */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Business unit",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={PATH_MANAGE_BUSINESS_UNIT}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <RecruitmentCard
                className={`d-flex justify-content-between align-items-center w-100 h-100`}
              >
                <CardTitle
                  title={t("Business unit")}
                  className={`dashboard-card-title`}
                />
              </RecruitmentCard>
            </Link>
          </AccessControl>
          {/* Competence */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Competence",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_COMPETENCE_MANAGE}?type=general`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Competence")}</div>
            </Link>
          </AccessControl>

          {/* Confif elements */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Config elements",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_CONFIG_ELEMENTS_MANAGE}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Config elements")}</div>
            </Link>
          </AccessControl>
          {/* Contract template */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Contract template",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_MANAGE_CONTRACT_TEMPLATE}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Contract template")}</div>
            </Link>
          </AccessControl>
          {/* Document type */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Document type",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_MANAGE_DOCUMENT_TYPE}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Document type")}</div>
            </Link>
          </AccessControl>
          {/* email */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Email template",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={PATH_EMAIL_TEMPLATE_MANAGE}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Email templates")}</div>
            </Link>
          </AccessControl>
          {/* employee type */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Employee type",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_MANAGE_EMPLOYEE_TYPE}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Employee Type")}</div>
            </Link>
          </AccessControl>
          {/* Holiday */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Holiday Config",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link to={HOLIDAY_CONFIGS}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Holiday settings")}</div>

            </Link>
          </AccessControl>
          {/* Paritair committe */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Paritair commitee",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={PATH_PC_MANAGE}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Paritair comitee")}</div>
            </Link>
          </AccessControl>
          {/* Projects */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Templates",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={PATH_MANAGE_TEMPLATE}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Project template")}</div>
            </Link>
          </AccessControl>
          {/* Questions */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Questions",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${MANAGE_QUESIONS}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Questions")}</div>
            </Link>
          </AccessControl>
          {/* Salary benefits */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Salary benefits",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_SALARY_BENEFITS_MANAGE}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Salary benefits")}</div>
            </Link>
          </AccessControl>
          {/* Shifts */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Shifts",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={PATH_MANAGE_SHIFTS}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Shifts")}</div>
            </Link>
          </AccessControl>
          {/* timetable */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Timetable",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={PATH_MANAGE_TIMETABLE}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Timetable")}</div>
            </Link>
          </AccessControl>
          {/* Todo */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Todo",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_TODO_MANAGE}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Todo")}</div>
            </Link>
          </AccessControl>

          {/* Translation */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Translations",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link to={PATH_MANAGE_TRANSLATIONS}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Translations")}</div>

            </Link>
          </AccessControl>
          {/*Wage elements */}

          <AccessControl
            requiredPermissions={[
              {
                permission: "Wage elements",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_MANAGE_WAGE_ELEMENT}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Wage element")}</div>
            </Link>
          </AccessControl>

          {/* Coefficient deviation */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Coefficient deviation",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_MANAGECOEFF_DEVIATION}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Coefficient deviation")}</div>
            </Link>
          </AccessControl>
          {/* Business unit cities */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Business unit cities",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_MANAGE_BUSINESS_UNIT_CITIES}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Business unit cities")}</div>
            </Link>
          </AccessControl>
          {/* <Link to="/manage-city-profile" className='card border-0 card-shadow justify-content-center h-100 rounded-3s'>
                       <div className="tab">
                           Manage city profile
                       </div>
                   </Link>
                   <Link to="/manage/function-profile" className='card border-0 card-shadow justify-content-center h-100 rounded-3s'>
                       <div className="tab">
                           Manage function profile
                       </div>
                   </Link>
                   <Link to="/manage-checklist" className='card border-0 card-shadow justify-content-center h-100 rounded-3s'>
                       <div className="tab">
                           Document checklist
                       </div>
                   </Link>
                   <Link to="/manage/default/low-coefficients" className='card border-0 card-shadow justify-content-center h-100 rounded-3s'>
                       <div className="tab">
                           Manage low coefficients
                       </div>
                  </Link>
                   <Link to="/work-type/extra" className='card border-0 card-shadow justify-content-center h-100 rounded-3s'>
                       <div className="tab">
                           Work type extra
                       </div>
                   </Link>
                   <Link to="/manage-extras" className='card border-0 card-shadow justify-content-center h-100 rounded-3s'>
                       <div className="tab">
                           Manage extras
                       </div>
                   </Link> */}
          {/* <Link to="/manage/inflations" className='card border-0 card-shadow justify-content-center h-100 rounded-3s'>
                       <div className="tab">
                           Manage inflations
                       </div>
                   </Link> */}
          {/* <AccessControl
            requiredPermissions={[
              {
                permission: "Hotlist",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_HOTLIST}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Hotlist")}</div>
            </Link>
          </AccessControl> */}
          {/* <AccessControl
            requiredPermissions={[
              {
                permission: "Document",
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <Link
              to={`${PATH_DOCUMENT}`}
              className="card border-0 card-shadow justify-content-center h-100"
              style={{ padding: "2vw" }}
            >
              <div className="tab">{t("Documents")}</div>
            </Link>
          </AccessControl> */}
        </div>
      </div>
{/* 
      <div className="row mb-3">
        <div className="col-md-6 align-self-center">
          <BackButton />
        </div>
      </div> */}
    </>
  );
};

export default ConfigAndSettingsPage;
