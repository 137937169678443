import React from "react";

import TempAgencyGeneralForm from ".";
import { FormProvider } from "./context/Context";

export const TempAgencyGeneralProvider = () => {
    return (
        <>
            <FormProvider>
                <TempAgencyGeneralForm />
            </FormProvider>
        </>
    );
};
