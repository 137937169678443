import CustomNotify from "components/atoms/CustomNotify";
import Title from "components/atoms/Title";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GET_BUSINESS_UNIT_CITIES, STORE_BUSINESS_UNIT_CITY_DETAILS } from "routes/ApiEndpoints";
import AccessControl from "services/AccessControl";
import { ApiCall } from "services/ApiServices";
import { validateForm, validateMultiSelectField } from "services/validation/ValidationService";
import { Option, ValidationRules } from "utils/TypeAnnotations";
import { t } from "../translation/Translation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Button from "components/atoms/Button";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_MANAGE_BUSINESS_UNIT_CITIES } from "constants/Paths";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface BusinessUnitProps {
    businessUnits: object[];
    cities: object[];
}

interface DropdownProps {
    businessUnitList: object[],
    citiesList: object[]
}

const CreateBusinessUnitCities: React.FC = () => {
    const userData = useSelector(selectAuth);
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const isEdit = id ? true : false;
    const [formData, setFormData] = useState<BusinessUnitProps>({
        businessUnits: [],
        cities: [],
    });
    const [existingBUnits, setExistingBUnits] = useState<Option[]>([]);
    const [existingCities, setExistingCities] = useState<Option[]>([]);

    const [dropdownData, SetDropdownData] = useState<DropdownProps>();
    const [errors, setErrors] = useState<{ [key: string]: string }>({
        businessUnits: "",
        cities: "",
    });

    const validation = (
        name: string,
        value: string | boolean | null | number | undefined | object[],
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            businessUnits: [validateMultiSelectField],
            cities: [validateMultiSelectField],
        };

        const validationErrors = validateForm(
            { ...formData, [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );
        if (isSingleFieldValidation) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: validationErrors[name],
            }));
        } else {
            setErrors(validationErrors);
        }

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        const fetchBusinessCitiesDetails = async () => {
            const url = isEdit ? `${GET_BUSINESS_UNIT_CITIES}/${id}` : GET_BUSINESS_UNIT_CITIES;
            const response = await ApiCall.getService(url, "GET", M_MASTER_DATA, true);
            if (response?.status === 200) {
                const { businessUnits, cities, dropdownData } = response.data;
                SetDropdownData((prevData: any) => ({
                    ...prevData,
                    businessUnitList: dropdownData?.businessunitList,
                    citiesList: dropdownData?.citiesList
                }));
                if (isEdit) {
                    setExistingCities(cities);
                    setExistingBUnits(businessUnits);
                    setFormData((prevData) => ({
                        ...prevData,
                        businessUnits: businessUnits,
                        cities: cities,
                    }));
                }
            }
        };
        fetchBusinessCitiesDetails();
    }, []);

    const handleSelectChange = (selectedOption: any, name: string) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: selectedOption !== null ? selectedOption : "",
        }));
        // Filter the difference (removed options)
        let removedBUOptions: any = [];
        let removedCityOptions: any = [];

        if (name === "businessUnits") {
            removedBUOptions = existingBUnits && existingBUnits.filter((option: any) => {
                return !selectedOption.some((newOption: any) => newOption.value === option.value);
            });
        } else if (name === "cities") {
            removedCityOptions = existingCities && existingCities.filter((option: any) => {
                return !selectedOption.some((newOption: any) => newOption.value === option.value);
            });
        }

        if (removedBUOptions?.length > 0) {
            const filterRemovedBUOptions = removedBUOptions?.filter((option: any) =>
                !dropdownData?.businessUnitList.some((ddOption: any) => ddOption?.value === option?.value)
            );

            SetDropdownData((prevData: any) => ({
                ...prevData,
                businessUnitList: [...prevData.businessUnitList, ...filterRemovedBUOptions],
                citiesList: dropdownData?.citiesList
            }));
        }
        if (removedCityOptions?.length > 0) {
            const filterRemovedCityOptions = removedCityOptions?.filter((option: any) =>
                !dropdownData?.citiesList.some((ddOption: any) => ddOption?.value === option?.value)
            );
            SetDropdownData((prevData: any) => ({
                ...prevData,
                businessUnitList: dropdownData?.businessUnitList,
                citiesList: [...prevData.citiesList, ...filterRemovedCityOptions]
            }));
        }
        validation(name, selectedOption, true);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const { name, value } = event.target as HTMLInputElement;
        if (validation(name, value)) {
            const url = isEdit ? `${STORE_BUSINESS_UNIT_CITY_DETAILS}/${id}` : STORE_BUSINESS_UNIT_CITY_DETAILS;
            const response = await ApiCall.service(
                url,
                "POST",
                formData,
                true,
                M_MASTER_DATA
            );
            if (response.status === 200) {
                CustomNotify({ type: "success", message: t(response.message) });
                navigate("/manage/business-unit-cities");
            }
        }
    };

    const permissionType = id ? "update" : "create";
    const permissionObject: any = {
        permission: "Business unit cities",
    };
    permissionObject[permissionType] = true;

    return (
        <AccessControl
            requiredPermissions={[permissionObject]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <>
                <Title title={isEdit ? t("Edit business unit cities") : t("Create business unit cities")} />
                <form action="" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-border">
                                <div className="row ">
                                    <div className="col-md-6">
                                        <SelectWithSearch
                                            title={t("Business units")}
                                            name="businessUnits"
                                            isMandatory={true}
                                            search={true}
                                            options={dropdownData?.businessUnitList}
                                            onChange={(e) => handleSelectChange(e, "businessUnits")}
                                            isMulti={true}
                                            value={formData.businessUnits}
                                            // isDisabled={isEdit}
                                            placeHolder={t("Buisness units")}
                                            error={errors?.businessUnits}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <SelectWithSearch
                                            title={t("Cities")}
                                            name="cities"
                                            isMandatory={true}
                                            search={true}
                                            options={dropdownData?.citiesList}
                                            onChange={(e) => handleSelectChange(e, "cities")}
                                            isMulti={true}
                                            value={formData.cities}
                                            error={errors.cities}
                                            placeHolder={t("Cities")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row backPadding">
                        <div className="col-md-4 align-self-center">
                            <Link
                                to={`${PATH_MANAGE_BUSINESS_UNIT_CITIES}`}
                                className=" back-btn text-decoration-underline"
                            >
                                {t("Back")}
                            </Link>
                        </div>
                        <div className="col-md-8 text-end">
                            <Button
                                title={t("Save")}
                                type="submit"
                                className="form-button shadow-none"
                            />
                        </div>
                    </div>
                </form>
            </>
        </AccessControl>
    );
};

export default CreateBusinessUnitCities;
