import { Option, scrollToTop } from "services/validation/ValidationService";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useFormContext } from "../context/Context";
import { ValidationRules } from "utils/TypeAnnotations";
import {
  validateForm,
  validateNumber,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { AddressTabData } from "../context/Interfaces";
import AddAddressLoactionViaMap, {
  AddressDetails,
} from "utils/AddAddressLoactionViaMap";
import ActiveLocation from "static/images/ActiveLocation";
import { t } from "pages/microservices/masterData/translation/Translation";
import LabelField from "components/atoms/LabelField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import CheckBoxField from "components/atoms/CheckBoxField";
import {
  UPDATE_COMPANY_ADDRESS_ERROR,
  UPDATE_COMPANY_ADDRESS_FIELD,
  UPDATE_COMPANY_LOCATION_FIELD,
} from "../context/Constants";
import { getCityAndCountry } from "services/util/UtilService";

// import AddAddressLoactionViaMap, { AddressDetails } from "utils/AddAddressLoactionViaMap";
interface ICompanyFormAddressTabProps {
  type?: string | any;
}
const CompanyAddress: React.FC<ICompanyFormAddressTabProps> = (type) => {
  const { state, dispatch } = useFormContext();
  const { companyId } = useParams<{ companyId: string }>();

  const isViewMode = state.isViewMode;

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | null | Option | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      // location: [validateRequired],
      street: [validateRequired],
      number: [validateRequired, validateNumber],
      zipCode: [validateRequired],
      city: [validateRequired],
      country: [validateSelectField],
      biStreet: [validateRequired],
      biZipCode: [validateRequired],
      biNumber: [validateRequired, validateNumber],
      biCity: [validateRequired],
      biCountry: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...state.companyAddress.data, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    dispatch({
      type: UPDATE_COMPANY_ADDRESS_ERROR,
      fieldName: name,
      error: validationErrors[name],
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({
        type: UPDATE_COMPANY_ADDRESS_ERROR,
        fieldName: name,
        error: "",
      });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const copyBillingFieldsFromOfficial = (isChecked: boolean) => {
    if (isChecked) {
      const billingFields = [
        "street",
        "number",
        "box",
        "zipCode",
        "city",
        "country",
        "initialLat",
        "initialLn",
      ];
      billingFields.forEach((fieldName) => {
        const billingFieldName =
          "bi" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        const fieldValue =
          state.companyAddress.data[fieldName as keyof AddressTabData];
        dispatch({
          type: UPDATE_COMPANY_ADDRESS_FIELD,
          field: billingFieldName,
          value: fieldValue,
        });
        validation(billingFieldName, fieldValue, true);
      });
    } else {
      const billingFields = [
        "biStreet",
        "biNumber",
        "biBox",
        "biZipCode",
        "biCity",
        "biCountry",
        "biInitialLat",
        "biInitialLn",
      ];
      billingFields.forEach((fieldName) => {
        dispatch({
          type: UPDATE_COMPANY_ADDRESS_FIELD,
          field: fieldName,
          value: "",
        });
        validation(fieldName, "", true);
      });
    }
  };
  const handleLocationAddressUpdate = (isChecked: boolean) => {
    if (isChecked) {
      const locationAddressFields = [
        { field: "locationStreet", value: state.companyAddress.data.street },
        { field: "locationNumber", value: state.companyAddress.data.number },
        { field: "locationBox", value: state.companyAddress.data.box },
        { field: "locationZipCode", value: state.companyAddress.data.zipCode },
        { field: "locationCity", value: state.companyAddress.data.city },
        { field: "locationCountry", value: state.companyAddress.data.country },
        { field: "initialLat", value: state.companyAddress.data.initialLat },
        { field: "initialLn", value: state.companyAddress.data.initialLn },
      ];
      dispatch({
        type: UPDATE_COMPANY_LOCATION_FIELD,
        field: "locationName",
        value: `${state.companyBasic.data.companyName}-HQ`,
      });

      locationAddressFields.forEach((field) => {
        dispatch({
          type: UPDATE_COMPANY_LOCATION_FIELD,
          field: field.field,
          value: field.value,
        });
        const locationAddressFields = [
          { field: "locationPhoneNumber", value: "" },
          { field: "locationName", value: "" },
          { field: "locationStreet", value: "" },
          { field: "locationNumber", value: "" },
          { field: "locationBox", value: "" },
          { field: "locationZipCode", value: "" },
          { field: "locationCity", value: "" },
          { field: "locationCountry", value: "" },
        ];
        locationAddressFields.forEach((field) => {
          //   dispatch({
          //     type: "UPDATE_FIELD_ERROR",
          //     fieldName: field.field,
          //     error: field.value,
          //   });
        });
        // validation(field.field, field.field, true);
      });
    } else {
      const locationAddressFields = [
        { field: "locationStreet", value: "" },
        { field: "locationNumber", value: "" },
        { field: "locationBox", value: "" },
        { field: "locationZipCode", value: "" },
        { field: "locationCity", value: "" },
        { field: "locationCountry", value: "" },
      ];
      locationAddressFields.forEach((field) => {
        // dispatch({
        //   type: "UPDATE_COMPANYLOCATION_FIELD",
        //   field: field.field,
        //   value: field.value,
        // });
      });
    }
  };

  // Helper function to update billing address fields
  const updateBillingAddressFields = (city: string, country: string) => {
    const countryValue = getCountryValue(country);

    dispatch({
      type: UPDATE_COMPANY_ADDRESS_FIELD,
      field: "biCity",
      value: city,
    });
    dispatch({
      type: UPDATE_COMPANY_ADDRESS_ERROR,
      fieldName: "biCity",
      error: "",
    });
    dispatch({
      type: UPDATE_COMPANY_ADDRESS_FIELD,
      field: "biCountry",
      value: countryValue,
    });
    dispatch({
      type: UPDATE_COMPANY_ADDRESS_ERROR,
      fieldName: "biCountry",
      error: "",
    });
  };

  // Helper function to update primary address fields
  const updatePrimaryAddressFields = (city: string, country: string) => {
    const countryValue = getCountryValue(country);

    dispatch({
      type: UPDATE_COMPANY_ADDRESS_FIELD,
      field: "city",
      value: city,
    });
    dispatch({
      type: UPDATE_COMPANY_ADDRESS_ERROR,
      fieldName: "city",
      error: "",
    });
    dispatch({
      type: UPDATE_COMPANY_ADDRESS_FIELD,
      field: "country",
      value: countryValue,
    });
    dispatch({
      type: UPDATE_COMPANY_ADDRESS_ERROR,
      fieldName: "country",
      error: "",
    });
  };

  // Helper function to get country value from the country name
  const getCountryValue = (country: string) => {
    const countryValue: any =
      country !== "Error"
        ? state.options.countryList.find(
            (coun: any) => coun?.label?.toLowerCase() === country?.toLowerCase()
          )
        : null;
    return countryValue;
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    // Handle checkbox fields
    if (type === "checkbox") {
      const newValue = checked ? 1 : 0;
      dispatch({
        type: UPDATE_COMPANY_ADDRESS_FIELD,
        field: name,
        value: newValue,
      });

      validation(name, checked, true);

      if (name === "sameAddress") {
        copyBillingFieldsFromOfficial(checked);
      }

      if (name === "hqAddress") {
        handleLocationAddressUpdate(checked);
      }

      return;
    }

    if (name === "biZipCode") {
      const cityPromise = getCityAndCountry(value);

      cityPromise.then((response: { city: string; country: string }) => {
        const { city, country } = response;
        updateBillingAddressFields(city, country);
      });
      dispatch({
        type: UPDATE_COMPANY_ADDRESS_FIELD,
        field: name,
        value,
      });

      validation(name, value, true);
    }
    // Handle non-checkbox fields
    if (name === "zipCode") {
      const cityPromise = getCityAndCountry(value);

      cityPromise.then((response: { city: string; country: string }) => {
        const { city, country } = response;

        if (state.companyAddress.data.sameAddress) {
          updateBillingAddressFields(city, country);
        }

        updatePrimaryAddressFields(city, country);
      });
      dispatch({
        type: UPDATE_COMPANY_ADDRESS_FIELD,
        field: name,
        value,
      });

      validation(name, value, true);
      if (state.companyAddress.data.sameAddress) {
        const billingFieldName = `bi${name.charAt(0).toUpperCase()}${name.slice(
          1
        )}`;

        dispatch({
          type: UPDATE_COMPANY_ADDRESS_FIELD,
          field: billingFieldName,
          value,
        });

        validation(billingFieldName, value, true);
      }
    } else {
      // Update primary address field
      dispatch({
        type: UPDATE_COMPANY_ADDRESS_FIELD,
        field: name,
        value,
      });

      validation(name, value, true);

      if (state.companyAddress.data.sameAddress) {
        const billingFieldName = `bi${name.charAt(0).toUpperCase()}${name.slice(
          1
        )}`;

        dispatch({
          type: UPDATE_COMPANY_ADDRESS_FIELD,
          field: billingFieldName,
          value,
        });

        validation(billingFieldName, value, true);
      }
    }
  };
  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: UPDATE_COMPANY_ADDRESS_FIELD,
      field: name,
      value: selectedOption,
    });

    if (state.companyAddress.data.sameAddress) {
      const billingFieldName =
        "bi" + name.charAt(0).toUpperCase() + name.slice(1);

      dispatch({
        type: UPDATE_COMPANY_ADDRESS_FIELD,
        field: billingFieldName,
        value: selectedOption,
      });
      validation(billingFieldName, selectedOption, true);
    }
    validation(name, selectedOption, true);
  };
  const handleConfirm = (details: AddressDetails | null) => {
    if (details) {
      const { street, nr, bus, postal, city, country, coordinates } = details;
      let companyOption: any = []; // Declare it as an empty array or object

      // Find the country option if country is not empty
      if (country !== "") {
        companyOption =
          state.options.countryList.find((option: any) =>
            option.label.toLowerCase().includes(country.toLowerCase())
          ) || {}; // If no match found, default to an empty object
      }

      // Ensure coordinates is in the correct format before processing
      if (
        coordinates &&
        coordinates.includes("Lat: ") &&
        coordinates.includes("Lng: ")
      ) {
        const [lat, lng] = coordinates
          .replace("Lat: ", "")
          .replace("Lng: ", "")
          .split(", ")
          .map(Number); // Convert the values to numbers
        handleAddressUpdate(
          street,
          nr,
          bus,
          postal,
          city,
          companyOption,
          lat,
          lng
        );
      } else {
        console.error("Invalid coordinates format");
      }
    }

    setShowPopup(false); // Close the popup after confirming the address
  };

  const handleBillingConfirm = (details: AddressDetails | null) => {
    if (details) {
      const { street, nr, bus, postal, city, country, coordinates } = details;
      let companyOption: any = []; // Declare it as an empty array or object

      // Find the country option if country is not empty
      if (country !== "") {
        companyOption =
          state.options.countryList.find((option: any) =>
            option.label.toLowerCase().includes(country.toLowerCase())
          ) || {}; // If no match found, default to an empty object
      }

      // Ensure coordinates is in the correct format before processing
      if (
        coordinates &&
        coordinates.includes("Lat: ") &&
        coordinates.includes("Lng: ")
      ) {
        const [lat, lng] = coordinates
          .replace("Lat: ", "")
          .replace("Lng: ", "")
          .split(", ")
          .map(Number); // Convert the values to numbers
        handleBillingAddressUpdate(
          street,
          nr,
          bus,
          postal,
          city,
          companyOption,
          lat,
          lng
        );
      } else {
        console.error("Invalid coordinates format");
      }
    }

    setShowBillingPopup(false); // Close the popup after confirming the address
  };
  const [showPopup, setShowPopup] = useState(false);
  const [showBillingPopup, setShowBillingPopup] = useState(false);
  const handleAddPopup = () => {
    setShowPopup(true); // Close the popup without saving
  };
  const handleClosePopup = () => {
    setShowPopup(false); // or whatever logic you have to close the popup
  };
  const handleAddressUpdate = (
    street: string,
    number: string,
    unit: string,
    postal: string,
    city: string,
    countryValue: string,
    lat: number | string | any,
    lng: number | string | any
  ) => {
    const addressFields = [
      { field: "street", value: street },
      { field: "number", value: number },
      { field: "box", value: unit },
      { field: "zipCode", value: postal },
      { field: "city", value: city },
      { field: "country", value: countryValue },
      { field: "initialLat", value: lat },
      { field: "initialLn", value: lng },
    ];

    addressFields.forEach((field) => {
      dispatch({
        type: UPDATE_COMPANY_ADDRESS_FIELD,
        field: field.field,
        value: field.value,
      });

      dispatch({
        type: UPDATE_COMPANY_ADDRESS_ERROR,
        fieldName: field.field,
        error: "",
      });
      if (
        state.companyAddress.data.sameAddress === 1 ||
        state.companyAddress.data.sameAddress === true
      ) {
        dispatch({
          type: UPDATE_COMPANY_ADDRESS_FIELD,
          field: `bi${
            field.field.charAt(0).toUpperCase() + field.field.slice(1)
          }`,
          value: field.value,
        });
        dispatch({
          type: UPDATE_COMPANY_ADDRESS_ERROR,
          fieldName: `bi${
            field.field.charAt(0).toUpperCase() + field.field.slice(1)
          }`,
          error: "",
        });
      }
    });
  };
  const handleBillingAddressUpdate = (
    street: string,
    number: string,
    unit: string,
    postal: string,
    city: string,
    countryValue: string,
    lat: number | string | any,
    lng: number | string | any
  ) => {
    const addressFields = [
      { field: "biStreet", value: street },
      { field: "biNumber", value: number },
      { field: "biBox", value: unit },
      { field: "biZipCode", value: postal },
      { field: "biCity", value: city },
      { field: "biCountry", value: countryValue },
      { field: "biInitialLat", value: lat },
      { field: "biInitialLn", value: lng },
    ];

    addressFields.forEach((field) => {
      dispatch({
        type: UPDATE_COMPANY_ADDRESS_FIELD,
        field: field.field,
        value: field.value,
      });
      dispatch({
        type: UPDATE_COMPANY_ADDRESS_ERROR,
        fieldName: field.field,
        error: "",
      });
    });
  };

  const renderAddressGoogleMap = () => {
    return (
      <div style={{ marginLeft: "1vw" }}>
        <div className="d-flex align-items-center">
          <Link
            to={""}
            title={t("Search address via google map...")}
            onClick={handleAddPopup}
            className="color-dark-pink marginRightPoint5"
          >
            <ActiveLocation />
          </Link>
          <Link to={""} className="link-color" onClick={handleAddPopup}>
            {t("Search address via google map...")}
          </Link>
        </div>
        <AddAddressLoactionViaMap
          initialLat={
            state.companyAddress.data.initialLat === ""
              ? 50.8503
              : parseFloat(state.companyAddress.data.initialLat)
          } // Replace with your initial latitude
          initialLng={
            state.companyAddress.data.initialLn === ""
              ? 4.3517
              : parseFloat(state.companyAddress.data.initialLn)
          } // Replace with your initial longitude
          onConfirm={handleConfirm}
          showAddPopup={showPopup}
          onHide={handleClosePopup}
        />
      </div>
    );
  };
  const handleBillingAddPopup = () => {
    setShowBillingPopup(true);
  };
  const handleBilligClosePopup = () => {
    setShowBillingPopup(false);
  };
  const renderBillingAddressGoogleMap = () => {
    return (
      <div style={{ marginLeft: "1vw" }}>
        <div className="d-flex align-items-center">
          <Link
            to={""}
            title={t("Search address via google map...")}
            onClick={handleBillingAddPopup}
            className="color-dark-pink marginRightPoint5"
          >
            <ActiveLocation />
          </Link>
          <Link to={""} className="link-color" onClick={handleBillingAddPopup}>
            {t("Search address via google map...")}
          </Link>
        </div>
        <AddAddressLoactionViaMap
          initialLat={
            state.companyAddress.data.biInitialLat === ""
              ? 50.8503
              : parseFloat(state.companyAddress.data.biInitialLat)
          } // Replace with your initial latitude
          initialLng={
            state.companyAddress.data.biInitialLn === ""
              ? 4.3517
              : parseFloat(state.companyAddress.data.biInitialLn)
          } // Replace with your initial longitude
          onConfirm={handleBillingConfirm}
          showAddPopup={showBillingPopup}
          onHide={handleBilligClosePopup}
        />
      </div>
    );
  };
  return (
    <div className="row">
      <div className="col-12">
        <div
          className="form-border"
          style={{ paddingTop: "3vw", paddingBottom: "1vw" }}
        >
          <div className="row marginBotttom1">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <LabelField
                  title={t("Official address")}
                  className="tab-subtitle pb-0"
                />
                {!isViewMode && renderAddressGoogleMap()}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6">
              <LabelWithInputField
                isMandatory={true}
                name="street"
                handleChange={handleFieldChange}
                value={state.companyAddress.data.street}
                id="street"
                label="Street"
                type="text"
                error={state.companyAddress.errors.street}
                isDisabled={isViewMode}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="row">
                <div className="col-sm-12 col-md-8">
                  <LabelWithInputField
                    isMandatory={true}
                    name="number"
                    handleChange={handleFieldChange}
                    value={state.companyAddress.data.number}
                    id="number"
                    label="Number"
                    type="text"
                    error={state.companyAddress.errors.number}
                    isDisabled={isViewMode}
                  />
                </div>
                <div className="col-sm-12 col-md-4">
                  <LabelWithInputField
                    isMandatory={false}
                    name="box"
                    handleChange={handleFieldChange}
                    value={state.companyAddress.data.box}
                    id="box"
                    label="Box"
                    type="text"
                    isDisabled={isViewMode}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <LabelWithInputField
                isMandatory={true}
                name="zipCode"
                handleChange={handleFieldChange}
                value={state.companyAddress.data.zipCode}
                id="zipCode"
                label="Zip code"
                type="text"
                error={state.companyAddress.errors.zipCode}
                isDisabled={isViewMode}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <LabelWithInputField
                isMandatory={true}
                name="city"
                handleChange={handleFieldChange}
                value={state.companyAddress.data.city}
                id="city"
                label="City"
                type="text"
                error={state.companyAddress.errors.city}
                isDisabled={isViewMode}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <SelectWithSearch
                title="Country"
                name="country"
                isMandatory={true}
                search={true}
                options={state.options.countryList}
                placeHolder="Select"
                value={state.companyAddress.data.country}
                onChange={(e) => handleSelectChange(e, "country")}
                isMulti={false}
                className="select-field"
                error={state.companyAddress.errors.country}
                isDisabled={isViewMode}
              />
            </div>

            <div className="col-md-12 mt-2" style={{ marginBottom: "1vw" }}>
              <CheckBoxField
                label="This is the Headquarters address"
                name="hqAddress"
                onChangeHandler={handleFieldChange}
                isChecked={state.companyAddress.data.hqAddress === 1}
                disable={isViewMode}
                id="hqAdress"
                lineHeight="1.6vw"
              />
            </div>
            <div className="col-md-12">
              <CheckBoxField
                label="Same as official address"
                name="sameAddress"
                onChangeHandler={handleFieldChange}
                isChecked={
                  state.companyAddress.data.sameAddress === 1 ||
                  state.companyAddress.data.sameAddress === true
                }
                disable={isViewMode}
                id="sameAddress"
                lineHeight="1.6vw"
              />
            </div>
            <div className="col-md-12" style={{ marginTop: "1vw" }}>
              <div className="d-flex align-items-center">
                <LabelField
                  title={t("Billing addressss")}
                  className="tab-subtitle pb-0"
                />
                {state.companyAddress.data.sameAddress !== 1 &&
                  state.companyAddress.data.sameAddress !== true &&
                  !isViewMode &&
                  renderBillingAddressGoogleMap()}
              </div>
            </div>

            <div className="col-sm-12 col-md-6">
              <LabelWithInputField
                isMandatory={true}
                name="biStreet"
                handleChange={handleFieldChange}
                value={state.companyAddress.data.biStreet}
                isDisabled={
                  state.companyAddress.data.sameAddress === 1 ||
                  state.companyAddress.data.sameAddress === true ||
                  isViewMode
                }
                id="street"
                label="Street"
                type="text"
                error={state.companyAddress.errors.biStreet}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="row">
                <div className="col-sm-12 col-md-8">
                  <LabelWithInputField
                    isMandatory={true}
                    name="biNumber"
                    handleChange={handleFieldChange}
                    value={state.companyAddress.data.biNumber}
                    isDisabled={
                      state.companyAddress.data.sameAddress === 1 ||
                      state.companyAddress.data.sameAddress === true ||
                      isViewMode
                    }
                    id="number"
                    label="Number"
                    type="text"
                    error={state.companyAddress.errors.biNumber}
                  />
                </div>
                <div className="col-sm-12 col-md-4">
                  <LabelWithInputField
                    isMandatory={false}
                    name="biBox"
                    handleChange={handleFieldChange}
                    value={state.companyAddress.data.biBox}
                    isDisabled={
                      state.companyAddress.data.sameAddress === 1 ||
                      state.companyAddress.data.sameAddress === true ||
                      isViewMode
                    }
                    id="box"
                    label="Box"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <LabelWithInputField
                isMandatory={true}
                name="biZipCode"
                handleChange={handleFieldChange}
                value={state.companyAddress.data.biZipCode}
                isDisabled={
                  state.companyAddress.data.sameAddress === 1 ||
                  state.companyAddress.data.sameAddress === true ||
                  isViewMode
                }
                id="zipCode"
                label="Zip code"
                type="text"
                error={state.companyAddress.errors.biZipCode}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <LabelWithInputField
                isMandatory={true}
                name="biCity"
                handleChange={handleFieldChange}
                value={state.companyAddress.data.biCity}
                isDisabled={
                  state.companyAddress.data.sameAddress === 1 ||
                  state.companyAddress.data.sameAddress === true ||
                  isViewMode
                }
                id="biCity"
                label="City"
                type="text"
                error={state.companyAddress.errors.biCity}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <SelectWithSearch
                title="Country"
                name="biCountry"
                isMandatory={true}
                search={true}
                placeHolder="Select"
                options={state.options.countryList}
                isDisabled={
                  state.companyAddress.data.sameAddress === 1 ||
                  state.companyAddress.data.sameAddress === true ||
                  isViewMode
                }
                value={state.companyAddress.data.biCountry}
                onChange={(e) => handleSelectChange(e, "biCountry")}
                isMulti={false}
                error={state.companyAddress.errors.biCountry}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAddress;
