import { useFormContext } from "pages/microservices/companyCreation/context/Context";

import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { Navigation } from "../formNavigation/Navigation";
import SelectWithSearch from "components/atoms/SelectWithSearch";

import { useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { t } from "pages/microservices/masterData/translation/Translation";

import Button from "components/atoms/Button";

import { M_COMPANY_CREATION, M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import {
  scrollToTop,
  validateFileRequired,
  validateForm,
  validateNumber,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import * as END_POINTS from "routes/ApiEndpoints";
import { Option, ValidationRules } from "utils/TypeAnnotations";

import {
  companyLocationProps,
  ContactProps,
} from "../../annotations/CompanyAnnotations";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import LabelField from "components/atoms/LabelField";
import ModalPopup from "components/atoms/ModalPopup";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import { UploadCertificate } from "pages/microservices/CandidateCreation/annotations/CandidateInterface";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import { log } from "console";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { DYNAMIC_TODO_CREATION } from "routes/ApiEndpoints";

interface DocumentProps {
  [key: string]: string | Date | number | null | boolean | Option | object[];
  id: number | null;
  documentName: string;
  documentCategory: Option | null;
  documentId: number | null;
  fileName: string;
  filePath: string | null;
  extension: string;
  uploadedBy: string;
  createdAt: string;
  editId: number | null;
}
export interface documents {
  id: number | null;
  editId: number | null;
  fileUpload: {
    accessUrl: string;
    fileName: string;
  };
  fileName: string;
  fileExtension: string;
  category: Option | null;
  uploadedBy: string | null;
  createdAt: string | null;
}

interface SearchFields {
  documentCategory: { value: number; label: string } | null;
  extension: { value: string; label: string } | null;
  documentName: string;
}
const CompanyDocument = () => {
  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();
  const [countryId, setCountryId] = useState<number | null>(null);
  const [index, setIndex] = useState(0);
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const userAuth = useSelector(selectAuth);
  const documentInitialState = {
    id: null,
    editId: null,
    fileUpload: {
      accessUrl: "", // Initialize with an empty string
      fileName: "", // Initialize with an empty string
    },
    fileName: "",
    fileExtension: "",
    category: null,
    uploadedBy: "",
    createdAt: "",
  };
  const extensionOptions = [
    { value: 1, label: "doc" },
    { value: 2, label: "docx" },
    { value: 3, label: "jpeg" },
    { value: 4, label: "jpg" },
    { value: 5, label: "pdf" },
    { value: 6, label: "png" },
  ];

  const [searchFields, setSearchFields] = useState<SearchFields>({
    documentCategory: null,
    extension: null,
    documentName: "",
  });
  const { companyId } = useParams<{ companyId: string }>();
  const location = useLocation();
  const [formData, setFormData] = useState<documents>(documentInitialState);

  // Check if 'mode=view' is present in the query string
  const isViewMode = location.search.includes("mode=view");
  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const { name, value, type, checked } = event.target;
    const { name, value, type, checked } = event.target as HTMLInputElement;
    let updatedValue: string | number | null = value;
    if (type === "checkbox") {
      updatedValue = checked ? 1 : 0;
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
    validation(name, updatedValue, true);
  };
  const validation = (
    name: string,
    value: string | number | null | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      fileUpload: [
        (value: { fileName: any }) =>
          value?.fileName ? null : "File is required",
      ],
      fileName: [validateRequired],
      category: [validateSelectField],
    };
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: selectedOption,
    }));
    //Filters
    // setSearch((prevData: any) => ({
    //   ...prevData,
    //   [fieldName]: selectedOption,
    // }));
    validation(name, selectedOption, true);
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: true,
      // isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let finalCompanyLocation = null;
    let finalCompanyContact = null;
    if (state.companyLocationTable.length === 0) {
      finalCompanyLocation = [state.companyLocation];
    } else {
      const specificLocationFields: Array<keyof companyLocationProps> = [
        "locationName",
        "locationPhoneNumber",
        "locationStreet",
        "locationNumber",
        "locationZipCode",
        "locationCountry",
        "locationCity",
      ];

      // Check if all specific fields are filled
      const areSpecificFieldsFilled = specificLocationFields.every((field) => {
        const value = state.companyLocation[field];

        // Check if value is a string and apply trim if needed
        if (typeof value === "string") {
          return value.trim() !== ""; // Check for non-empty strings after trimming
        }

        // Otherwise, check for non-null and non-undefined values (for other types)
        return value !== null && value !== undefined;
      });

      // Initialize finalCompanyContact with existing entries
      finalCompanyLocation = [...state.companyLocationTable];

      // Add to finalCompanyContact only if all of the specific fields are filled
      if (areSpecificFieldsFilled) {
        finalCompanyLocation.push(state.companyLocation);
      }
    }

    if (state.companyContactPersonTable.length === 0) {
      finalCompanyContact = [state.contacts];
    } else {
      const specificFields: Array<keyof ContactProps> = [
        "fName",
        "lName",
        "email",
        "mobileNumber",
      ];

      const areSpecificFieldsFilled = specificFields.every((field) => {
        const value = state.contacts[field];

        // Check if value is a string and apply trim if needed
        if (typeof value === "string") {
          return value.trim() !== ""; // Check for non-empty strings after trimming
        }

        // Otherwise, check for non-null and non-undefined values (for other types)
        return value !== null && value !== undefined;
      });

      // Initialize finalCompanyContact with existing entries
      finalCompanyContact = [...state.companyContactPersonTable];

      // Add to finalCompanyContact only if all of the specific fields are filled
      if (areSpecificFieldsFilled) {
        finalCompanyContact.push(state.contacts);
      }
    }
    const formData = {
      basic: state.basic,
      address: state.address,
      companyLocation: finalCompanyLocation,
      contacts: finalCompanyContact,
      document: state.documents,
    };
    formData.basic.companyId = companyId;
    if (validationStatus.isValid) {
      const url = !companyId
        ? END_POINTS.REGISTER_COMPANY
        : `${END_POINTS.REGISTER_COMPANY}/${companyId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        M_COMPANY_CREATION
      );

      if (response?.status === 422) {
        CustomNotify({
          type: "warning",
          message: response?.msg,
        });
      }
      if (response?.status === 200) {
        const msg =
          state.basic.companyStatus === 1
            ? "Company updated successfully!"
            : "Company created successfully!";
        CustomNotify({
          type: "success",
          message: msg,
        });
        navigate("/manage-companies");
        //todo creation
        const todoData = {
          'name': 'company',
          'entityId': companyId,
          'description': "Company registered successfully",
          'loginUserId': userAuth?.userId,
          'userId': [userAuth?.userId],
          'todoType': 'register'
        };
        await ApiCall.service(DYNAMIC_TODO_CREATION, 'POST', todoData, false, M_MASTER_DATA);
        //todo creation if linkedin is not filled
        if (Array.isArray(response?.contactPersonData) && response?.contactPersonData?.length > 0) {
          for (const contact of response?.contactPersonData) {
            const linkedInTodoData = {
              'name': 'company',
              'entityId': companyId,
              'description': "Please make sure LinkedIn should be filled",
              'loginUserId': userAuth?.userId,
              'userId': [contact?.userId],
              'todoType': 'LinkedIn'
            };
            await ApiCall.service(DYNAMIC_TODO_CREATION, 'POST', linkedInTodoData, false, M_MASTER_DATA);
          }
        }
      } else if (response?.status === 400) {
        CustomNotify({ type: "error", message: response?.msg });
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        scrollToTop();
        state.fieldError.vat = response?.msg;
      }
    }
  };
  const editDocumentData = (index: number | null) => {
    if (index !== null) {
      setModal(true);
      const documentToEdit = state.documents[index];
      // Get the certificate to edit
      if (documentToEdit) {
        setFormData((prev) => ({
          ...prev,
          id: documentToEdit.id || null,
          editId: index,
          fileUpload: {
            ...prev.fileUpload,
            fileName: documentToEdit.fileUpload.fileName || "",
            accessUrl: documentToEdit.fileUpload.accessUrl || "",
          },
          fileName: documentToEdit.fileName || "",
          fileExtension: documentToEdit.fileExtension || "",
          category: (documentToEdit.category as Option) || null,
          uploadedBy: userAuth?.name || "",
          updateAt: documentToEdit.createdAt || "",
        }));
      }
    }
  };
  const removeDocumentFieldSet = (indexToRemove: number | null) => {
    if (indexToRemove !== null) {
      dispatch({ type: "REMOVE_DOCUMENT_FIELD", index });
    }
  };
  const handleSelectFieldForSearch = (
    selectedOption: any,
    fieldName: string
  ) => {
    setSearchFields((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };
  const intialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{
    [key: string]: string;
  }>(intialErrors);

  const [modal, setModal] = useState(false);
  const closeModal = () => {
    setModal(false);
    resetFields();
    setErrors({});
  };
  const resetFields = () => {
    setFormData({
      id: null,
      editId: null,
      fileUpload: {
        accessUrl: "", // Initialize with an empty string
        fileName: "", // Initialize with an empty string
      },
      fileName: "",
      fileExtension: "",
      category: null,
      uploadedBy: "",
      createdAt: "",
    });
  };
  const handleFileChange = async (event: any, field: any) => {
    let fileData: any = null;

    if (event !== null) {
      fileData = await handleSingleFileUpload(event, "documents");
    }
    const fileName = event?.name;
    const index = fileName?.lastIndexOf(".");
    const name = index !== -1 ? fileName?.slice(0, index) : fileName;
    const extension = index !== -1 ? fileName?.slice(index + 1) : "";
    setFormData((prevData: any) => ({
      ...prevData,
      fileName: name,
      fileExtension: extension,
      uploadedBy: userAuth.name,
      [field]: fileData,
    }));
    validation(field, fileData, true);
    validation("fileName", event?.name, true);
  };

  const addData = (index: number) => {
    setFormData((prevData: any) => ({
      ...prevData,
      editId: index,
    }));
    setIndex(index); // Set the index for dispatch
    setModal(true);
  };
  const handleAddDocument = (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-GB"); // 'en-GB' gives dd-mm-yyyy format
      // Update formData with the current date in updated_at
      const updatedFormData = {
        ...formData,
        createdAt: formattedDate,
      };
      dispatch({
        type: "ADD_DOCUMENT",
        data: updatedFormData,
        index: index,
      });
      setModal(false); // Close modal
      resetFields();
      setErrors({});
    }
  };
  const handleInputFieldForSearch = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setSearchFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "3vw" }}>
            <div className="row">
              <div className="col-12">
                <LabelField title={t("Documents")} className="tab-subtitle" />
              </div>
              <div className="col-sm-12 col-md-4">
                <SelectWithSearch
                  title={t("Category")}
                  name="documentCategory"
                  isMandatory={false}
                  search={true}
                  options={state.categoryList}
                  placeHolder="Select"
                  value={searchFields.documentCategory}
                  onChange={(e) =>
                    handleSelectFieldForSearch(e, "documentCategory")
                  }
                  isMulti={false}
                  className="select-field"
                  //   error={state.fieldError.locationCountry}
                  // isDisabled={isViewMode}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  isMandatory={false}
                  label={t("File name")}
                  name={"documentName"}
                  handleChange={(event) => handleInputFieldForSearch(event)}
                  value={searchFields.documentName ?? ""}
                  id={`documentName`}
                  placeholder="File name"
                  //   error={state.fieldError.locationName}
                  //  isDisabled={isViewMode}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <SelectWithSearch
                  title={t("File type")}
                  name="extension"
                  isMandatory={false}
                  search={true}
                  options={extensionOptions}
                  placeHolder="Select"
                  value={searchFields.extension}
                  onChange={(e) => handleSelectFieldForSearch(e, "extension")}
                  isMulti={false}
                  className="select-field"
                  //   error={state.fieldError.locationCountry}
                  isDisabled={isViewMode}
                />
              </div>
            </div>
            <div className="col-12">
              <div
                className="pwa table-responsive overflow-auto scrollBarDesign"
                style={{ maxHeight: "calc(100vh - 30vw)" }}
              >
                <table className="table table-hover">
                  <thead className="position-sticky top-0 companyDocumentsThead">
                    <tr className="border-bottom TableHeader bg-white">
                      <th className="border-0 align-middle">{t("File")}</th>
                      <th className="border-0 align-middle">
                        {t("File type")}
                      </th>
                      <th className="border-0 align-middle">{t("Category")}</th>
                      <th className="border-0 align-middle">
                        {t("Uploaded by")}
                      </th>
                      <th className="border-0 align-middle">
                        {t("Uploaded at")}
                      </th>
                      <th className="border-0 align-middle">{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.documents
                      .filter((document) => {
                        // If no category is selected, include all documents
                        if (
                          !searchFields.documentCategory ||
                          searchFields.documentCategory.label === "Select"
                        ) {
                          // Check if the document name matches the search criteria
                          const fileNameMatches =
                            searchFields.documentName.trim() === "" ||
                            document.fileName
                              .toLowerCase()
                              .includes(
                                searchFields.documentName.toLowerCase()
                              );

                          const extensionMatches =
                            !searchFields.extension ||
                            searchFields.extension?.label === "Select" ||
                            document.fileExtension.toLowerCase() ===
                              searchFields.extension?.label;

                          return fileNameMatches && extensionMatches;
                        }

                        // Check if the document matches the selected category
                        const categoryMatches =
                          document.category?.value ===
                          searchFields.documentCategory?.value;

                        // Check if the document matches the file name
                        const fileNameMatches =
                          searchFields.documentName.trim() === "" ||
                          document.fileName
                            .toLowerCase()
                            .includes(searchFields.documentName.toLowerCase());

                        // Check if the document matches the file extension
                        const extensionMatches =
                          !searchFields.extension ||
                          searchFields.extension?.label === "Select" ||
                          document.fileExtension.toLowerCase() ===
                            searchFields.extension?.label;

                        // Include the document if it matches all filter criteria
                        return (
                          categoryMatches && fileNameMatches && extensionMatches
                        );
                      })
                      .map((document, index) => (
                        <tr key={index}>
                          <td className="align-middle text-break">
                            <a
                              className="file-link"
                              href={
                                document.filePath?.startsWith("https")
                                  ? document.fileUpload.accessUrl // If filePath starts with "https", use it directly
                                  : `${document.fileUpload.accessUrl}` //  `${FILE_URL}/${document.filePath}`Otherwise, construct the URL using FILE_URL and picFilePath
                              }
                              target="_blank"
                            >
                              {document?.fileName}
                            </a>
                          </td>
                          <td
                            className="align-middle text-break"
                            data-label={t("File type")}
                          >
                            {document.fileExtension}
                          </td>
                          <td
                            className="align-middle text-break"
                            data-label={t("Category")}
                          >
                            {document.category?.label}
                          </td>
                          <td
                            className="align-middle text-break"
                            data-label={t("Uploaded by")}
                          >
                            {document?.uploadedBy}
                          </td>
                          <td
                            className="align-middle text-break"
                            data-label={t("Uploaded at")}
                          >
                            {document?.createdAt}
                          </td>
                          <td
                            className="align-middle text-break table-action-icons"
                            data-label={t("Action")}
                          >
                            {!isViewMode && (
                              <>
                                <span
                                  title={t("Edit")}
                                  onClick={() =>
                                    editDocumentData(document.editId)
                                  }
                                  className="table-action-btn cursor-pointer marginRightPoint5 shadow-none"
                                >
                                  <EditIcon />
                                </span>
                                <span
                                  title={t("Delete")}
                                  onClick={() =>
                                    removeDocumentFieldSet(document.editId)
                                  }
                                  className="table-action-btn cursor-pointer shadow-none"
                                >
                                  <DeleteIcon />
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-end">
              <Button
                title={"+ " + t("Add")}
                handleClick={() => addData(state.documents.length)}
                className="form-button"
              />
            </div>
          </div>
        </div>
        <div>
          <ModalPopup
            show={modal}
            onHide={closeModal}
            title={t("Documents")}
            body={
              <div className="row">
                <div className="col-6 fileUploadFullWidth candidateDoumentsFileUpload">
                  <MaxiconFileUpload
                    label={t("File upload")}
                    name={t("fileUpload")}
                    id={t("fileUpload")}
                    isMandatory={true}
                    edit={false}
                    fileId={null}
                    fileObj={JSON.stringify(formData.fileUpload)}
                    multiple={false}
                    uploadPath="company-documents"
                    formats={"value.formats"}
                    handleChange={(e: any) => handleFileChange(e, "fileUpload")}
                    maxFileSize={10000}
                    error={errors.fileUpload}
                    // mode={isView}
                  />
                </div>
                <div className="col-6">
                  <div className="d-flex align-items-center">
                    <LabelWithInputField
                      isMandatory={true}
                      name="fileName"
                      handleChange={handleFieldChange}
                      id="fileName"
                      label="File name"
                      type="text"
                      value={formData.fileName}
                      error={errors.fileName}
                    />
                    <span style={{ marginLeft: "0.25vw" }}>
                      {formData.fileExtension
                        ? `.${formData.fileExtension}`
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <SelectWithSearch
                    title={t("Category ")}
                    isMandatory={true}
                    search={true}
                    options={state.categoryList}
                    onChange={(e) => handleSelectChange(e, "category")}
                    isMulti={false}
                    name="category"
                    value={formData.category}
                    error={errors.category}
                    placeHolder="Select"
                    //  isDisabled={isView}
                  />
                </div>

                <div className="col-12 d-flex justify-content-end">
                  {!isViewMode && (
                    <Button
                      title={t("Add")}
                      handleClick={handleAddDocument}
                      className="form-button shadow-none"
                    />
                  )}
                </div>
              </div>
            }
            className="modal-lg contractPerfCodeModal"
          />
        </div>
        <Navigation validStatus={validStatus} />
      </form>
    </>
  );
};

export default CompanyDocument;
