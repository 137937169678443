import React, { useState } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/project/context/Context";
import CheckBoxField from "components/atoms/CheckBoxField";
import RadioField from "components/atoms/RadioField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import Accordion from "react-bootstrap/Accordion";
import Button from "components/atoms/Button";
import AddProjectQuestion from "./AddProjectQuestionPopUp";
import { REMOVE_QUESTION, SET_QUESTION_OPTION } from "../context/Constants";
import ModalPopup from "components/atoms/ModalPopup";
import DeleteIcon from "static/images/DeleteIcon";

interface ProjectsProps {
  id?: string | number;
  type?: string;
}

const ProjectQuestion: React.FC<ProjectsProps> = ({ type }) => {
  const { state, dispatch } = useFormContext();

  const className = type === "View" ? "form-border disabled" : "form-border";
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    questionIndex: number
  ) => {
    console.log("Handle field change", { event, questionIndex });
  };

  const projectQuestions = state.options?.Question || [];
  const noOfEle = projectQuestions.map((_: any, index: number) =>
    index.toString()
  );
  const [showModal, setShowModal] = useState(false);
  const handleQuestion = () => {
    setShowModal(true);
  };
  const handleAddCompetence = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleAddQuestion = (questionData: any) => {
    dispatch({
      type: SET_QUESTION_OPTION,
      field: "Question",
      value: questionData,
    });
    setShowModal(false);
  };
  const handleDeleteClick = (index: number) => {
    console.log(index);
    setDeleteIndex(index);
    setShowConfirm(true);
  };

  const handleDeleteModelClose = () => {
    setShowConfirm(false);
  };
  const handleSaveChanges = () => {
    if (deleteIndex !== null) {
      dispatch({
        type: REMOVE_QUESTION,
        indexToRemove: deleteIndex,
      });
      setShowConfirm(false);
      setDeleteIndex(null);
    }
  };
  const functionOption = state.general.data.functions.tableData;
  return (
    <form>
      <div className={className} style={{ paddingTop: "3.4vw" }}>
        <div className="row">
          <div className="col-12 marginBotttom1">
            <Button
              title={t("Add question")}
              handleClick={handleQuestion}
              className="form-button float-end"
            />
          </div>
          <div className="col-12">
            <Accordion defaultActiveKey={noOfEle} alwaysOpen>
              {projectQuestions.length > 0 ? (
                projectQuestions.map((question: any, index: number) => (
                  <div className="d-flex align-items-start w-100">
                    <Accordion.Item
                      eventKey={`${index}`}
                      key={index}
                      className="w-100 marginRight1"
                    >
                      <Accordion.Header>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <span>{question.questionTittle}</span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          className={`p-3 rounded-3 mb-3 border ${
                            question.disabled
                              ? "border question-not-applicable-section opacity-50"
                              : ""
                          }`}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <h5
                              className="questionName"
                              dangerouslySetInnerHTML={{
                                __html: `${index + 1}) ${question.question}`,
                              }}
                            />
                          </div>

                          {/* Render Checkbox Options */}
                          {question.questionType === 1 &&
                            question.options?.length > 0 && (
                              <div className="my-1">
                                {question.options.map(
                                  (option: string, optionIndex: number) => (
                                    <div
                                      key={optionIndex}
                                      className="screening-checkbox d-flex align-items-center mb-2"
                                    >
                                      <CheckBoxField
                                        name={`answers-${index}-${optionIndex}`}
                                        id={`answers-${index}-${optionIndex}`}
                                        onChangeHandler={(event) =>
                                          handleFieldChange(event, index)
                                        }
                                        value={optionIndex}
                                        disable={question.disabled}
                                        isChecked={question.answers.includes(
                                          optionIndex
                                        )}
                                      />
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: option,
                                        }}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                          {/* Render Radio Options */}
                          {question.questionType === 3 &&
                            question.options?.length > 0 && (
                              <div className="my-1">
                                {question.options.map(
                                  (option: string, optionIndex: number) => (
                                    <div
                                      key={optionIndex}
                                      className="d-flex screening-checkbox align-items-center mb-2"
                                    >
                                      <RadioField
                                        label=""
                                        className="ps-1"
                                        id={`answers-${index}-${optionIndex}`}
                                        value={optionIndex}
                                        name={`answers-${index}`}
                                        handleChange={(event) =>
                                          handleFieldChange(event, index)
                                        }
                                        disable={question.disabled}
                                        ischecked={question.answers.includes(
                                          optionIndex
                                        )}
                                      />
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: option,
                                        }}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                          {/* Render Text Area */}
                          {question.questionType === 2 && (
                            <LabelWithTextAreaField
                              name={`description-${index}`}
                              handleChange={(event) =>
                                handleFieldChange(event, index)
                              }
                              label=""
                              value={question.answers[0] || ""}
                              isDisabled={question.disabled}
                            />
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <div className="table-action-icons projectAccordionIcon">
                      <span
                        onClick={() => handleDeleteClick(index)}
                        title={t("Delete")}
                        className="table-action-btn cursor-pointer"
                      >
                        <DeleteIcon />
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-danger p-2 text-center">
                  {t("No general and function related questions added")}
                </div>
              )}
            </Accordion>
            {showModal && (
              <AddProjectQuestion
                handleClose={handleCloseModal}
                handleAdd={handleAddQuestion}
                functionOptions={functionOption}
                questionTypeOption={state.options.QuestionType}
              />
            )}
          </div>
        </div>
        <ModalPopup
          show={showConfirm}
          onHide={handleDeleteModelClose}
          title="Delete confirmation"
          body="Are you sure want to delete?"
          onCloseButtonClick={handleDeleteModelClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
      </div>
    </form>
  );
};

export default ProjectQuestion;
