export const UserTableHeader = [
  { name: "Business unit", alias: 'businessUnits', width: 15, issort: true },
  { name: "First name", width: 15, alias: 'firstName', issort: true },
  { name: "Last name", width: 15, alias: 'lastName', issort: true },
  { name: "Email", width: 30, alias: 'email', issort: true },
  { name: "Phone number", width: 20, alias: 'phoneNumber', issort: true },
  { name: "Role", width: 15, alias: 'role', issort: true },
  { name: "Status", width: 16 },
];
export const ApplicantTableHeader = [
  { name: "Business unit", width: 30, alias: "businessUnits", issort: true, toolTip: true },
  { name: "Name", width: 30, alias: "name", issort: true, toolTip: true },
  { name: "Email", width: 30, alias: "email", issort: true, toolTip: true },
  { name: "Phone number", width: 10, alias: "phoneNumber", issort: true, toolTip: true },
  { name: "Job offer", width: 10, alias: "job_offer", issort: true },
  { name: "Company name", width: 10, alias: "company_name", issort: true },
  { name: "Date of application", width: 10, alias: "dateOfApplication", issort: true },
  { name: "Channel", width: 10, alias: "channel", issort: true },
  { name: "Status", width: 20 },
];

export const CandidatesTableHeader = [
  { name: "Business unit", alias: 'businessUnits', issort: true },
  { name: "Name", alias: 'name', issort: true },
  { name: "Email", alias: 'email', issort: true },
  { name: "Contract type", alias: 'contractType', issort: true },
  { name: "Mobile number", alias: 'mobileNumber', issort: true },
  { name: "Recruitment status", alias: 'recruitmentStatus', issort: true },
  { name: "Status", alias: 'status' },
  { name: "Role", alias: "role", issort: true },
];

//Todo
export const TodoHeader = [
  { name: "Bussiness unit", alias: "business_unit" },
  { name: "Time of follow-up", alias: "todo_date" },
  { name: "Created by", alias: "assigned_by" },
  { name: "Todo type", alias: "todo_type" },
  { name: "Status", alias: "todo_status" },
  { name: "Candidate/Applicant", alias: "candidate" },
  { name: "Company/Project", alias: "company" },
  { name: "Vacancy", alias: "vacancy" },
];

export const CompaniesTableHeader = [
  // { name: "Businees unit", alias: 'businessUnits', width: 15, issort: true },
  { name: "Company name", width: 15, alias: 'name', issort: true },
  { name: "VAT number", width: 15, alias: 'vatNumber', issort: true },
  { name: "Zip code", width: 30, alias: 'zipCode', issort: true },
  { name: "City", width: 20, alias: 'city', issort: true },
  { name: "Country", width: 15, alias: 'country', issort: true },
  { name: "Email", width: 15, alias: 'email', issort: true },
  { name: "Mobile number", width: 15, alias: 'phoneNumber', issort: true },
  { name: "Business unit", alias: 'businessUnits', width: 15, issort: true },
  { name: "Company type", alias: 'companyType', width: 15, issort: false },
  { name: "Status", width: 16 },
];

export const ProjectTableHeader = [
  { name: "Business unit", alias: 'businessUnits', width: 15, issort: true },
  { name: "Project", alias: 'project', width: 20, issort: true },
  { name: "Company", alias: 'company', width: 15, issort: true },
  { name: "Location", alias: 'location', width: 15, issort: true },
  { name: "Start date", alias: 'startDate', width: 15, issort: true },
  { name: "End date", alias: 'endDate', width: 15, issort: true },
  { name: "Project status", alias: 'projectStatus', width: 15 },
  { name: "Agreement status", alias: 'agreementStatus', width: 15 },
];
export const ProjectMacthingTableHeader = [
  { name: "Name", alias: 'name', issort: true },
  { name: "Contract type", alias: 'contractType', issort: true },
  // { name: "Location", alias: 'location', issort: true },
  { name: "Function", alias: 'Function', issort: true },
  // { name: "From", alias: 'startDate', issort: true },
  // { name: "To", alias: 'endDate', issort: true },
  { name: "Margin", alias: 'margin', issort: true },
  { name: "Margin%", alias: 'marginPercentage', issort: true },
  { name: "Matching score", alias: 'score', issort: true },
];

export const VacancyTableHeader = [
  { name: "Business unit", alias: 'businessUnits', issort: true },
  { name: "Title", alias: 'title', issort: true },
  { name: "Company", alias: 'company', issort: true },
  { name: "Project", alias: 'project', issort: true },
  { name: "Function", alias: 'pcFunction', issort: true },
  { name: "Updated by", alias: 'updated_by', issort: true },
  // { name: "Updated at", alias: 'updated_at', issort: true },
  { name: "Posting", alias: 'postingMedium', issort: true },
  { name: "Status", alias: 'vacancyStatus' },
];

export const RoleTableHeader = [
  { name: "Name", alias: "name", width: 80, issort: true }
];

export const BusinessUnitTableHeader = [
  { name: "Business unit name", alias: "name", width: 35 },
  { name: "Business unit number", alias: "number", width: 25 },
  { name: "Company", alias: "company", width: 30 },
  // {name: "Template", alias: "template", width: 10},
  // {name: "Phone number", alias: "phone", width: 15},
  // {name: "Address", alias: "country", width: 15},
  // {name: "Status", alias: "status", width: 10}
];
export const ContactPersonTableHeader = [
  { name: "Business unit", alias: "businessUnitsName", issort: true },
  { name: "Contact person", alias: "name", issort: true },
  { name: "Company", alias: "companyName", issort: true },
  { name: "Location", alias: "location", issort: true },
  { name: "Email", alias: "companyEmail", issort: true },
  { name: "Phone number", alias: "companyPhoneNumber", issort: true },
]

export const LocationTableHeader = [
  { name: "Location", alias: "location", issort: true },
  { name: "Company", alias: "company", issort: true },
]

export const ApplicationConfiguration = [
  { name: "Name", alias: "entity_name", width: 80 },
]

export const LocationColumn = ["location", "company"];

export const ExcludeLocationCompanyData = ['id', 'company_id', 'location'];

export const ExcludeContactCompanyData = ['id', 'name',];

export const EmployeeTypeTableHeader = [
  { name: "Employee type name", alias: "name" },
  { name: "category", alias: "category" }
];

export const TagHeader = [
  { name: "Tag name", alias: "name", width: 15 },
];

export const ShiftHeader = [

  { name: "Shifts name", alias: "shift_name", width: 50 },
];

export const CONFIG_HEADERS = [
  { name: "Name", alias: "name", width: 30 },
  { name: "Status", alias: "status", width: 30 }
]

export const CompetenceHeader = [
  { name: "Name of competence", alias: "competenceName" },
  { name: "Paritair comitee", alias: "pcName" },
  { name: "Function", alias: "functions" },
];

export const DocumentHeader = [
  { name: "File name", alias: "fileName" },
  { name: "Document type", alias: "typeDocument" },
  { name: "Module", alias: "module" },
  { name: "Candidate/Company/Project/Fleet", alias: "candidate/company/project/fleet" },
  { name: "File size", alias: "fileSize" },
  { name: "Created on", alias: "createdOn" },
  { name: "Created by", alias: "createdBy" },
];

export const PCHeader = [
  { name: "Paritair comitee number", alias: "pc_number", width: 40 },
  { name: "Paritair comitee name", alias: "pc_name", width: 40 },
  // {name : "Category", alias:"category_name", width: 30}
];

export const HotlistEmployeeTableHeader = [
  { name: "Employee", alias: "name", width: "" },
  { name: "Location", alias: "location", width: "" },
  { name: "Contract type", alias: "contractType", width: "" },
  { name: "Function", alias: "function", width: "" },
  { name: "From", alias: "startDate", width: "" },
  { name: "To", alias: "endDate", width: "" },
  { name: "Contract sign", alias: "status", width: "" },
];

export const HotlistProjectTableHeader = [
  { name: "Project", alias: "name", width: "" },
  { name: "Company", alias: "companyName", width: "" },
  { name: "Location ", alias: "location", width: "" },
  { name: "Start date", alias: "startDate", width: "" },
  { name: "End date", alias: "endDate", width: "" },
  // {name: "Project status", alias:"projectStatus", width: ""},
]

//Questions
export const QuestionsTableHeader = [
  { name: "PC", alias: "PC", width: 30 },
  { name: "Function", alias: "functnName", width: 30 },
  { name: "Question type", alias: "questionTypeName" },
  { name: "Question title", alias: "questionTittle" }
]

export const GeneralQuestionsTableHeader = [
  { name: "Category", alias: "category" },
  { name: "Question type", alias: "questionType" },
  { name: "Question title", alias: "questionTittle" }

]

//Salary benefits
export const SalaryBenefitHeader = [
  { name: "Name", alias: "name", width: 30 },
  { name: "Salary benefit type", alias: "salary_benefit_type", width: 30 },
  // {name : "Start date", alias:"start_date", width: 30},
];

//Contracts
export const ContractTemplateHeader = [
  { name: "Name", alias: "name" },
  { name: "Value", alias: "value" }
]

//Manage contracts
export const ManageContractTemplateHeader = [
  { name: "Category", alias: "category" },
  { name: "company", alias: "company" },
  { name: "Document type", alias: "documentType" },
  { name: "Employement type", alias: "employementType" },
  { name: "Schedule type", alias: "scheduleType" },

]
//Manage employee leave
export const ManageEmployeeLeaves = [
  { name: "Title", alias: 'leaveType', width: '' },
  { name: 'Reason', alias: 'reason', width: '' },
  { name: 'From', alias: 'from', width: '' },
  { name: 'To', alias: 'to', width: '' },
  { name: 'Unit', alias: 'unit', width: '' }
]

export const CarTableHeader = [
  { name: "Business unit", alias: 'businessUnits', issort: true },
  { name: "Company", alias: 'ownerCompany', issort: true },
  { name: "Car type", alias: 'carType', issort: true },
  { name: "Brand", alias: 'brand', issort: true },
  { name: "Model", alias: 'model', issort: true },
  { name: "Licence plate", alias: 'licencePlate', issort: true },
  { name: "Driver", alias: 'driver', issort: true },
  { name: "Chassis number", alias: 'chassisNumber', issort: true },
  { name: "Contract number", alias: 'contractNumber', issort: true },
  { name: "Start date", alias: 'startDate', issort: true },
  { name: "End date", alias: 'endDate', issort: true },
  { name: "From", alias: 'fromDate', issort: true },
  { name: "To", alias: 'toDate', issort: true },
  { name: "Location", alias: 'location', issort: true },
  { name: "Status", alias: 'status' },
];

export const MaterialTableHeader = [
  { name: "Business unit", alias: 'businessUnits', issort: true },
  { name: "Company", alias: 'ownerCompany', issort: true },
  { name: "Brand", alias: 'brand', issort: true },
  { name: "Model", alias: 'model', issort: true },
  { name: "Reference", alias: 'reference', issort: true },
  { name: "User", alias: 'user', issort: true },
  { name: "Serial number", alias: 'serialNumber', issort: true },
  { name: "From", alias: 'fromDate', issort: true },
  { name: "To", alias: 'toDate', issort: true },
  { name: "Status", alias: 'status' },
];

export const ClothsTableHeader = [
  { name: "Business unit", alias: 'businessUnits', issort: true },
  { name: "Company", alias: 'ownerCompany', issort: true },
  { name: "Brand", alias: 'brand', issort: true },
  { name: "Model", alias: 'model', issort: true },
  { name: "Status", alias: 'status' },
];

export const PurchaseOrderTableHeader = [
  { name: "Business unit", alias: 'businessUnits', issort: true },
  { name: "Company", alias: 'ownerCompany', issort: true },
  { name: "Brand", alias: 'brand', issort: true },
  { name: "Model", alias: 'model', issort: true },
  { name: "Status", alias: 'status' },
];


export const ClientTableHeader = [
  { name: "Client type", alias: 'typeName', issort: true },
  { name: "Name", alias: 'name', issort: true },
  { name: "Vat number", alias: 'vatNumber', issort: true },
  { name: "Email", alias: 'email', issort: true },
  { name: "Status", alias: 'status' },
];

export const TempAgencyHeader = [
  { name: "Temp agency", alias: "name", issort: true },
  { name: "Company", alias: "company_names", issort: true },
  { name: "Business units", alias: "business_unit_names", issort: true },
  { name: "Status", alias: 'status' },
];


export const SupplierTableHeader = [
  { name: "Supplier type", alias: 'typeName', issort: true },
  { name: "Name", alias: 'name', issort: true },
  { name: "Vat number", alias: 'vatNumber', issort: true },
  { name: "Email", alias: 'email', issort: true },
  { name: "Status", alias: 'status' },
];
export const FleetLocationTableHeader = [
  { name: "Location", alias: 'locationName', issort: true },
  { name: "City", alias: 'city', issort: true },
  { name: "Country", alias: 'countryName', issort: true },
];

export const HolidayCodeTableHeader = [
  { name: "Name", alias: 'holiday_name', issort: true },
  { name: "Code", alias: 'holiday_code', issort: true },
  { name: "Contract type", alias: 'contract_type', issort: true },
  { name: "Holiday type", alias: 'holiday_type', issort: true },
  { name: "Employee category", alias: 'employee_category', issort: true },
  { name: "Counts", alias: 'holiday_count' },
];

export const PublicHolidayTableHeader = [
  { name: "Name", alias: 'name', issort: true },
  { name: "Date", alias: 'date', issort: true },
  { name: "Country", alias: 'country', issort: true },
  { name: "Region", alias: 'region', issort: true },
];

export const ManageHolidaysPerPC = [
  { name: "Paritair committee", alias: "pc_name", width: 80, issort: true },
]

export const DocumentTypeHeader = [
  { name: "Document module", alias: "documentModule", issort: true },
  { name: "Document type", alias: "documentType", issort: true },
];

export const TimesheetsTableHeader = [
  { name: "Business unit", alias: 'businessUnits', issort: true },
  { name: "Month", alias: 'month', issort: true },
  { name: "Week", alias: 'week', issort: true },
  { name: "Project name", alias: 'projectName', issort: true },
  { name: "Employee name", alias: 'employeeName', issort: true },
  { name: "Worksheet number", alias: 'worksheet_no', issort: true },
  { name: "Invoice number", alias: 'invoice_no', issort: true },
  { name: "Approved", alias: 'approved' },
  { name: "Invoiced", alias: 'invoiced' },
];
export const PayRollTableHeader = [
  { name: "Business unit", alias: 'businessUnits', issort: true },
  { name: "Employee", alias: 'employee', issort: true },
  { name: "Contract type", alias: 'contract_type', issort: true },
];