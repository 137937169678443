import React, { createContext, useContext, useReducer, ReactNode } from "react";
//import { Dispatch } from "./Actions";
import { ComapanyState } from "pages/microservices/Company/context/Interfaces";
import  CompanyReducer  from "pages/microservices/Company/context/CompanyReducer";
import { initialState } from "./state";

import { Dispatch } from "./Actions";

const FormContext = createContext<
  | {
      state: ComapanyState;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

interface FormProviderProps {
  children: ReactNode;
}

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(CompanyReducer, initialState);

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};
