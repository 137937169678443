import {
    validateEmail,
    validateMultiSelectField,
    validatePhoneNumber,
    validateRequired,
    validateSelectField,
    validateTextFieldAlpha,
    validateVAT,
} from "services/validation/ValidationService";
import { ValidationRules } from "utils/TypeAnnotations";


// GeneralTab
export const GeneralTabValidationRules: ValidationRules = {
    vat: [
        validateVAT
    ],
    businessUnitNumber: [validateRequired, validateMultiSelectField],
    name: [validateRequired, validateTextFieldAlpha],
    code: [validateRequired, validateTextFieldAlpha],
    type: [validateRequired, validateSelectField],
    company: [validateRequired, validateSelectField],
    consultant: [validateSelectField],
};
export const AddressTabValidationRules: ValidationRules = {
    street: [validateRequired],
    number: [validateRequired],
    zipCode: [validateRequired],
    city: [validateRequired],
    country: [validateSelectField],
    biStreet: [validateRequired],
    biZipCode: [validateRequired],
    biNumber: [validateRequired],
    biCity: [validateRequired],
    biCountry: [validateSelectField],
};


export const GeneralTabSubformLocationRules: ValidationRules = {
    company: [validateRequired, validateSelectField],
    location: [validateRequired],
    phone: [validateRequired],
    street: [validateRequired],
    number: [validateRequired],
    zipcode: [validateRequired],
    city: [validateRequired],
    country: [validateSelectField],
    // info: [],
};
export const LocationTabValidationRules: ValidationRules = {
    locationStreet: [validateRequired],
    locationName: [validateRequired],
    // locationEmail: [validateRequired, validateEmail],
    locationNumber: [validateRequired],
    locationZipCode: [validateRequired],
    locationCity: [validateRequired],
    locationCountry: [validateSelectField],
    locationPhoneNumber: [validatePhoneNumber],
};

// ContactsTab
export const ContactsTabValidationRules: ValidationRules = {
    selectedContactPersons: [validateRequired, validateMultiSelectField],
}

export const ContactsTabSubformValidationRules: ValidationRules = {
    fName: [validateRequired],
    lName: [validateRequired],
    email: [validateRequired, validateEmail],
    mobNumber: [validatePhoneNumber],
};
