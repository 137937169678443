import SortAtomForManagePage from 'components/atoms/SortAtomForManagePage';
import PaginationWithPerPage from 'components/molecules/PaginationWithPerPage';
import FilterOrganism from 'components/organism/FilterOrganism';
import { M_MASTER_DATA } from 'constants/Constants';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MANAGE_TODO_DATA } from 'routes/ApiEndpoints';
import { ApiCall } from 'services/ApiServices';
import { selectPageHistory, updatePageFilters } from 'store/pageHistory/pageHistorySlice';
import { TodoHeader } from 'TableHeader';
import { t } from '../translation/Translation';
import EditIcon from 'static/images/EditIcon';
import { useNavigate } from 'react-router-dom';
import { PATH_TODO_CREATE, PATH_TODO_VIEW } from 'constants/Paths';
import ViewIcon from 'static/images/ViewIcon';
import AccessControl from 'services/AccessControl';
import { selectAuth } from 'features/auth/AuthSlice';
import LinkTo from 'components/atoms/LinkTo';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Title from 'components/atoms/Title';

export interface Todo {
  id?: number;
  todo_type: string;
  assigned_by?: number;
  todo_status?: any;
  business_unit?: any;
  todo_date?: string;
  candidate?: string;
  company?: string;
  project?: string;
  vacancy?: string;
}

const ManageTodos = () => {
  const userData = useSelector(selectAuth);
  const navigate = useNavigate();
  const [todo, setTodo] = useState<Todo[]>();
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true
  });
  const [dropdownData, setDropdownData] = useState({
    businessUnits: [],
  });
  const [loading, setLoading] = useState(false);
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();

  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
    };
    fetchTodos(postData);
  }, [pageData?.refresh, pageData?.initialCall]);

  // Function to get search data
  const getSearchData = () => {
    return {
      businessUnits: history?.filters?.businessUnits ?? [],
      startDate: history?.filters?.startDate ?? "",
      endDate: history?.filters?.endDate ?? "",
      createdBy: history?.filters?.createdBy ?? "",
      todoType: history?.filters?.todoType ?? "",
      todoStatus: history?.filters?.todoStatus ?? "",
      candidate: history?.filters?.candidate ?? "",
      company: history?.filters?.company ?? "",
      project: history?.filters?.project ?? "",
      vacancy: history?.filters?.vacancy ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc"
    };
  };

  const fetchTodos = async (postData: any) => {
    setLoading(true);
    const response = await ApiCall.service(MANAGE_TODO_DATA, "POST", postData, false, M_MASTER_DATA);
    if (response?.status === 200) {
      if (pageData?.initialCall) {
        setDropdownData(response?.dropdownData);
      }
      setTodo(response?.data);
      setPageData((prev: any) => ({ ...prev, totalPages: response?.totalPages, totalRecords: response?.totalRecords, initialCall: false }));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  // Filters
  const filters = [
    { name: 'businessUnits', fieldType: "multiSelect", options: dropdownData?.businessUnits, placeholder: "Business unit", filterType: 'search' },
    { name: 'startDate', fieldType: "date", placeholder: "Start Date", filterType: 'search' },
    { name: 'endDate', fieldType: "date", placeholder: "End Date", filterType: 'search' },
    { name: 'createdBy', fieldType: "text", placeholder: "Created by", filterType: 'search' },
    { name: 'todoType', fieldType: "text", placeholder: "Todo Type", filterType: 'search' },
    { name: 'todoStatus', fieldType: "text", placeholder: "Todo Status", filterType: 'search' },
    { name: 'candidate', fieldType: "text", placeholder: "Candidate/Applicant", filterType: 'search' },
    { name: 'company', fieldType: "text", placeholder: "Company", filterType: 'search' },
    { name: 'project', fieldType: "text", placeholder: "Project", filterType: 'search' },
    { name: 'vacancy', fieldType: "text", placeholder: "Vacancy", filterType: 'search' },
  ];

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <div className="manage-todos mt-5">
      <Title title={t("Todo followups")} />

      <div className="position-relative tableMainWrapper">
        <AccessControl
          requiredPermissions={[
            {
              permission: "Todo",
              create: true,
            },
          ]}
          override={userData.isSuperAdmin}
        >
          <div className="row">
            <div className="ManageCreateBtn">
              <LinkTo
                pagelink={PATH_TODO_CREATE}
                title={t("Create follow up todo")}
                icon={faPlus}
              />
            </div>
          </div>
        </AccessControl>
        <div className="table-responsive manageTodos tableSection">
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={filters}
            handleRefresh={handleRefresh}
          />
          <div className="TableHeight">
            <table className="table table-hover">
              {loading ? (
                <p>Loading todos...</p>
              ) : (
                <>
                  <thead >
                    <tr className='TableHeader'>
                      {TodoHeader?.map((header: any) => (
                        <th key={header?.alias}>
                          <>
                            {t(header.name)}
                            {header?.issort && (
                              <SortAtomForManagePage
                                value={{ alias: header?.alias }}
                                dispatch={dispatch}
                                history={history}
                                updatePageFilters={updatePageFilters}
                                handleRefresh={handleRefresh}
                              />
                            )}
                          </>
                        </th>
                      ))}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {todo && todo?.map((data: Todo) => (
                      <tr key={data.id} className="data-item">
                        <td>{Array.isArray(data?.business_unit) && data?.business_unit?.join(', ')}</td>
                        <td>{data?.todo_date}</td>
                        <td>{data.assigned_by}</td>
                        <td>{data.todo_type}</td>
                        <td>{data?.todo_status}</td>
                        <td>{data?.candidate}</td>
                        <td>{data?.company || data?.project}</td>
                        <td>{Array.isArray(data?.vacancy) && data?.vacancy?.join(', ')}</td>
                        <td>
                          <span className="table-action-btn cursor-pointer" onClick={() => navigate(`${PATH_TODO_CREATE}/${data.id}`)} title={t("Edit")}>
                            <EditIcon />
                          </span>
                          <span className="table-action-btn cursor-pointer" onClick={() => navigate(`${PATH_TODO_VIEW}/${data.id}`)} title={t("Edit")}>
                            <ViewIcon />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>


                </>

              )}
            </table>
          </div >
          {/* Pagination */}
          <PaginationWithPerPage
            handleRefresh={handleRefresh}
            dispatch={dispatch}
            history={history}
            pageData={pageData}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageTodos;
