import React from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/project/context/Context";
import ContactForm from "components/organism/contactForm";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import {
  validateForm,
  validateFormField,
} from "services/validation/ValidationService";
import { ITagAtomProps } from "components/atoms/TagAtom";
import { Option } from "components/common/CommonInterfaces";
import {
  SET_CONTACT_PERSON_OPTION,
  UPDATE_CONTACT_FIELD_ERROR,
  UPDATE_CONTACT_SUBFORM_FIELD,
  UPDATE_FIELD_ERROR,
  UPDATE_RESPONSIBLE_PERSON_FIELD,
  UPDATE_SELECTED_CONTACT_FIELD,
} from "../context/Constants";
import {
  ContactsTabSubformValidationRules,
  ContactsTabValidationRules,
} from "../validationRules/ProjectFormValidationRules";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import ResponsiblePersonForm from "components/organism/responsiblePerson";
import { ApiCall } from "services/ApiServices";
import { CREATE_CONTACT_PERSONS_FROM_PROJECT } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
interface ProjectsProps {
  id?: string | number;
  type?: string;
}

const ContactsPersons: React.FC<ProjectsProps> = ({ type }) => {
  const { state, dispatch } = useFormContext();

  // Init
  const contactsData = state.contactsPersons.data;
  const contactErrors = state.contactsPersons.errors;
  const responsiblePersons = state.responsiblePerson.data;
  const responsiblePersonData = {
    timeSheet: responsiblePersons.timeSheet,
    billing: responsiblePersons.billing,
    leave: responsiblePersons.leave,
    sickness: responsiblePersons.sickness,
    holiday: responsiblePersons.holiday,
    safety: responsiblePersons.safety,
  };
  const options = state.options;

  // Component based
  const selectedContactPersons: Option[] = contactsData.selectedContactPersons;

  // Options
  const contactPersonOptions = options.companyUsers;

  // Subform
  const contact = contactsData.contactsPersons;
  const contactSubformIsVisible = contactsData.contactSubformIsVisible;

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationErrors = validateForm(
      { ...contact, [name]: value },
      ContactsTabSubformValidationRules,
      isSingleFieldValidation ? name : undefined
    );

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      const updatedErrors = {
        ...contactErrors.contactsPersons,
        [name]: "",
      };

      contactErrors.contactsPersons = updatedErrors;
    } else {
      const updatedErrors = {
        ...contactErrors.contactsPersons,
        [name]: validationErrors[name],
      };
      contactErrors.contactsPersons = updatedErrors;
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleRemoveContact = (indexToRemove: number) => {
    dispatch({
      type: "REMOVE_CONTACT",
      indexToRemove,
    });
    dispatch({
      type: UPDATE_SELECTED_CONTACT_FIELD,
      field: "contactSubformIsVisible",
      value: !contactSubformIsVisible,
    });
  };

  const handleContactChange = (selectedOption: Option[], fieldName: string) => {
    dispatch({
      type: UPDATE_SELECTED_CONTACT_FIELD,
      field: fieldName,
      value: selectedOption,
    });

    // todo: add dispatch for error update
    let validatedFieldError = validateFormField(
      contactsData,
      ContactsTabValidationRules,
      fieldName,
      selectedOption
    );
    dispatch({
      type: UPDATE_FIELD_ERROR,
      fieldName: fieldName,
      error: validatedFieldError,
      tab: "contactsPersons",
    });
  };
  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = event.target;
    dispatch({
      type: UPDATE_CONTACT_SUBFORM_FIELD,
      field: name,
      value,
    });
    validation(name, value, true);
  };

  const handlePhoneNumberChange = (
    fieldName: string,
    value: string | undefined
    // index: number
  ) => {
    dispatch({
      type: UPDATE_CONTACT_SUBFORM_FIELD,
      // index,
      field: fieldName,
      value: value,
    });
    validation(fieldName, value, true);
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: UPDATE_CONTACT_SUBFORM_FIELD,
      field: name,
      value: selectedOption,
      // index,
    });
    validation(name, selectedOption, true);
  };

  const toggleFormVisibility = () => {
    dispatch({
      type: UPDATE_SELECTED_CONTACT_FIELD,
      field: "contactSubformIsVisible",
      value: !contactSubformIsVisible,
    });

    // setIsFormVisible((prevIsVisible) => !prevIsVisible);
  };
  const updateError = (fieldName: string, errorMessage: string) => {
    dispatch({
      type: UPDATE_CONTACT_FIELD_ERROR,
      fieldName,
      error: errorMessage,
      tab: "contactsPersons",
      // index: index, // Make sure you're updating the correct part of the state
    });
  };

  const handleSaveContact = async (contact: any) => {
    const validationErrors = validateForm(
      contact,
      ContactsTabSubformValidationRules
    );

    // If there are validation errors, update the state
    if (Object.keys(validationErrors).length > 0) {
      Object.keys(validationErrors).forEach((field) => {
        updateError(field, validationErrors[field]);
      });
      return;
    }
    const response = await ApiCall.service(
      CREATE_CONTACT_PERSONS_FROM_PROJECT,
      "POST",
      contact,
      true,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const contactPesonOption = response?.contactPerson;
      dispatch({
        type: SET_CONTACT_PERSON_OPTION,
        field: "companyUsers",
        value: contactPesonOption,
      });
      dispatch({
        type: UPDATE_SELECTED_CONTACT_FIELD,
        field: "contactSubformIsVisible",
        value: !contactSubformIsVisible,
      });
    }
  };
  const handleClose = () => {
    dispatch({
      type: UPDATE_SELECTED_CONTACT_FIELD,
      field: "contactSubformIsVisible",
      value: !contactSubformIsVisible,
    });
  };
  const renderContactPersonCreateForm = () => {
    return contactSubformIsVisible ? (
      <div className="marginBotttom1">
        <Modal
          size="xl"
          show={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="addContactPersonsModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Add contact person")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ContactForm
              // key={key}
              // index={index}
              contacts={contact}
              contactErrors={contactErrors.contactsPersons}
              onRemoveContact={handleRemoveContact}
              onFieldChange={handleFieldChange}
              onPhoneNumberChange={handlePhoneNumberChange}
              onSelectChange={handleSelectChange}
              companyOptions={options.company}
              genderOptions={options.gender}
              languageOptions={options.language}
              companyBusinessUnitOptions={options.businessUnit}
              rolesOptions={options.roles}
              onSaveContact={handleSaveContact}
              locationsOptions={options.locations}
              tittleOptions={options.tittle}
              handleDateChange={handleDateChange}
              fromType={"project"}
            />
          </Modal.Body>
        </Modal>
      </div>
    ) : null;
  };
  const handleResponsiblePersonChange = (
    selectedOption: Option[],
    fieldName: string
  ) => {
    dispatch({
      type: UPDATE_RESPONSIBLE_PERSON_FIELD,
      field: fieldName,
      value: selectedOption,
    });
  };
  const handleDateChange = (date: Date | null) => {
    if (date && !isNaN(date.getTime())) {
      const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1
        }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
      dispatch({
        type: UPDATE_CONTACT_SUBFORM_FIELD,
        field: "dob",
        value: formattedDate,
        // index,
      });
      // dispatch({
      //   type: "UPDATE_CONTACTS_FIELD",
      //   field: "dob",
      //   value: formattedDate,
      // });
    } else {
      console.error("Invalid date selected");
    }
  };

  const className = type == "View" ? "form-border disabled" : "form-border";
  return (
    <div className="row">
      <div className="col-12">
        <div className={className} style={{ paddingTop: "3vw" }}>
          <div className="row">
            <div className="col-6">
              <SelectWithSearch
                title="Select contact person"
                name="personcontact"
                isMandatory={true}
                search={true}
                options={contactPersonOptions}
                value={selectedContactPersons}
                placeHolder={t("Select")}
                onChange={(e) =>
                  handleContactChange(e, "selectedContactPersons")
                }
                isMulti={true}
                className="select-field"
                error={contactErrors.selectedContactPersons}
              />
            </div>
            <div className="col-6 plusIconSPace table-action-icons">
              <span
                onClick={toggleFormVisibility}
                className="table-action-btn cursor-pointer"
                title={contactSubformIsVisible ? t("Minimise") : t("Expand")}
              >
                <FontAwesomeIcon
                  icon={contactSubformIsVisible ? faMinus : faPlus}
                />
              </span>
            </div>
          </div>
          {renderContactPersonCreateForm()}
          <ResponsiblePersonForm
            onSelectChange={handleResponsiblePersonChange}
            companyUsers={options.companyUsers}
            responsiblePersonData={responsiblePersonData}
          // responsiblePersonErrors={responsiblePersonErrors}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactsPersons;
