import React, { ChangeEvent, useState } from "react";
import Modal from "react-bootstrap/Modal";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import CommonComponent from "pages/microservices/masterData/competence/CommonComponent";
import CompetenceType from "pages/microservices/masterData/competence/CompetenceType";
import { ValidationRules } from "utils/TypeAnnotations";
import {
  validateForm,
  validateRequired,
} from "services/validation/ValidationService";
import OverFlowWrapper from "components/atoms/OverFlowWrapper";
import { ApiCall } from "services/ApiServices";
import { COMPETENCE_CREATE } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";

interface Option {
  label: string;
  value: string;
  range: string;
}

interface Competence {
  competence_id: number;
  key: string;
  label: string;
  info: string;
  additionalInfo: string;
  maxRange: number;
  options: Option[];
}
interface CompetenceModalProps {
  handleClose: () => void;
  handleAdd: (selectedOptions: Competence[]) => void;
  functionOptions: {};
  projectId?: any;
}

const AddProjectCompetenceModal: React.FC<CompetenceModalProps> = ({
  handleClose,
  handleAdd,
  functionOptions,
  projectId,
}) => {
  const [activeTab, setActiveTab] = useState("general"); // Track the active tab
  const [errors, setErrors] = useState<any>({
    competenceName: "",
    competenceType: [""],
    competenceRange: "",
    paritairComitee: "",
    function: "",
    info: "",
  });
  const [selectedCompetenceOptions, setSelectedCompetenceOptions] = useState<
    Competence[]
  >([]);

  const tabs = [
    { id: "general", tabName: "General" },
    { id: "function", tabName: "Function" },
  ];
  const validateInput = (name: string, value: any, index?: number) => {
    // if (name === "function" && activeTab !== "general") {
    //   const isValid = value.length > 0; // Ensure the array is not empty
    //   setErrors((prevErrors: any) => ({
    //     ...prevErrors,
    //     [name]: isValid ? "" : "At least one function must be selected.",
    //   }));
    //   return isValid;
    // }
    if (name === "competenceType" && (index === 0 || index)) {
      const errorsArray: any = [...errors.competenceType];
      errorsArray[index] = value?.trim() !== "" ? "" : "This field is required";
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        competenceType: errorsArray,
      }));
      return value.trim() !== "";
    }
    const shouldValidate = name !== "defaultStatus";

    const validationRules: ValidationRules = shouldValidate
      ? { [name]: [validateRequired] }
      : {};

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules
    );
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: validationErrors[name],
    }));
    return Object.keys(validationErrors).length === 0;
    // const validationRules: ValidationRules = { [name]: [validateRequired] };
    // const validationErrors = validateForm(
    //   { ...formData, [name]: value },
    //   validationRules
    // );
    // setErrors((prevErrors: any) => ({
    //   ...prevErrors,
    //   [name]: validationErrors[name],
    // }));
    // return Object.keys(validationErrors).length === 0;
  };
  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  const handleSelectChange = async (selectedOption: Option, name: string) => {
    const id = selectedOption.value;
    setFormData((prevData: any) => ({ ...prevData, [name]: selectedOption }));
    validateInput(name, selectedOption);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let isValid = true;
    // Validate all form fields
    Object.keys(formData).forEach((key) => {
      if (activeTab === "function" && key === "function") {
        // Special validation for "function" when activeTab is "function"
        const functionIsValid = formData.function.length > 0;
        if (!functionIsValid) {
          setErrors((prevErrors: any) => ({
            ...prevErrors,
            function: "At least one function must be selected.",
          }));
          isValid = false;
        } else {
          setErrors((prevErrors: any) => ({
            ...prevErrors,
            function: "",
          }));
        }
        return;
      }

      if (activeTab === "general" || key !== "function") {
        // General validation logic
        if (key !== "competenceType") {
          const result = validateInput(key, formData[key]);
          isValid = isValid && result;
        } else {
          const competenceError = validateInput(key, formData[key][0].label, 0);
          isValid = isValid && competenceError;
        }
      }
    });
    if (isValid && projectId) {
      try {
        const response = await ApiCall.service(
          COMPETENCE_CREATE,
          "POST",
          {
            ...formData,
            isGeneral: activeTab === "general" ? false : true,
            projectId: projectId,
          },
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          setSelectedCompetenceOptions(response?.result);
          CustomNotify({
            type: "success",
            message: response.message,
          });
          handleAddClick(response.result);
        } else {
          // Handle error scenario
          console.error("Error occurred while submitting the form");
        }
      } catch (error) {
        console.error("Error occurred while submitting the form:", error);
      }
    }
  };
  const handleAddClick = (result: any) => {
    handleAdd(result);
  };
  const changeHandler = (
    e: ChangeEvent<HTMLInputElement>,
    index?: number,
    type?: string
  ) => {
    const { name, value } = e.target;
    const updatedFormData: any = { ...formData };
    if (type === "competenceType" && index !== null && index !== undefined) {
      updatedFormData.competenceType[index].label = value;
    } else {
      updatedFormData[name] = value;
    }
    setFormData(updatedFormData);
    validateInput(index === 0 || index ? "competenceType" : name, value, index);
  };
  const handleAddType = (indexToAdd: number) => {
    const lastFieldIndex = formData.competenceType.length - 1;
    const lastFieldValue = formData.competenceType[lastFieldIndex]?.label;
    const isValid = validateInput(
      "competenceType",
      lastFieldValue,
      lastFieldIndex
    );
    if (isValid) {
      setFormData((prevState: any) => ({
        ...prevState,
        competenceType: [...prevState.competenceType, { value: "", label: "" }],
      }));
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        competenceType: [...prevErrors.competenceType, ""],
      }));
    }
  };

  const handleRemoveType = (indexToRemove: number) => {
    setFormData((prevState: any) => ({
      ...prevState,
      competenceType: prevState.competenceType.filter(
        (item: any, index: number) => index !== indexToRemove
      ),
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      competenceType: prevErrors.competenceType.filter(
        (item: any, index: number) => index !== indexToRemove
      ),
    }));
  };

  const [formData, setFormData] = useState<any>({
    competenceName: "",
    competenceType: [{ value: "", label: "" }],
    // paritairComitee: null,
    competenceRange: null,
    // category: null,
    info: "",
    function: [""],
    defaultStatus: false,
  });
  const handelDefaultCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      defaultStatus: checked,
    }));
  };

  return (
    <Modal
      size="xl"
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      className="competencesModal addProjectCompetencesPopup"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Add competence")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Tabs Section */}
        <div className="vacancy-tabs question-tabs d-flex backPadding">
          {tabs.map((eachItem) => (
            <button
              key={eachItem.id}
              className={`text-decoration-none border-0 bg-transparent ps-0 marginRight1 ${
                activeTab === eachItem.id ? "active-tab" : ""
              }`}
              onClick={() => handleTabClick(eachItem.id)}
            >
              {t(eachItem.tabName)}
            </button>
          ))}
        </div>

        <OverFlowWrapper className="addProjectCompetencesPopupWrapper">
          {/* Conditional Rendering Based on Active Tab */}
          {activeTab !== "general" && (
            <div>
              {/* <div className="form-group col-md-4">
              <SelectWithSearch
                title={t("Paritair comitee")}
                name="paritairComitee"
                id="paritairComitee"
                isMandatory={true}
                search={true}
                options={[]}
                placeHolder="Select"
                value={[]}
                onChange={(e) => handleSelectChange(e, "paritairComitee")}
                isMulti={false}
                className="select-field"
                error={""}
              />
            </div> */}

              {/* <div className="form-group col-md-4">
              <SelectWithSearch
                title={t("Category")}
                name="category"
                id="category"
                isMandatory={false}
                search={true}
                options={[]}
                placeHolder="Select"
                value={[]}
                onChange={(e) => handleSelectChange(e, "category")}
                isMulti={false}
                className="select-field"
                error={""}
              />
            </div> */}

              <div className="form-group">
                <SelectWithSearch
                  title={t("Function")}
                  name="function"
                  id="function"
                  isMandatory={true}
                  search={true}
                  options={functionOptions}
                  placeHolder="Select"
                  value={formData.function}
                  onChange={(e) => handleSelectChange(e, "function")}
                  isMulti={true}
                  className="select-field"
                  error={errors.function}
                />
              </div>
            </div>
          )}

          {/* Common Components */}
          <CommonComponent
            changeHandler={changeHandler}
            formData={formData}
            errors={errors}
            handleSelectChange={handleSelectChange}
            handleRemoveType={handleRemoveType}
            handleAddType={handleAddType}
            handelDefaultCheckbox={handelDefaultCheckbox}
          />

          <CompetenceType
            changeHandler={changeHandler}
            formData={formData}
            errors={errors}
            handleSelectChange={handleSelectChange}
            handleRemoveType={handleRemoveType}
            handleAddType={handleAddType}
          />
        </OverFlowWrapper>
        {/* Footer Buttons */}
        <div className="d-flex align-items-center justify-content-between backPadding">
          <Button
            title={t("Cancel")}
            handleClick={handleClose}
            className="delete-btn shadow-none"
          />
          <Button
            title={t("Add")}
            handleClick={handleSubmit}
            className="form-button shadow-none"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddProjectCompetenceModal;
