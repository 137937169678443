import React from "react";
import { t } from "pages/microservices/masterData/translation/Translation";


import Button from "components/atoms/Button";
import CheckBoxField from "components/atoms/CheckBoxField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";

import { Option } from "components/common/CommonInterfaces";
import {
    scrollToTop,
    validateForm,
    validateFormField,
    validateVAT,
} from "../../../../services/validation/ValidationService";
import {
    dependencyFields,
    SET_FIELD_ERROR_GENERAL_TAB,
    SET_FIELD_GENERAL_TAB,
    UPDATE_FIELD_ERROR,
} from "../context/Constants";
import { FormMode } from "components/common/CommonEnums";
import { getProjectStatusColor } from "services/util/UtilService";
import { GeneralTabData, GeneralTabErrors } from "../context/Interface";
// import { GeneralTabValidationRules } from "../validationRules/TempAgencyValidationRules";
import {
    COMPANY_VAT_VALIDATION,
    VAT_NUMBER_IN_TEMP_AGENCY,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA, M_WEB_CONNECTOR } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { GeneralTabValidationRules } from "../validationRules/TempAgencyGeneralRules";
import { useFormContext } from "../context/Context";
import { useParams } from "react-router-dom";
import { getOptions } from "../Utils";

// Define the prop types for the component
interface IProjectFormGeneralTabProps {
    // id?: string | number;
}

// Functional component using TypeScript
const General: React.FC<IProjectFormGeneralTabProps> = () => {
    const { state, dispatch } = useFormContext();
    const selectOptions = state.options;
    const { id: routeId } = useParams();
    const generalData: GeneralTabData = state.general.data;
    const generalErrors: GeneralTabErrors = state.general.errors;
    const handleAddressUpdate = (
        street: string,
        number: string,
        unit: string,
        postal: string,
        city: string,
        countryValue: { value: number; label: string } = {
            value: 29,
            label: "Belgium",
        }
    ) => {
        const addressFields = [
            { field: "street", value: street },
            { field: "number", value: number },
            { field: "box", value: unit },
            { field: "zipCode", value: postal },
            { field: "city", value: city },
            { field: "country", value: countryValue },
        ];

        const errors: any = {};
        const data: any = {};

        addressFields.forEach((field) => {
            errors[field.field] = ""
            data[field.field] = field.value
        });

        dispatch({
            type: "UPDATE_ALL_ADDRESS_FIELDS",
            data,
            errors,
        });
    };
    const handleLocationAddressUpdate = (
        street: string,
        number: string,
        unit: string,
        postal: string,
        city: string,
        countryValue: { value: number; label: string } = {
            value: 29,
            label: "Belgium",
        }
    ) => {
        const locationAddressFields = [
            { field: "locationStreet", value: street },
            { field: "locationNumber", value: number },
            { field: "locationBox", value: unit },
            { field: "locationZipCode", value: postal },
            { field: "locationCity", value: city },
            { field: "locationCountry", value: countryValue },
        ];
        locationAddressFields.forEach((field) => {
            // dispatch({
            //   type: "UPDATE_COMPANYLOCATION_FIELD",
            //   field: field.field,
            //   value: field.value,
            //   index: 0,
            // });
            // dispatch({
            //   type: "UPDATE_COMPANYLOCATION_FIELD",
            //   field: field.field,
            //   error: "",
            // });
        });
    };
    const verifyVatHandler = async (e: any, field: string, value: any) => {
        validateForm({ [field]: value }, { vat: [validateVAT] }, field);

        if (!value) {
            // dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
            // return;
        }
        const vatData = {
            vat_number: value,
            key: `${process.env.REACT_APP_WEBCONNECTOR_UNIQUE_KEY}`,
        };
        const vatExistence = await ApiCall.service(
            VAT_NUMBER_IN_TEMP_AGENCY,
            "POST",
            vatData,
            false,
            M_MASTER_DATA
        );
        if (vatExistence?.status === 400) {
            dispatch({
                type: SET_FIELD_ERROR_GENERAL_TAB,
                fieldName: "vat",
                error: vatExistence?.message,
            });
            scrollToTop();
            return;
        }
        const url = COMPANY_VAT_VALIDATION;
        const response = await ApiCall.service(
            url,
            "POST",
            vatData,
            true,
            M_WEB_CONNECTOR,
            false
        );
        if (response?.status !== 200) {
            CustomNotify({ type: "error", message: response?.error });

            handleAddressUpdate("", "", "", "", "");
            dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true, draft: false });
            scrollToTop();
        } else {
            const value = response?.data?.name;
            const { street, number, box, postal, city } = response?.data?.address;

            handleAddressUpdate(street, number, box, postal, city);
            if (state?.address?.data?.sameAddress == 1 || state?.address?.data?.sameAddress == true) {
                handleBillingAddressUpdate(street, number, box, postal, city);
            }
            //handleLocationAddressUpdate(street, number, box, postal, city);

            CustomNotify({ type: "success", message: "VAT number is verified" });
            dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: false, draft: false });
        }
    };

    const handleBillingAddressUpdate = (
        street: string,
        number: string,
        unit: string,
        postal: string,
        city: string,
        countryValue: { value: number; label: string } = {
            value: 29,
            label: "Belgium",
        }
    ) => {
        const addressFields = [
            { field: "biStreet", value: street },
            { field: "biNumber", value: number },
            { field: "biBox", value: unit },
            { field: "biZipCode", value: postal },
            { field: "biCity", value: city },
            { field: "biCountry", value: countryValue },
        ];

        const errors: any = {};
        const data: any = {};

        addressFields.forEach((field) => {
            errors[field.field] = ""
            data[field.field] = field.value
        });

        dispatch({
            type: "UPDATE_ALL_ADDRESS_FIELDS",
            data,
            errors,
        });
    }

    const handleFieldChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        if (event.target) {
            const { name, value, type, checked } = event.target as HTMLInputElement;
            if (type === "checkbox") {
                dispatch({
                    type: SET_FIELD_GENERAL_TAB,
                    field: name,
                    value: checked,
                });
            } else {
                if (name == 'name') {
                    dispatch({
                        type: SET_FIELD_GENERAL_TAB,
                        field: 'code',
                        value: value.replace(/\s+/g, "").slice(0, 6).toUpperCase(),
                    });

                    const addressData = state?.address?.data?.hqAddress;
                    if (addressData) {
                        dispatch({
                            type: "UPDATE_LOCATION_OR_CONTACT_FIELD",
                            field: 'locationName',
                            value: value + '-HQ',
                            tab: "location",
                        });
                    }
                }
                dispatch({
                    type: SET_FIELD_GENERAL_TAB,
                    field: name,
                    value: value,
                });
            }
            let validatedFieldError = validateFormField(
                generalData,
                GeneralTabValidationRules,
                name,
                value
            );
            dispatch({
                type: SET_FIELD_ERROR_GENERAL_TAB,
                fieldName: name,
                error: validatedFieldError,
            });
        }
    };

    const handleSelectChange = async (
        value: Option | Option[],
        fieldName: string,
        isMultiSelect: boolean = false
    ) => {
        if (fieldName === "businessUnitNumber" && isMultiSelect) {
            const isSelectAllSelected = (value as Option[]).some(
                (option) => option.value === "selectAll"
            );

            if (isSelectAllSelected) {
                const allOptions = selectOptions.businessUnit.filter(
                    (option: any) => option.value !== "selectAll"
                );
                dispatch({
                    type: SET_FIELD_GENERAL_TAB,
                    field: fieldName,
                    value: allOptions,
                });
            } else {
                dispatch({
                    type: SET_FIELD_GENERAL_TAB,
                    field: fieldName,
                    value: value as Option[],
                });
            }
        }
        else if (fieldName == "consultant") {
            const response = await getOptions(value, fieldName);
            dispatch({
                type: SET_FIELD_GENERAL_TAB,
                field: 'businessUnitNumber',
                value: response?.data,
            });
            dispatch({
                type: "UPDATE_LOCATION_OR_CONTACT_FIELD",
                field: 'businessUnit',
                value: response?.data?.[0] ?? '',
                tab: 'contactsPersons'
            });
            dispatch({
                type: SET_FIELD_GENERAL_TAB,
                field: fieldName,
                value: value,
            });
        }
        else {
            dispatch({
                type: SET_FIELD_GENERAL_TAB,
                field: fieldName,
                value: value,
            });
        }
        let validatedFieldError = validateFormField(
            generalData,
            GeneralTabValidationRules,
            fieldName,
            value
        );
        dispatch({
            type: SET_FIELD_ERROR_GENERAL_TAB,
            fieldName: fieldName,
            error: validatedFieldError,
        });
    };

    const vatClass = routeId ? "col-sm-12 col-md-6 col-lg-4 generalTabVAT position-relative disabled"
        : "col-sm-12 col-md-6 col-lg-4 generalTabVAT position-relative"

    return (
        <div className="row">
            <div className="col-12">
                <div className="form-border" style={{ paddingTop: "3vw" }}>
                    <div className="row">
                        <div className={vatClass}>
                            <div className="position-relative">
                                <div className="position-absolute fieldPrefix">BE</div>
                                <LabelWithInputField
                                    isMandatory={true}
                                    name="vat"
                                    handleChange={handleFieldChange}
                                    value={state.general?.data?.vat}
                                    id="vat"
                                    label="VAT number"
                                    type="text"
                                    error={state?.general?.errors?.vat}
                                />
                                <Button
                                    type="button"
                                    title="Verify"
                                    handleClick={(e) =>
                                        verifyVatHandler(e, "vat", state.general?.data?.vat)
                                    }
                                    className={`form-button companyVatVerify`}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <LabelWithInputField
                                type={"text"}
                                handleChange={handleFieldChange}
                                label={t("Name")}
                                value={generalData.name}
                                readOnly={false}
                                isDisabled={false}
                                isMandatory={true}
                                error={generalErrors.name}
                                name={"name"}
                                id={"nameId"}
                            ></LabelWithInputField>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <LabelWithInputField
                                type={"text"}
                                handleChange={handleFieldChange}
                                label={t("Code")}
                                value={generalData.code}
                                readOnly={false}
                                isDisabled={false}
                                isMandatory={true}
                                error={generalErrors.code}
                                name={"code"}
                                id={"codeId"}
                            ></LabelWithInputField>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <SelectWithSearch
                                search={true}
                                onChange={(value) => handleSelectChange(value, "company", false)}
                                title={t("Company")}
                                placeHolder={t("Select")}
                                options={selectOptions.company}
                                value={generalData.company}
                                isDisabled={false}
                                isMulti={false}
                                isMandatory={true}
                                error={generalErrors.company}
                                name={"company"}
                                id={"companyId"}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <SelectWithSearch
                                onChange={(value) =>
                                    handleSelectChange(value, "consultant", true)
                                }
                                title={t("Consultant")}
                                placeHolder={t("Select")}
                                search={true}
                                options={selectOptions.consultant}
                                value={generalData.consultant}
                                isDisabled={false}
                                isMulti={false}
                                isMandatory={true}
                                error={generalErrors.consultant}
                                name={"consultant"}
                                id={"consultant"}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <SelectWithSearch
                                onChange={(value) =>
                                    handleSelectChange(value, "businessUnitNumber", true)
                                }
                                title={t("Maxicon Business Unit")}
                                placeHolder={t("Select")}
                                search={true}
                                options={selectOptions.businessUnit}
                                value={generalData.businessUnitNumber}
                                isDisabled={false}
                                isMulti={true}
                                isMandatory={true}
                                error={generalErrors.businessUnitNumber}
                                name={"businessUnitNumber"}
                                id={"businessUnitNumber"}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <LabelWithInputField
                                isMandatory={false}
                                name="website"
                                handleChange={handleFieldChange}
                                value={generalData?.website}
                                id="website"
                                label={t("Website")}
                                type="text"
                            />
                        </div>
                        <div className="col-4">
                            <SelectWithSearch
                                onChange={(value) => handleSelectChange(value, "type", true)}
                                title={t("Type")}
                                placeHolder={t("Select")}
                                options={selectOptions.tempAgencyTypes}
                                value={generalData.type}
                                search={true}
                                isDisabled={false}
                                isMulti={false}
                                isMandatory={true}
                                error={generalErrors.type}
                                name={"type"}
                                id={"typeId"}
                            ></SelectWithSearch>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <LabelWithTextAreaField
                                label={t("Info")}
                                name={"info"}
                                value={generalData.info}
                                isDisabled={false}
                                isMandatory={false}
                                handleChange={handleFieldChange}
                            />
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <CheckBoxField
                                label={t("Active")}
                                name={"isActive"}
                                id={"isActiveId"}
                                isChecked={
                                    ((generalData.isActive == 1) || (generalData.isActive == true)) ? true : false
                                }
                                onChangeHandler={handleFieldChange}
                                disable={false}
                                lineHeight="1.5vw"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default General;
