import LabelField from "components/atoms/LabelField"
import { t } from "pages/microservices/masterData/translation/Translation"
import PhoneInput from "react-phone-number-input";

interface PhoneNumberProps {
    label: string;
    name: string;
    value: string;
    defaultCountry: any;
    placeholder: string;
    disabled: boolean;
    handleChange: any;
    required: boolean;
    error?: string;
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({
    label,
    name,
    value,
    defaultCountry,
    placeholder,
    disabled,
    handleChange,
    required,
    error,
}) => {
    return (
        <div>
            <LabelField
                title={label}
                isMandatory={true}
                key="PhoneInput"
                htmlfor={name}
            />
            <div className="form-control field-shadow">
                <PhoneInput
                    name={name}
                    label={label}
                    required={required}
                    defaultCountry={defaultCountry ?? 'BE'}
                    international
                    initialValueFormat="national"
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={value ? ("+" + value?.replace(/\+/g, '')) : ""}
                    id={name}
                    disabled={disabled}
                />
            </div>
            <div className="height-20" style={{ marginBottom: "0.25vw", marginTop: "0.25vw" }}>
                {error && (
                    <span className="text-danger">{error}</span>
                )}
            </div>
        </div>
    )
}
export default PhoneNumber