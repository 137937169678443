import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "components/atoms/Button";
import React, { useState, ChangeEvent, useEffect } from "react";
import { t } from "../translation/Translation";
import {
  validateForm,
  validateRequired,
} from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";

import { ApiCall } from "services/ApiServices";
import {
  COMPETENCE_CREATE,
  COMPETNCE_EDIT,
  GET_CATOGRY_BY_ID,
  GET_COMPETENCE_OPTIONS,
  GET_FUNCTION_BY_CATEGORY_ID,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { PATH_COMPETENCE_MANAGE } from "constants/Paths";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option } from "components/common/CommonInterfaces";
import CloseFile from "static/images/CloseFile";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import TitleAtom from "components/atoms/Title";
import AddPlusIcon from "static/images/AddPlusIcon";
import BackButton from "components/atoms/BackButton";
import { competenceRange } from "utils/multiTabForm/components/common/ReducerService";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import CompetenceType from "./CompetenceType";
import CommonComponent from "./CommonComponent";

interface ValidationRules {
  [key: string]: Function[];
}
interface Payload {
  id: string | number | any;
  pcId?: string | number;
}

function CreateCompetence() {
  const [formData, setFormData] = useState<any>({
    competenceName: "",
    competenceType: [{ value: "", label: "" }],
    paritairComitee: null,
    competenceRange: null,
    category: null,
    info: "",
    defaultStatus: true,
    function: [""],
  });
  const [pcOption, setPcOptions] = useState<Option[]>();
  const [categoryOption, setCategoryOption] = useState<Option[]>();
  const [functionOption, setFunctionOption] = useState<Option[]>();
  const { id, type } = useParams<{ id: string; type: string }>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({
    competenceName: "",
    competenceType: [""],
    competenceRange: "",
    paritairComitee: "",
    function: "",
    info: "",
  });

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await ApiCall.getService(
        GET_COMPETENCE_OPTIONS,
        "GET",
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setPcOptions(response?.data?.pc);
        setCategoryOption(response?.data?.category);
        setFunctionOption(response?.data.function);
        if (id) {
          fetchData(response?.data);
        }
      }
    } catch (error) {}
  };

  const rangeOptions = [
    { value: 4, label: "1-4" },
    { value: 6, label: "1-6" },
  ];

  const fetchData = async (optiondata: any) => {
    if (id) {
      try {
        const editData = { id: id };
        const response = await ApiCall.service(
          COMPETNCE_EDIT,
          "POST",
          editData,
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          const {
            competenceName,
            competenceType,
            paritairComitee,
            category,
            range,
            info,
            default: defaultStatus,
            function: functions,
          } = response.data;
          setFormData({
            id,
            competenceName,
            competenceRange: range,
            info,
            competenceType: competenceType.map((type: any) => ({
              value: type.id,
              label: type.competence_type,
            })),
            paritairComitee: paritairComitee || null,
            category: category || null,
            function: functions || null,
            defaultStatus: defaultStatus !== undefined ? defaultStatus : null,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const changeHandler = (
    e: ChangeEvent<HTMLInputElement>,
    index?: number,
    type?: string
  ) => {
    const { name, value } = e.target;
    const updatedFormData: any = { ...formData };
    if (type === "competenceType" && index !== null && index !== undefined) {
      updatedFormData.competenceType[index].label = value;
    } else {
      updatedFormData[name] = value;
    }
    setFormData(updatedFormData);
    validateInput(index === 0 || index ? "competenceType" : name, value, index);
  };
  const handelDefaultCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      defaultStatus: checked,
    }));
  };

  const handleSelectChange = async (selectedOption: Option, name: string) => {
    console.log(selectedOption);
    const id = selectedOption.value;
    // Initialize payload with selected option id
    const payload: Payload = {
      id: id,
    };
    if (name === "paritairComitee") {
      setFormData((prevData: any) => ({ ...prevData, ["category"]: null }));
      setFormData((prevData: any) => ({ ...prevData, ["function"]: null }));
      const response = await ApiCall.service(
        GET_CATOGRY_BY_ID,
        "POST",
        payload,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setCategoryOption(response?.data?.categories);
        setFunctionOption(response?.data?.functions);
      }
    }
    if (name === "category") {
      setFormData((prevData: any) => ({ ...prevData, ["function"]: null }));
      const paritairComiteeId = formData.paritairComitee?.value;
      if (paritairComiteeId) {
        payload.pcId = paritairComiteeId;
      }
      const response = await ApiCall.service(
        GET_FUNCTION_BY_CATEGORY_ID,
        "POST",
        payload,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setFunctionOption(response?.data);
      }
    }
    setFormData((prevData: any) => ({ ...prevData, [name]: selectedOption }));
    validateInput(name, selectedOption);
  };

  const validateInput = (name: string, value: any, index?: number) => {
    if (name === "competenceType" && (index === 0 || index)) {
      const errorsArray: any = [...errors.competenceType];
      errorsArray[index] = value?.trim() !== "" ? "" : "This field is required";
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        competenceType: errorsArray,
      }));
      return value.trim() !== "";
    }
    const shouldValidate = name !== "defaultStatus";

    const validationRules: ValidationRules = shouldValidate
      ? { [name]: [validateRequired] }
      : {};

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules
    );
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: validationErrors[name],
    }));
    return Object.keys(validationErrors).length === 0;
  };

  const handleAddType = (indexToAdd: number) => {
    const lastFieldIndex = formData.competenceType.length - 1;
    const lastFieldValue = formData.competenceType[lastFieldIndex]?.label;
    const isValid = validateInput(
      "competenceType",
      lastFieldValue,
      lastFieldIndex
    );
    if (isValid) {
      setFormData((prevState: any) => ({
        ...prevState,
        competenceType: [...prevState.competenceType, { value: "", label: "" }],
      }));
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        competenceType: [...prevErrors.competenceType, ""],
      }));
    }
  };

  const handleRemoveType = (indexToRemove: number) => {
    setFormData((prevState: any) => ({
      ...prevState,
      competenceType: prevState.competenceType.filter(
        (item: any, index: number) => index !== indexToRemove
      ),
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      competenceType: prevErrors.competenceType.filter(
        (item: any, index: number) => index !== indexToRemove
      ),
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let isValid = true;

    Object.keys(formData).map((key) => {
      if (
        !pathName &&
        ["paritairComitee", "category", "function"].includes(key)
      ) {
        return;
      }

      if (key === "category") return;
      if (key !== "competenceType") {
        const result = validateInput(key, formData[key]);
        isValid = isValid && result;
      } else {
        const competenceError = validateInput(key, formData[key][0].label, 0);
        isValid = isValid && competenceError;
      }
    });

    if (isValid) {
      try {
        const response = await ApiCall.service(
          COMPETENCE_CREATE,
          "POST",
          { ...formData, isGeneral: pathName },
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          CustomNotify({
            type: "success",
            message: response.message,
          });
          navigate(`${PATH_COMPETENCE_MANAGE}?type=${type}`);
        } else {
          // Handle error scenario
          console.error("Error occurred while submitting the form");
        }
      } catch (error) {
        console.error("Error occurred while submitting the form:", error);
      }
    }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Competence",
  };

  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  const pathName = type == "general" ? false : true;

  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <div className="row">
            <TitleAtom
              title={id ? t("Edit competence") : t("Create competence")}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="form-border">
                <div className="row">
                  {pathName && (
                    <>
                      <div className="form-group col-md-4">
                        <SelectWithSearch
                          title={t("Paritair comitee")}
                          name="paritairComitee"
                          id="paritairComitee"
                          isMandatory={true}
                          search={true}
                          options={pcOption ?? []}
                          placeHolder="Select"
                          value={formData.paritairComitee}
                          onChange={(e) =>
                            handleSelectChange(e, "paritairComitee")
                          }
                          isMulti={false}
                          className="select-field"
                          error={errors.paritairComitee}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <SelectWithSearch
                          title={t("Category")}
                          name="category"
                          id="category"
                          isMandatory={false}
                          search={true}
                          options={categoryOption ?? []}
                          placeHolder="Select"
                          value={formData.category}
                          onChange={(e) => handleSelectChange(e, "category")}
                          isMulti={false}
                          className="select-field"
                          error={errors.category}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <SelectWithSearch
                          title={t("Function")}
                          name="function"
                          id="function"
                          isMandatory={true}
                          search={true}
                          options={functionOption ?? []}
                          placeHolder="Select"
                          value={formData.function}
                          onChange={(e) => handleSelectChange(e, "function")}
                          isMulti={true}
                          className="select-field"
                          error={errors.function}
                        />
                      </div>
                    </>
                  )}
                  <CommonComponent
                    changeHandler={changeHandler}
                    formData={formData}
                    errors={errors}
                    handleSelectChange={handleSelectChange}
                    handleRemoveType={handleRemoveType}
                    handleAddType={handleAddType}
                    handelDefaultCheckbox={handelDefaultCheckbox}
                  />
                </div>

                <CompetenceType
                  changeHandler={changeHandler}
                  formData={formData}
                  errors={errors}
                  handleSelectChange={handleSelectChange}
                  handleRemoveType={handleRemoveType}
                  handleAddType={handleAddType}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between backPadding">
                <div className="">
                  <div className="">
                    <BackButton />
                  </div>
                </div>
                <div className="">
                  <Button
                    title={t("Save")}
                    handleClick={handleSubmit}
                    className="form-button float-end shadow-none"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    </AccessControl>
  );
}
export default CreateCompetence;
