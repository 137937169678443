import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import SelectWithSearch from "components/atoms/SelectWithSearch";

import { useEffect, useState } from "react";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { t } from "pages/microservices/masterData/translation/Translation";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import Button from "components/atoms/Button";
import PhoneInput from "react-phone-number-input";
import {
  scrollToTop,
  validateForm,
  validateNumber,
  validatePhoneNumber,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { Option, ValidationRules } from "utils/TypeAnnotations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import ViewIcon from "static/images/ViewIcon";
import axios from "axios";
import ActiveLocation from "static/images/ActiveLocation";
import AddAddressLoactionViaMap, {
  AddressDetails,
} from "utils/AddAddressLoactionViaMap";
import { LocationTabData } from "../context/Interface";
import { useFormContext } from "../context/Context";
import PhoneNumber from "components/molecules/PhoneNumber";

const Address = () => {
  const { state, dispatch } = useFormContext();

  const location = useLocation();
  const getCityAndCountry = async (zipcode: string) => {
    try {
      if (zipcode.length === 4) {
        const response = await axios.get(
          `https://api.zippopotam.us/be/${zipcode}`
        );
        // Check if the response contains places data and is in the expected format
        if (
          response.data &&
          response.data.places &&
          response.data.places.length > 0
        ) {
          return {
            city: response.data.places?.[0]?.["place name"] || "",
            country: response.data?.country || "Belgium",
          };
        } else {
          return { city: "", country: "Belgium" };
        }
      } else {
        return { city: "", country: "" };
      }
    } catch (error) {
      return { city: "", country: "" };
    }
  };

  const locFormData = state?.location?.data;

  // Check if 'mode=view' is present in the query string
  const isViewMode = location.search.includes("mode=view");
  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    const processedValue = type === "number" ? String(value) : value;
    if (name === "locationZipCode") {
      const city = getCityAndCountry(value);
      city.then((response: any) => {
        const { city, country } = response;
        dispatch({
          type: "UPDATE_LOCATION_OR_CONTACT_FIELD",
          field: "locationCity",
          value: city,
          tab: "location"
        });
        const countryValue: any =
          country !== "Error"
            ? state?.options?.countries.find(
              (coun: any) =>
                coun?.label?.toLowerCase() === country?.toLowerCase()
            )
            : null;
        dispatch({
          type: "UPDATE_LOCATION_OR_CONTACT_FIELD",
          field: "locationCountry", // Convert to domFieldName
          value: countryValue,
          tab: "location"
        });
      });
    }
    dispatch({
      type: "UPDATE_LOCATION_OR_CONTACT_FIELD",
      field: name,
      value: value,
      tab: "location"
    });
    validation(name, processedValue, true);
  };
  const validation = (
    name: string,
    value: any,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      locationName: [validateRequired],
      locationStreet: [validateRequired],
      locationNumber: [validateRequired],
      locationZipCode: [validateRequired],
      locationCountry: [validateSelectField],
      locationCity: [validateRequired],
      locationPhoneNumber: [validatePhoneNumber],
    };

    const validationErrors = validateForm(
      { ...state.location.data, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    dispatch({
      type: "UPDATE_CONTACT_LOCATION_FIELD_ERROR",
      fieldName: name,
      error: validationErrors[name],
      tab: 'location',
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };
  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_LOCATION_OR_CONTACT_FIELD",
      field: name,
      value: selectedOption,
      tab: "location"
    });
    validation(name, selectedOption, true);
  };

  // const validStatus = (validation: any) => {
  //   setValidationStatus({
  //     isValid: true,
  //     // isValid: validation.isValid,
  //     type: validation.type,
  //   });
  // };
  const validateAllFields = (): boolean => {
    // Track overall validity
    let allFieldsValid = true;

    Object.keys(state.location?.data).forEach((key) => {
      const value = state.location?.data[key as keyof LocationTabData];
      const isFieldValid = validation(key, value, true);
      allFieldsValid = allFieldsValid && isFieldValid; // Combine results
    });

    return allFieldsValid; // Return overall validity
  };

  const handleAddLocation = () => {
    const isValid = validateAllFields();
    if (isValid) {
      dispatch({
        type: "UPDATE_LOCATION_CONTACT_LIST_FIELD",
        value: state.location?.data,
        tab: 'location',
        list: 'locationList',
        isEdit: false,
      });
      const locationAddressFields = [
        { field: "locationId", value: "" },
        { field: "locationPhoneNumber", value: "" },
        { field: "locationName", value: "" },
        { field: "locationStreet", value: "" },
        { field: "locationNumber", value: "" },
        { field: "locationBox", value: "" },
        { field: "locationZipCode", value: "" },
        { field: "locationCity", value: "" },
        { field: "locationCountry", value: "" },
        { field: "initialLat", value: "" },
        { field: "initialLn", value: "" },
      ];

      let formEle: any = {};
      locationAddressFields.forEach((field) => {
        formEle[field?.field] = field?.value
      });

      dispatch({
        type: "UPDATE_ALL_CONTACT_OR_LOCATION_FIELD",
        data: formEle,
        error: {},
        tab: "location",
      });
    }
  };

  const handleEdit = (entryId: number) => {
    dispatch({
      type: "EDIT_PARTICULAR_CONTACT_LOCATION_FIELD",
      index: entryId,
      tab: 'location',
      list: 'locationList',
    });
  };

  const handleArchive = (deleteId: number) => {
    dispatch({
      type: "DELETE_CONTACT_LOCATION_INDEX",
      index: deleteId,
      tab: "location",
      list: "locationList"
    });
  };
  const handleSaveParticularData = () => {
    dispatch({
      type: "UPDATE_LOCATION_CONTACT_LIST_FIELD",
      value: state.location?.data,
      tab: "location",
      list: "locationList",
      isEdit: true,
    });
    const locationAddressFields = [
      { field: "locationId", value: "" },
      { field: "id", value: "" },
      { field: "locationPhoneNumber", value: "" },
      { field: "locationName", value: "" },
      { field: "locationStreet", value: "" },
      { field: "locationNumber", value: "" },
      { field: "locationBox", value: "" },
      { field: "locationZipCode", value: "" },
      { field: "locationCity", value: "" },
      { field: "locationCountry", value: "" },
    ];

    const addressObj: any = {};

    locationAddressFields.forEach((field) => {
      addressObj[field?.field] = field?.value;
    });
    dispatch({
      type: "UPDATE_ALL_CONTACT_OR_LOCATION_FIELD",
      data: addressObj,
      error: {},
      tab: 'location'
    });
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleAddPopup = () => {
    setShowPopup(true); // Close the popup without saving
  };
  const handleClosePopup = () => {
    setShowPopup(false); // or whatever logic you have to close the popup
  };
  const handleConfirm = (details: AddressDetails | null) => {
    if (details) {
      const { street, nr, bus, postal, city, country, coordinates } = details;
      let companyOption: any = {}; // Declare as an empty object

      // Find the country option if country is not empty
      if (country !== "") {
        companyOption =
          state.options?.countries.find((option: any) =>
            option.label.toLowerCase().includes(country.toLowerCase())
          ) || {}; // If no match found, default to an empty object
      }

      // Parse coordinates and ensure they're numbers
      //** set lat and lang /
      let lat = "";
      let lng = "";

      if (coordinates !== "" && coordinates) {
        [lat, lng] = coordinates
          .replace("Lat: ", "")
          .replace("Lng: ", "")
          .split(", ");
      }

      // Prepare the fields for dispatching
      const locationAddressFields = [
        { field: "locationStreet", value: street },
        { field: "locationNumber", value: nr },
        { field: "locationBox", value: bus },
        { field: "locationZipCode", value: postal },
        { field: "locationCity", value: city },
        { field: "locationCountry", value: companyOption },
        { field: "initialLat", value: lat },
        { field: "initialLn", value: lng },
      ];

      // Dispatch company name
      // dispatch({
      //   type: "UPDATE_COMPANYLOCATION_FIELD",
      //   field: "locationName",
      //   value: `${state.basic.companyName}-HQ`,
      // });

      // Dispatch location address fields

      let addressObj: any = {};

      let errorObj: any = {};


      locationAddressFields.forEach((field) => {
        addressObj[field?.field] = field?.value;
        errorObj[field?.field] = "";
      });

      dispatch({
        type: "UPDATE_ALL_CONTACT_OR_LOCATION_FIELD",
        data: addressObj,
        error: errorObj,
        tab: 'location',
      });


      setShowPopup(false); // Close the popup after confirming the address
    }
  };

  const handlePhoneNumberChange = (event: string, name: string) => {
    dispatch({
      type: "UPDATE_LOCATION_OR_CONTACT_FIELD",
      field: name,
      value: event,
      tab: "location",
    });
    validation(name, event, true);
  }

  const renderAddressGoogleMap = () => {
    return (
      <div style={{ marginBottom: "1vw" }}>
        <div className="d-flex align-items-center">
          <Link
            to={""}
            title={t("Search address via google map...")}
            onClick={handleAddPopup}
            className="color-dark-pink marginRightPoint5"
          >
            <ActiveLocation />
          </Link>
          <Link to={""} className="link-color" onClick={handleAddPopup}>
            {t("Search address via google map...")}
          </Link>
        </div>
        <AddAddressLoactionViaMap
          initialLat={
            state.location?.data?.initialLat === ""
              ? 50.8503
              : parseFloat(state.location?.data?.initialLat)
          } // Replace with your initial latitude
          initialLng={
            state.location?.data?.initialLn === ""
              ? 4.3517
              : parseFloat(state.location?.data?.initialLn)
          } // Replace with your initial longitude
          onConfirm={handleConfirm}
          showAddPopup={showPopup}
          onHide={handleClosePopup}
        />
      </div>
    );
  };

  const fieldErrors = state?.location?.errors;
  const isEditField = state?.location?.editIndex;

  return (
    <>
      <form>
        <div className="row">
          <div className="col-12">
            <div className="form-border" style={{ paddingTop: "3vw" }}>
              {state.location?.locationList.length > 0 && (
                <div className="bg-white locationBg">
                  <table className="table table-hover">
                    <thead className="TableHeader">
                      <tr>
                        <th>{t("Location name")}</th>
                        <th>{t("Street")}</th>
                        <th>{t("ZipCode")}</th>
                        <th>{t("Number")}</th>
                        <th>{t("City")}</th>
                        <th>{t("Country")}</th>
                        <th>{t("Actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.location?.locationList.map(
                        (eachLocationData: any, index: number) => (
                          <tr key={index}>
                            <td className="align-middle">
                              {eachLocationData?.locationName}
                            </td>
                            <td className="align-middle">
                              {eachLocationData?.locationStreet}
                            </td>
                            <td className="align-middle">
                              {eachLocationData?.locationZipCode}
                            </td>
                            <td className="align-middle">
                              {eachLocationData?.locationNumber}
                            </td>
                            <td className="align-middle">
                              {eachLocationData?.locationCity}
                            </td>
                            <td className="align-middle">
                              {eachLocationData?.locationCountry?.label}
                            </td>
                            <td className="table-action-icons">
                              <span
                                onClick={() => handleEdit(index)}
                                className="table-action-btn cursor-pointer marginRightPoint5"
                                title={isViewMode ? t("View") : t("Edit")}
                              >
                                {isViewMode ? <ViewIcon /> : <EditIcon />}
                              </span>
                              {!isViewMode && (
                                <span
                                  onClick={() => handleArchive(index)}
                                  className="table-action-btn cursor-pointer"
                                  title={t("Delete")}
                                >
                                  <DeleteIcon />
                                </span>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="row">
                {!isViewMode && renderAddressGoogleMap()}
                {!isViewMode && (
                  <>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationName"
                        handleChange={(event) => handleFieldChange(event)}
                        value={locFormData.locationName ?? ""}
                        id={`locationName`}
                        label="Location name"
                        placeholder="Location name"
                        error={fieldErrors.locationName}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      {/* <LabelWithInputField
                        isMandatory={true}
                        name="locationPhoneNumber"
                        handleChange={(event) => handleFieldChange(event)}
                        value={locFormData?.locationPhoneNumber ?? ""}
                        id={`phoneNumber`}
                        label="Phone number"
                        placeholder="Phone number"
                        error={fieldErrors?.locationPhoneNumber}
                        isDisabled={isViewMode}
                      /> */}
                      {/* <div className="form-control field-shadow">
                        <PhoneInput
                          name="locationPhoneNumber"
                          label={t("Phone number")}
                          required={true}
                          defaultCountry="BE"
                          international
                          initialValueFormat="national"
                          placeholder="Phone number"
                          onChange={(event: any) => handleFieldChange(event)}
                          value={locFormData?.locationPhoneNumber ? ("+" + locFormData?.locationPhoneNumber?.replace(/\+/g, '')) : ""}
                          id='phoneNumber'
                          disabled={isViewMode}
                        /> */}
                      <PhoneNumber
                        label={t("Phone number")}
                        name="locationPhoneNumber"
                        value={locFormData?.locationPhoneNumber ?? ""}
                        defaultCountry='BE'
                        placeholder={t('Phone number')}
                        disabled={isViewMode}
                        handleChange={(event: any) => handlePhoneNumberChange(event, "locationPhoneNumber")}
                        required={true}
                        error={fieldErrors?.locationPhoneNumber}
                      />
                      {/* </div> */}
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationStreet"
                        handleChange={(event) => handleFieldChange(event)}
                        value={locFormData.locationStreet ?? ""}
                        id={`street`}
                        label="Street"
                        placeholder="Street"
                        error={fieldErrors.locationStreet}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationNumber"
                        handleChange={(event) => handleFieldChange(event)}
                        value={locFormData.locationNumber ?? ""}
                        id={`number`}
                        label="Number"
                        placeholder="Number"
                        error={fieldErrors.locationNumber}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <LabelWithInputField
                        isMandatory={false}
                        name="locationBox"
                        handleChange={(event) => handleFieldChange(event)}
                        value={locFormData.locationBox}
                        id={`box`}
                        label="Box"
                        type="text"
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationZipCode"
                        handleChange={(event) => handleFieldChange(event)}
                        value={locFormData.locationZipCode}
                        label="Zip code"
                        type="text"
                        error={fieldErrors.locationZipCode}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationCity"
                        handleChange={(event) => handleFieldChange(event)}
                        value={locFormData.locationCity}
                        label="City"
                        type="text"
                        error={fieldErrors.locationCity}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <SelectWithSearch
                        title="Country"
                        name="locationCountry"
                        isMandatory={true}
                        search={true}
                        options={state.options?.countries}
                        placeHolder="Select"
                        value={locFormData.locationCountry}
                        onChange={(e) =>
                          handleSelectChange(e, "locationCountry")
                        }
                        isMulti={false}
                        className="select-field"
                        error={fieldErrors.locationCountry}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-lg-12">
                      <LabelWithTextAreaField
                        name="locationInfo"
                        handleChange={(event) => handleFieldChange(event)}
                        value={locFormData.locationInfo ?? ""}
                        label="Info"
                        isDisabled={isViewMode}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="row">
                <div className="col-md-12">
                  {!isViewMode && (
                    <Button
                      title={
                        isEditField !== null
                          ? t("Save")
                          : t("+ Add another")
                      }
                      handleClick={
                        isEditField !== null
                          ? handleSaveParticularData
                          : handleAddLocation
                      }
                      className="form-button float-end"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form >
    </>
  );
};

export default Address;
