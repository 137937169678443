import React from "react";


import { FormProvider } from "./context/Context";
import { CompanyForm } from ".";

export const CompanyFormProvider = () => {
  return (
    <>
      <FormProvider>
        <CompanyForm />
      </FormProvider>
    </>
  );
};
