import { FormMode } from "components/common/CommonEnums";

export const SWITCH_TAB = "SWITCH_TAB";
export const SET_OPTIONS = "SET_OPTION";
export const SET_MODE = "SET_MODE";
export const UPDATE_CURRENT_TAB = "UPDATE_CURRENT_TAB";
export const INITIAL_STATE = "INITIAL_STATE";
export const SET_ERRORS_COMPANY_BASIC_TAB = "SET_ERRORS_COMPANY_BASIC_TAB";
export const SET_ERRORS_COMPANY_ADDRESS_TAB = "SET_ERRORS_COMPANY_ADDRESS_TAB";
export const SET_ERRORS_COMPANY_CONTACT_PERSON_TAB =
  "SET_ERRORS_COMPANY_CONTACT_PERSON_TAB";
export const UPDATE_TAB_ERROR = "UPDATE_TAB_ERROR";
export const UPDATE_COMPANY_BASIC_FIELD = "UPDATE_COMPANY_BASIC_FIELD";
export const SET_FIELD_ERROR_COMPANY_BASIC_TAB =
  "SET_FIELD_ERROR_COMPANY_BASIC_TAB";
export const SET_COMPANY_OPTION = "SET_COMPANY_OPTION";
export const UPDATE_COMPANY_ADDRESS_FIELD = "UPDATE_COMPANY_ADDRESS_FIELD";
export const UPDATE_COMPANY_ADDRESS_ERROR = "UPDATE_COMPANY_ADDRESS_ERROR";
export const UPDATE_COMPANY_LOCATION_FIELD = "UPDATE_COMPANY_LOCATION_FIELD";
export const UPDATE_COMPANY_LOCATION_TABLE_FIELD =
  "UPDATE_COMPANY_LOCATION_TABLE_FIELD";
export const EDIT_COMPANY_LOCATION_FIELD = "EDIT_COMPANY_LOCATION_FIELD";
export const UPDATE_COMPANY_LOCATION_EDITED_FIELD =
  "UPDATE_COMPANY_LOCATION_EDITED_FIELD";
export const DELETE_COMPANY_LOCATION_INDEX = "DELETE_COMPANY_LOCATION_INDEX";
export const UPDATE_COMPANY_CONTACTS_FIELD = "UPDATE_COMPANY_CONTACTS_FIELD";
export const UPDATE_COMPANY_CONTACT_PERSON_FIELD_ERROR =
  "UPDATE_COMPANY_CONTACT_PERSON_FIELD_ERROR";
export const UPDATE_COMPANY_CONTACT_PERSON_TABLE_FIELD =
  "UPDATE_COMPANY_CONTACT_PERSON_TABLE_FIELD";
export const EDIT_COMPANY_CONTACT_PERSON_FIELD =
  "EDIT_COMPANY_CONTACT_PERSON_FIELD";
export const DELETE_COMPANY_CONTACT_PERSON_INDEX =
  "DELETE_COMPANY_CONTACT_PERSON_INDEX";
export const UPDATE_COMPANY_CONTACT_PERSON_EDITED_FIELD =
  "UPDATE_COMPANY_CONTACT_PERSON_EDITED_FIELD";
export const COMPANY_ADD_DOCUMENT = "COMPANY_ADD_DOCUMENT";
export const REMOVE_COMPANY_DOCUMENT_FIELD = "REMOVE_COMPANY_DOCUMENT_FIELD";
export const UPDATE_TAB_DATA = "UPDATE_TAB_DATA";
export const ADD_UPDATE_DYNAMIC_COMPANY_LOCATION =
  "ADD_UPDATE_DYNAMIC_COMPANY_LOCATION";
export const ADD_UPDATE_DYNAMIC_COMPANY_CONTACT_PERSON =
  "ADD_UPDATE_DYNAMIC_COMPANY_CONTACT_PERSON";
export const ADD_UPDATE_DYNAMIC_COMPANY_DOCUMENT =
  "ADD_UPDATE_DYNAMIC_COMPANY_DOCUMENT";
export const UPDATE_VIEW_MODE = "UPDATE_VIEW_MODE";

export const INITIAL_STATE_OBJECT = {
  mode: FormMode.CREATE,
  tabDetails: [
    {
      id: "companyBasic",
      title: "Basic",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "companyAddress",
      title: "Address",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "companyLocation",
      title: "location",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "companyContactPerson",
      title: "Contact person",
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "companyDocument",
      title: "Document",
      showStatus: true,
      error: false,
      draft: false,
    },
  ],
  tabErrors: [],
  companyBasic: {
    data: {
      id: "",
      vat: "",
      companyName: "",
      officialName: "",
      pc: [],
      sector: [],
      domain: [],
      function: [],
      consultant: null,
      naceCodes: [],
      companyType: null,
      isBussinessUnit: false,
      bussinessUnit: null,
      website: "",
      hq: 0,
      parentCompanies: null,
      coEfficient: 0,
      companyStatus: 0,
      active: true,
    },
    errors: {
      vat: "",
      companyName: "",
      officialName: "",
      companyType: "",
      bussinessUnit: "",
    },
  },

  companyLocation: {
    data: {
      locationName: "",
      locationPhoneNumber: "",
      locationStreet: "",
      locationNumber: "",
      locationBox: "",
      locationZipCode: "",
      locationCountry: "",
      locationCity: "",
      initialLat: "",
      initialLn: "",
      id: "",
      locationId: "",
      locationInfo: "",
    },
    errors: {
      locationName: "",
      locationStreet: "",
      locationNumber: "",
      locationZipCode: "",
      locationCountry: "",
      locationCity: "",
    },
  },
  companyAddress: {
    data: {
      street: "",
      number: "",
      box: "",
      zipCode: "",
      country: null,
      city: "",
      biStreet: "",
      biNumber: "",
      biBox: "",
      biZipCode: "",
      biCountry: null,
      biCity: "",
      hqAddress: 0,
      sameAddress: 1,
      initialLat: "",
      initialLn: "",
      biInitialLat: "",
      biInitialLn: "",
    },
    errors: {
      street: "",
      number: "",
      zipCode: "",
      country: "",
      city: "",
      biStreet: "",
      biNumber: "",
      biZipCode: "",
      biCountry: "",
      biCity: "",
      hqAddress: "",
      sameAddress: "",
    },
  },

  companyContactPerson: {
    data: {
      tittle: null,
      fName: "",
      lName: "",
      email: "",
      company: null,
      mobileNumber: "",
      language: null,
      functionData: "",
      linkedIn: "",
      roles: [],
      dob: "",
      id: "",
      location: [],
      businessUnit: [],
      info: "",
      userId: "",
    },
    errors: {
      fName: "",
      lName: "",
      email: "",
      mobileNumber: "",
    },
  },
  companyLocationTable: [],
  companyContactPersonTable: [],
  companyDocument: [],
  currentTab: "companyBasic",
  isViewMode: false,

  options: {
    countryList: [],
    pcList: [],
    sectorList: [],
    domainList: [],
    functionList: [],
    categoryList: [],
    consultantList: [],
    locationList: [],
    companiesList: [],
    rolesList: [],
    languageList: [],
    naceCodesList: [],
    bussinessUnitList: [],
    genders: [],
    contactsList: [],
    tittle: [],
    documentCategoryList: [],
  },
};
