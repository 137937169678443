import BackButton from "components/atoms/BackButton";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import FilterOrganism from "components/organism/FilterOrganism";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addPageToHistory, selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { PayRollTableHeader } from "TableHeader";
import Title from "components/atoms/Title";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { getManageData } from "../utils/ApiCalls";
import Popup from "components/molecules/Popup";
import ExportPopup from "../atoms/ExportPopup";
import DatePicker from "react-multi-date-picker";
import { PAGENAMES } from "store/pageHistory/ConstantPageNames";
import { PATH_WORK_PAY_BILL_ROLL } from "constants/Paths";

const ManagePayrollOverviewOrganism: React.FC = () => {
  const navigate = useNavigate();
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true,
    data: [],
    options: {
      businessUnits: [],
      ContractType: [],
    },
    export_popup: {
      show: false,
      start_month: null,
      end_month: null,
    },
  });

  useEffect(() => {
    getManageData({ history, pageData, dispatch, setPageData, updatePageFilters })
  }, [pageData?.refresh, pageData?.initialCall]);

  const handleRowClick = (item: any) => {    
    dispatch(addPageToHistory({
      pageName: PAGENAMES?.PAY_ROLL_OVERVIEW,
      route: PATH_WORK_PAY_BILL_ROLL,
      filters: {
        employees: [item?.id] ?? [],
        selectedDate: history?.filters?.selected_date ? history?.filters?.selected_date : new Date()?.toISOString(),
      }
    }))
    navigate(PATH_WORK_PAY_BILL_ROLL);
  };

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const filters = [
    { name: 'businessUnits', fieldType: "multiSelect", options: pageData?.options?.businessUnits, placeholder: "Business unit", filterType: 'search' },
    { name: 'employee', fieldType: "text", placeholder: "Employee", filterType: 'search' },
    { name: 'contract_type', fieldType: "singleSelect", placeholder: "Contract type", options: pageData?.options?.ContractType, filterType: 'search' },
  ];

  const handleTableDataChange = (name: any, value = false) => {
    console.log(name, value);

  }


  return (
    <>
      <div className="search-bar">
        <div className="row">
          <Title title={t("Payroll")} />
          <div className="col-12">
            <div className="text-end">
              <DatePicker
                inputClass="form-control text-center field-shadow cursor-pointer"
                name="selected_date"
                placeholder={"year"}
                value={history?.filters?.selected_date ? new Date(history?.filters?.selected_date) : new Date()}
                onChange={(e) => {
                  let selectedDate = null;
                  if (e && !Array.isArray(e)) {
                    selectedDate = new Date(e.year, e.month.number - 1, e.day); // Extract and create a Date object
                  }                  
                  dispatch(updatePageFilters({ filters: { selected_date: selectedDate?.toISOString() } }));
                  handleRefresh();
                }}
                format="MMMM YYYY"
                onlyMonthPicker={true}
                minDate={new Date(2014, 0, 1)}
                zIndex={1000}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="table-responsive managePayroll tableSection">
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={filters}
                handleRefresh={handleRefresh}
              />
              <div className="TableHeight">
                <DynamicTableStructure
                  data={pageData?.data}
                  headers={PayRollTableHeader}
                  // selectAll={history?.filters?.select_all ?? false}
                  // handleSelect={(e) => handleTableDataChange(
                  //     e?.target?.name ?? 'selected_data',
                  //     e?.target?.checked ?? e
                  // )}
                  // isChecked={(e: any) => (history?.filters?.selected_data ?? [])?.includes(e?.id)}
                  handleRowClick={(e) => handleRowClick(e)}
                />
              </div>
              <div className="">
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={pageData}
                />
              </div>
            </div>
          </div>
          {pageData?.export_popup?.show && (
            <Popup
              title="Select date"
              body={
                <ExportPopup
                  handleChange={(e: any, name: any) =>
                    setPageData((prev: any) => ({ ...prev, [name]: e }))
                  }
                  pageData={pageData}
                />
              }
              yestext="Submit"
              notext="Cancel"
              submit={() => { }}
              cancel={() =>
                setPageData((prev: any) => ({ ...prev, show: null, start_month: null, end_month: null }))
              }
            />
          )}
          {/* <div className="backPadding">
            <BackButton />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ManagePayrollOverviewOrganism;
