import Button from "components/atoms/Button";
import LabelField from "components/atoms/LabelField";
import ModalPopup from "components/atoms/ModalPopup";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import { ADD_DOCUMENT, REMOVE_DOCUMENT } from "pages/microservices/CandidateCreation/annotations/CandidateConstants";
import { documents } from "pages/microservices/CandidateCreation/annotations/CandidateInterface";
import { useFormContext } from "pages/microservices/CandidateCreation/context/Context";
import Calender from "pages/microservices/CandidateCreation/helpers/Calender";
import { documentInitialState, formatDate } from "pages/microservices/CandidateCreation/helpers/CandidateHelperFunctions";
import { t } from "pages/microservices/masterData/translation/Translation";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { validateDate, validateFileRequired, validateForm, validateRequired, validateSelectField } from "services/validation/ValidationService";
import DeleteIcon from "static/images/DeleteIcon";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import { Option, ValidationRules } from "utils/TypeAnnotations";

interface DocumentFilterProps {
  categoryFilter: Option | null,
  fileNameFilter: string,
  fileExtensionFilter: Option | null
}

const Documents: React.FC = () => {
  const { state, dispatch } = useFormContext();
  const location = useLocation();
  const isView = location?.pathname?.includes('view');
  const [formData, setFormData] = useState<documents>(documentInitialState);
  const extensionArray = ['png', 'jpg', 'jpeg', 'doc', 'docx', 'xlx', 'pdf', 'ppt'];
  const extensionOptions = extensionArray.map((ext: string, index) => ({ 'label': ext, 'value': index }));
  const [search, setSearch] = useState<DocumentFilterProps>({
    categoryFilter: null,
    fileNameFilter: '',
    fileExtensionFilter: null
  });
  const intialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{
    [key: string]: string;
  }>(intialErrors);

  const filteredDocuments = state.documents.filter((document, index) => {
    const categoryMatch = search.categoryFilter?.value
      ? document?.category?.value === search.categoryFilter?.value
      : true;

    const fileNameMatch = search.fileNameFilter
      ? document?.fileName?.toLowerCase().includes(search.fileNameFilter?.toLowerCase())
      : true;

    const extensionMatch = search.fileExtensionFilter
      ? search.fileExtensionFilter.label !== 'Select'
        ? document.fileExtension.includes(search.fileExtensionFilter.label)
        : true
      : true;
    // Return true if all conditions are matched or not provided
    return categoryMatch && fileNameMatch && extensionMatch;
  });

  const validation = (
    name: string,
    value: string | number | null | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      fileUpload: [validateFileRequired],
      fileName: [validateRequired],
      category: [validateSelectField],
      validFrom: [validateDate],
    };
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: REMOVE_DOCUMENT, indexToRemove });
  };

  const [modal, setModal] = useState(false);
  const [index, setIndex] = useState(0);

  const addData = (index: number) => {
    setFormData((prevData: any) => ({
      ...prevData,
      editId: index,
    }));
    setIndex(index); // Set the index for dispatch
    setModal(true);
  };

  const editData = (id: number | null, index: number) => {
    setIndex(index); // Set the index for dispatch
    setModal(true);
    let documentToEdit = [];
    // Get the document to edit
    if (id !== null) {
      documentToEdit = state.documents.filter((doc) => doc.id === id);
    } else {
      documentToEdit = state.documents.filter((doc) => doc.editId === index);
    }
    if (documentToEdit && documentToEdit.length > 0) {
      const document = documentToEdit[0];
      // Populate the  data in the modal fields
      setFormData({
        id: document.id,
        editId: document.editId,
        fileUpload: {
          id: document.fileUpload?.id || null,
          accessUrl: document.fileUpload?.accessUrl || '', // Ensure the accessUrl is set correctly
          fileName: document.fileUpload?.fileName || '',   // Ensure the fileName is set correctly
        },
        fileName: document.fileName,
        fileExtension: document.fileExtension,
        category: document.category,
        validFrom: document.validFrom,
        validUntil: document.validUntil,
      });
    }
  };

  const resetFields = () => {
    setFormData({
      id: null,
      editId: null,
      fileUpload: {
        id: null,
        accessUrl: '',  // Initialize with an empty string
        fileName: '',   // Initialize with an empty string
      },
      fileName: '',
      fileExtension: '',
      category: null,
      validFrom: null,
      validUntil: null,
    });
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    //Filters
    setSearch((prevData: any) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    validation(fieldName, selectedOption, true);
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type, checked } = event.target as HTMLInputElement;
    let updatedValue: string | number | null = value;
    if (type === "checkbox") {
      updatedValue = checked ? 1 : 0
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
    //Filters
    setSearch((prevData: any) => ({
      ...prevData,
      [name]: updatedValue,
    }));
    validation(name, updatedValue, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      dispatch({
        type: ADD_DOCUMENT,
        data: formData,
        index: index,
      });
      setModal(false); // Close modal
      resetFields();
      setErrors({});
    }
  };

  const closeModal = () => {
    setModal(false);
    resetFields();
    setErrors({});
  };

  const handleFileChange = async (event: any, field: any) => {
    let fileData: any = null;
    let fileName: any = "";
    if (event !== null) {
      fileData = await handleSingleFileUpload(event, "documents");
      fileName = event?.name;
    }
    const index = fileName?.lastIndexOf(".");
    const name = index !== -1 ? fileName?.slice(0, index - 1) : fileName;
    const extension = index !== -1 ? fileName?.slice(index) : "";
    if (fileData !== null) {
      setFormData((prevData: any) => ({
        ...prevData,
        fileName: name,
        fileExtension: extension,
        [field]: fileData,
      }));
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        fileName: name,
        fileExtension: extension,
        [field]: {
          accessUrl: "",
          fileName: ""
        },
      }));
    }
    validation(field, fileData, true);
    validation("fileName", event?.name, true);
  };

  const handleDateChange = (date: Date | null, name: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: date,
    }));
    validation(name, date, true);
  };

  return (
    <>
      <div className="row" style={isView ? { pointerEvents: "auto" } : {}}>
        <div className="col-12">
          <LabelField title={t("Documents")} className="tab-subtitle" />
        </div>
        <div className="col">
          <SelectWithSearch
            title={t("Category ")}
            isMandatory={false}
            search={true}
            options={state.dropDownData.documentList}
            onChange={(e) => handleSelectChange(e, "categoryFilter")}
            isMulti={false}
            name="categoryFilter"
            value={search.categoryFilter}
            placeHolder="Select"
          />
        </div>
        <div className="col">
          <LabelWithInputField
            isMandatory={false}
            name="fileNameFilter"
            handleChange={handleFieldChange}
            id="fileNameFilter"
            label="File name"
            type="text"
            value={search.fileNameFilter}
          />
        </div>
        <div className="col">
          <SelectWithSearch
            title={t("File extension ")}
            isMandatory={false}
            search={true}
            options={extensionOptions}
            onChange={(e) => handleSelectChange(e, "fileExtensionFilter")}
            isMulti={false}
            name="fileExtension"
            value={search.fileExtensionFilter}
            placeHolder="Select"
          />
        </div>
      </div>
      <div>
        <ModalPopup
          show={modal}
          onHide={closeModal}
          title={t("Documents")}
          body={
            <div className="row">
              <div className="col-6 fileUploadFullWidth candidateDoumentsFileUpload">
                <MaxiconFileUpload
                  label={t('File upload')}
                  name={t('fileUpload')}
                  id={t('fileUpload')}
                  isMandatory={true}
                  edit={false}
                  fileId={null}
                  fileObj={JSON.stringify(formData.fileUpload)}
                  multiple={false}
                  uploadPath="candidate-documents"
                  formats={'value.formats'}
                  handleChange={(e: any) => handleFileChange(e, "fileUpload")}
                  maxFileSize={10000}
                  error={errors.fileUpload}
                  mode={isView}
                />
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center">
                  <LabelWithInputField
                    isMandatory={true}
                    name="fileName"
                    handleChange={handleFieldChange}
                    id="fileName"
                    label="File name"
                    type="text"
                    value={formData.fileName}
                    error={errors.fileName}
                  />
                  <span style={{ marginLeft: "0.25vw" }}>{formData.fileExtension}</span>
                </div>
              </div>
              <div className="col-4">
                <SelectWithSearch
                  title={t("Category ")}
                  isMandatory={true}
                  search={true}
                  options={state.dropDownData.documentList}
                  onChange={(e) => handleSelectChange(e, "category")}
                  isMulti={false}
                  name="category"
                  value={formData.category}
                  error={errors.category}
                  placeHolder="Select"
                  isDisabled={isView}
                />
              </div>
              <div className="col-4">
                <Calender
                  onChange={(date) => handleDateChange(date, "validFrom")}
                  label={t('Valid from')}
                  isMandatory={true}
                  name={'validFrom'}
                  error={errors.validFrom}
                  selectedDate={formData.validFrom}
                  maxDate={formData.validUntil}
                  isDisabled={isView}
                ></Calender>
              </div>
              <div className="col-4">
                <Calender
                  onChange={(date) => handleDateChange(date, "validUntil")}
                  label={t('Valid until')}
                  isMandatory={false}
                  name={'validUntil'}
                  error={errors.validUntil}
                  selectedDate={formData.validUntil}
                  minDate={formData.validFrom}
                  isDisabled={isView}
                ></Calender>
              </div>
              <div className="col-12 d-flex justify-content-end">
                {!isView && <Button
                  title={t('Save')}
                  handleClick={handleSubmit}
                  className="form-button shadow-none px-4"
                />}
              </div>

            </div>

          }
          className="modal-lg contractPerfCodeModal"
        />
      </div>
      <div>
        <div className="pwa table-responsive  scrollBar">
          <table className="table table-hover mb-0">
            <thead>
              <tr className="border-0 TableHeader bg-white">
                <th className="border-0 align-middle col" >
                  {t("File name")}
                </th>
                <th className="border-0 align-middle col" >
                  {t("Category")}
                </th>
                <th className="border-0 align-middle col" >
                  {t("Valid")}
                </th>
                <th className="border-0 align-middle col" >
                  {t("Valid until")}
                </th>
                <th
                  className="border-0 align-middle col" >
                  {t("Action")}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredDocuments.length > 0 && filteredDocuments.map(
                (document, index) => (
                  <tr key={index}>
                    <td
                      className="align-middle text-break"
                      data-label={t("File name")}
                    >
                      {document?.fileName}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("category")}
                    >
                      {document.category?.label}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("Document number")}
                    >
                      {formatDate(document.validFrom)}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("Document number")}
                    >
                      {formatDate(document.validUntil)}
                    </td>
                    <td className="align-middle text-break table-action-icons">
                      <span
                        title={t("Edit")}
                        onClick={() => editData(document.id, document.editId ?? index)}
                        className="table-action-btn cursor-pointer shadow-none marginRightPoint5"
                      >
                        {isView ? <ViewIcon /> : <EditIcon />}
                      </span>

                      {!isView &&
                        <span
                          title={t("Delete")}
                          onClick={() => removeFieldSet(document.editId ?? index)}
                          className="table-action-btn cursor-pointer shadow-none"
                        >
                          <DeleteIcon />
                        </span>}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row" style={{ paddingTop: "1vw" }}>
        <div className="col-12">
          <Button
            title={"+ " + t("Add")}
            handleClick={() =>
              addData(state.documents.length)
            }
            className="form-button float-end"
          />
        </div>
      </div>
    </>
  );
};

export default Documents;