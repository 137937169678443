import React, { useEffect, useState } from "react";

import { t } from "pages/microservices/masterData/translation/Translation";
import { M_COMPANY_CREATION, M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import {
  COMPANY_OPTIONS,
  CREATE_COMPANY,
  GET_COMPANY_DATA_FOR_EDIT,
} from "routes/ApiEndpoints";

import {
  validateForm,
  validateMultiSelectField,
  validateNoSpaces,
  validateRequired,
  validateSelectField,
  validateVatNumber,
} from "services/validation/ValidationService";

import { FormMode } from "components/common/CommonEnums";
import { CREATE_CANDIDATE, PATH_PROJECTS_MANAGE } from "constants/Paths";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isObjectEmpty } from "services/util/UtilService";

import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import CompanyBasic from "./components/CompanyBasic";
import CompanyContactPerson from "./components/CompanyContactPerson";
import CompanyAddress from "./components/CompanyAddress";
import CompanyDocument from "./components/CompanyDocument";
import CompanyLocation from "./components/CompanyLocation";

import {
  ADD_UPDATE_DYNAMIC_COMPANY_CONTACT_PERSON,
  ADD_UPDATE_DYNAMIC_COMPANY_DOCUMENT,
  ADD_UPDATE_DYNAMIC_COMPANY_LOCATION,
  INITIAL_STATE,
  INITIAL_STATE_OBJECT,
  SET_COMPANY_OPTION,
  SET_ERRORS_COMPANY_ADDRESS_TAB,
  SET_ERRORS_COMPANY_BASIC_TAB,
  SET_ERRORS_COMPANY_CONTACT_PERSON_TAB,
  SWITCH_TAB,
  UPDATE_COMPANY_BASIC_FIELD,
  UPDATE_COMPANY_CONTACTS_FIELD,
  UPDATE_CURRENT_TAB,
  UPDATE_TAB_DATA,
  UPDATE_TAB_ERROR,
  UPDATE_VIEW_MODE,
} from "./context/Constants";
import FormNavigationOrganism from "components/organism/Actions/FormNavigationOrganism";
import { useFormContext } from "./context/Context";
import {
  CompanyAddressTabValidationRules,
  CompanyBasicTabValidationRules,
  CompanyContactsTabValidationRules,
} from "./validationRules/CompanyValidationRules";
import {
  AddressTabError,
  BasicTabError,
  ContactPersonTabData,
  ContactPersonTabError,
  LocationTabData,
} from "./context/Interfaces";
import { mapToSelect } from "utils/MapToSelect";
import CustomNotify from "components/atoms/CustomNotify";

export const CompanyForm = () => {
  const { state, dispatch } = useFormContext();
  const { id } = useParams<{ id: string }>();
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();

  const accessType = location?.pathname?.split("/");
  const navigate = useNavigate();
  const type = accessType.includes("view")
    ? "View"
    : accessType.includes("create")
    ? "Create"
    : "Edit";

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    dispatch({
      type: INITIAL_STATE,
      payload: INITIAL_STATE_OBJECT,
    });

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const companyFormHasErrors = (): boolean => {
    let hasErrors = false;

    state.tabDetails.forEach((tab, index) => {
      let tabHasErrors = false;

      switch (tab.id) {
        case "companyBasic":
          tabHasErrors = !isObjectEmpty(
            handleFieldValidationsCompanyBasicTab()
          );
          break;
        case "companyAddress":
          tabHasErrors = !isObjectEmpty(
            handleFieldValidationsCompanyAddressTab()
          );
          break;
        case "companyContactPerson":
          tabHasErrors = !isObjectEmpty(
            handleFieldValidationsCompanyContactPersonTab()
          );
          break;

        default:
          break;
      }

      dispatch({
        type: UPDATE_TAB_ERROR,
        tabIndex: index,
        error: tabHasErrors,
        draft: tab.draft,
      });

      if (tabHasErrors) {
        hasErrors = true;
      }
    });

    return hasErrors;
  };
  const handleFieldValidationsCompanyBasicTab = (): BasicTabError => {
    let newbasicTabErrors = validateForm(
      state.companyBasic.data,
      CompanyBasicTabValidationRules
    ) as Object;

    // Check if isBussinessUnit is true and validate bussinessUnit
    if (state.companyBasic.data.isBussinessUnit) {
      // Add bussinessUnit validation here, assuming you already have rules for it
      const bussinessUnitErrors = validateForm(
        { bussinessUnit: state.companyBasic.data.bussinessUnit },
        { bussinessUnit: [validateMultiSelectField] } // Assuming "required" is the rule for bussinessUnit
      ) as Object;

      newbasicTabErrors = { ...newbasicTabErrors, ...bussinessUnitErrors };
    }

    dispatch({
      type: SET_ERRORS_COMPANY_BASIC_TAB,
      errors: newbasicTabErrors as BasicTabError,
    });

    return newbasicTabErrors as BasicTabError;
  };

  const handleFieldValidationsCompanyAddressTab = (): AddressTabError => {
    let newAddressTabErrors = validateForm(
      state.companyAddress.data,
      CompanyAddressTabValidationRules
    ) as Object;

    dispatch({
      type: SET_ERRORS_COMPANY_ADDRESS_TAB,
      errors: newAddressTabErrors as AddressTabError,
    });

    return newAddressTabErrors as AddressTabError;
  };

  const handleFieldValidationsCompanyContactPersonTab =
    (): ContactPersonTabError => {
      let newCompanyContactPersonTabErrors = {};

      if (state.companyContactPersonTable.length > 0) {
        const contact = state.companyContactPerson.data;
        const allFieldsEmpty = Object.values(contact).every(
          (value) =>
            value === "" ||
            value === null ||
            value === undefined ||
            (Array.isArray(value) && value.length === 0)
        );
        if (!allFieldsEmpty) {
          newCompanyContactPersonTabErrors = validateForm(
            state.companyContactPerson.data,
            CompanyContactsTabValidationRules
          ) as Object;
          dispatch({
            type: SET_ERRORS_COMPANY_CONTACT_PERSON_TAB,
            errors: newCompanyContactPersonTabErrors as ContactPersonTabError,
          });
        }
      }
      if (state.companyContactPersonTable.length == 0) {
        newCompanyContactPersonTabErrors = validateForm(
          state.companyContactPerson.data,
          CompanyContactsTabValidationRules
        ) as Object;
        dispatch({
          type: SET_ERRORS_COMPANY_CONTACT_PERSON_TAB,
          errors: newCompanyContactPersonTabErrors as ContactPersonTabError,
        });
      }
      return newCompanyContactPersonTabErrors as ContactPersonTabError;
    };

  const ConstructTabs = (tab: string | number) => {
    switch (tab) {
      case "companyBasic":
        return <CompanyBasic type={type} />;
      case "companyAddress":
        return <CompanyAddress type={type} />;
      case "companyLocation":
        return <CompanyLocation type={type} />;
      case "companyContactPerson":
        return <CompanyContactPerson type={type} />;

      case "companyDocument":
        return <CompanyDocument type={type} />;
      default:
        break;
    }
  };

  useEffect(() => {
    getOptions();
    // dispatch({
    //   type: UPDATE_VIEW_MODE,
    //   value: type,
    // });
  }, []);

  async function getOptions() {
    if (id) {
      companyDetails(id);
      dispatch({
        type: UPDATE_VIEW_MODE,
        value: type,
      });
    } else {
      await options(!!id);
    }
  }
  async function companyDetails(id: string) {
    if (!id) {
      return;
    }
    options(!!id);
    const response = await ApiCall.service(
      GET_COMPANY_DATA_FOR_EDIT,
      "POST",
      { companyId: id },
      false,
      M_COMPANY_CREATION
    );

    if (response?.status === 200) {
      const {
        basicDetails,
        addressDetails,
        contactDetails,
        companyLocationDetails,
        documents,
        locations,
      } = response?.data;
      dispatch({
        type: UPDATE_TAB_DATA,
        field: "companyBasic",
        value: basicDetails,
      });
      dispatch({
        type: SET_COMPANY_OPTION,
        field: "locationList",
        value: locations,
      });

      dispatch({
        type: UPDATE_TAB_DATA,
        field: "companyAddress",
        value: addressDetails,
      });
      if (contactDetails && contactDetails.length > 0) {
        dispatch({
          type: ADD_UPDATE_DYNAMIC_COMPANY_CONTACT_PERSON,
          field: "companyContactPersonTable",
          value: contactDetails,
        });
      }
      if (companyLocationDetails && companyLocationDetails.length > 0) {
        dispatch({
          type: ADD_UPDATE_DYNAMIC_COMPANY_LOCATION,
          field: "companyLocationTable",
          value: companyLocationDetails,
        });
      }
      if (documents && documents.length > 0) {
        dispatch({
          type: ADD_UPDATE_DYNAMIC_COMPANY_DOCUMENT,
          data: documents,
        });
      }
    }
  }

  async function options(isEdit: boolean) {
    try {
      const response = await ApiCall.getService(
        COMPANY_OPTIONS,
        "GET",
        M_COMPANY_CREATION
      );

      if (response?.status === 200) {
        const {
          countries,
          paritairCommittees,
          parentCompany,
          naceCodes,
          bussinessUnit,
          roles,
          genders,
          language,
          tittle,
          documentCategory,
          sector,
          consultant,
        } = response?.data;

        const pcOptions = paritairCommittees.map((item: any) => ({
          value: item.id,
          label: `(${item.pc_number}) ${item.pc_name}`,
        }));

        const parentCompanyOptions = parentCompany.map((item: any) => ({
          value: item.id,
          label: item.company_name,
        }));

        if (
          userData.role.includes("SALES") ||
          userData.role.includes("HR") ||
          userData.role.includes("ADMIN") ||
          userData.role.includes("SUPERADMIN")
        ) {
          if (!isEdit) {
            const option = {
              label: userData.name,
              value: userData.userId,
            };
            dispatch({
              type: UPDATE_COMPANY_BASIC_FIELD,
              field: "isBussinessUnit",
              value: true,
            });
            dispatch({
              type: UPDATE_COMPANY_BASIC_FIELD,
              field: "companyType",
              value: { value: 2, label: "External" },
            });
            dispatch({
              type: UPDATE_COMPANY_CONTACTS_FIELD,
              field: "businessUnit",
              value: userData.businessUnit,
            });
            dispatch({
              type: UPDATE_COMPANY_BASIC_FIELD,
              field: "consultant",
              value: option,
            });
            dispatch({
              type: UPDATE_COMPANY_BASIC_FIELD,
              field: "bussinessUnit",
              value: userData.businessUnit,
            });
          }
        }
        dispatch({
          type: SET_COMPANY_OPTION,
          field: "bussinessUnitList",
          value: bussinessUnit,
        });

        dispatch({
          type: SET_COMPANY_OPTION,
          field: "countryList",
          value: mapToSelect(countries),
        });
        dispatch({
          type: SET_COMPANY_OPTION,
          field: "pcList",
          value: pcOptions,
        });
        dispatch({
          type: SET_COMPANY_OPTION,
          field: "companiesList",
          value: parentCompanyOptions,
        });
        dispatch({
          type: SET_COMPANY_OPTION,
          field: "naceCodesList",
          value: mapToSelect(naceCodes),
        });
        dispatch({
          type: SET_COMPANY_OPTION,
          field: "rolesList",
          value: mapToSelect(roles),
        });
        dispatch({
          type: SET_COMPANY_OPTION,
          field: "genders",
          value: mapToSelect(genders),
        });
        dispatch({
          type: SET_COMPANY_OPTION,
          field: "languageList",
          value: language,
        });
        dispatch({ type: SET_COMPANY_OPTION, field: "tittle", value: tittle });
        dispatch({
          type: SET_COMPANY_OPTION,
          field: "sectorList",
          value: sector,
        });
        dispatch({
          type: SET_COMPANY_OPTION,
          field: "consultantList",
          value: consultant,
        });
        dispatch({
          type: SET_COMPANY_OPTION,
          field: "documentCategoryList",
          value: documentCategory,
        });
      }
    } catch (error) {
      console.error("Error fetching company options:", error);
    }
  }
  const generateLocationOptions = () => {
    const locationTable = state.companyLocationTable; // Existing table data
    const locationData = state.companyLocation.data; // Single location data

    const requiredFields = [
      "locationName",
      "locationNumber",
      "locationCity",
      "locationCountry",
      "locationZipCode",
      "locationStreet",
    ];

    // Check if locationData has all required fields
    const isLocationDataValid = requiredFields.every(
      (field) => locationData[field as keyof typeof locationData]
    );

    let options: { value: number; label: string }[] = [];

    if (locationTable.length === 0) {
      // Case 1: Table is empty
      if (isLocationDataValid) {
        options.push({
          value: 1, // Start value from 1 instead of 0
          label: locationData.locationName, // Use locationName as label
        });
      }
    } else {
      // Case 2: Table has data
      locationTable.forEach((item) => {
        options.push({
          value: Number(item.locationId), // Use locationId for existing entries
          label: item.locationName, // Use locationName from table
        });
      });

      if (isLocationDataValid) {
        options.push({
          value: locationTable.length + 1, // Next index for new entry, start from 1 instead of 0
          label: locationData.locationName, // Use locationName from locationData
        });
      }
    }

    return options;
  };

  function handleTabSwitch(tab: string) {
    dispatch({
      type: SWITCH_TAB,
      field: "currentTab",
      value: tab,
    });
  }

  // Navigation tabs
  const handlePreviousStep = () => {
    const currentTabIndex = state.tabDetails.findIndex(
      (tab) => tab.id === state.currentTab
    );
    const nextTabIndex = (currentTabIndex - 1) % state.tabDetails.length;
    const nextTabId = state.tabDetails[nextTabIndex].id;
    dispatch({
      type: UPDATE_CURRENT_TAB,
      field: "currentTab",
      value: nextTabId,
    });
  };

  const handleNextStep = () => {
    const currentTabIndex = state.tabDetails.findIndex(
      (tab) => tab.id === state.currentTab
    );
    const nextTabIndex = (currentTabIndex + 1) % state.tabDetails.length;
    const nextTabId = state.tabDetails[nextTabIndex].id;
    if (
      state.currentTab === "companyLocation" &&
      nextTabId === "companyContactPerson"
    ) {
      const locationOptions: any = generateLocationOptions();
      dispatch({
        type: SET_COMPANY_OPTION,
        field: "locationList",
        value: locationOptions,
      });
    }
    dispatch({
      type: UPDATE_CURRENT_TAB,
      field: "currentTab",
      value: nextTabId,
    });
  };
  // Save as Draft validation
  const draftValidation = () => {
    const { vat, companyName, officialName, companyType } =
      state?.companyBasic?.data;

    const tabFields = state.companyBasic;
    const fieldName = "projectName";
    const tabIndex = 0;
    const tab = "companyBasic";
    // const fieldValue: any = tabFields.data[fieldName];
    // const validationError = validateRequired(fieldValue);

    const validationRules = {
      vat: [
        validateRequired,
        (value: string) => validateVatNumber(value),
        validateNoSpaces,
      ],
      companyName: [validateRequired],
      officialName: [validateRequired],
      companyType: [validateSelectField],
    };

    const validateValues = {
      vat,
      companyName,
      officialName,
      companyType,
    };

    const validationErrors = validateForm(validateValues, validationRules);

    if (Object.keys(validationErrors).length > 0) {
      dispatch({
        type: SET_ERRORS_COMPANY_BASIC_TAB,
        errors: { ...validationErrors } as unknown as BasicTabError,
      });

      dispatch({
        type: "UPDATE_TAB_ERROR",
        tabIndex: tabIndex,
        error: true,
        draft: true,
      });
      return false;
    }
    dispatch({
      type: "UPDATE_TAB_ERROR",
      tabIndex: tabIndex,
      error: false,
      draft: true,
    });

    return true;
  };
  const handleSubmit = async (e: React.FormEvent) => {
    const formHasErrors = companyFormHasErrors();
    if (formHasErrors) {
      return;
    }
    e.preventDefault();
    let finalCompanyLocation = null;
    let finalCompanyContact = null;
    if (state.companyLocationTable.length === 0) {
      finalCompanyLocation = [state.companyLocation.data];
    } else {
      const specificLocationFields: Array<keyof LocationTabData> = [
        "locationName",
        "locationPhoneNumber",
        "locationStreet",
        "locationNumber",
        "locationZipCode",
        "locationCountry",
        "locationCity",
      ];

      // Check if all specific fields are filled
      const areSpecificFieldsFilled = specificLocationFields.every((field) => {
        const value = state.companyLocation.data[field];

        // Check if value is a string and apply trim if needed
        if (typeof value === "string") {
          return value.trim() !== ""; // Check for non-empty strings after trimming
        }

        // Otherwise, check for non-null and non-undefined values (for other types)
        return value !== null && value !== undefined;
      });

      // Initialize finalCompanyContact with existing entries
      finalCompanyLocation = [...state.companyLocationTable];

      // Add to finalCompanyContact only if all of the specific fields are filled
      if (areSpecificFieldsFilled) {
        finalCompanyLocation.push(state.companyLocation.data);
      }
    }

    if (state.companyContactPersonTable.length === 0) {
      finalCompanyContact = [state.companyContactPerson.data];
    } else {
      const specificFields: Array<keyof ContactPersonTabData> = [
        "fName",
        "lName",
        "email",
        "mobileNumber",
      ];

      const areSpecificFieldsFilled = specificFields.every((field) => {
        const value = state.companyContactPerson.data[field];

        // Check if value is a string and apply trim if needed
        if (typeof value === "string") {
          return value.trim() !== ""; // Check for non-empty strings after trimming
        }

        // Otherwise, check for non-null and non-undefined values (for other types)
        return value !== null && value !== undefined;
      });

      // Initialize finalCompanyContact with existing entries
      finalCompanyContact = [...state.companyContactPersonTable];

      // Add to finalCompanyContact only if all of the specific fields are filled
      if (areSpecificFieldsFilled) {
        finalCompanyContact.push(state.companyContactPerson.data);
      }
    }
    const formData = {
      basic: state.companyBasic.data,
      address: state.companyAddress.data,
      companyLocation: finalCompanyLocation,
      contacts: finalCompanyContact,
      document: state.companyDocument,
      status: 0,
    };
    const response = await ApiCall.service(
      CREATE_COMPANY,
      "POST",
      formData,
      false,
      M_COMPANY_CREATION
    );
    if (response?.status === 422) {
      CustomNotify({
        type: "warning",
        message: response?.msg,
      });
    }
    if (response?.status === 200) {
      const msg =
        state.companyBasic.data.companyStatus === 1
          ? "Company updated successfully!"
          : "Company created successfully!";
      CustomNotify({
        type: "success",
        message: msg,
      });
      navigate("/manage-companies");
    } else if (response?.status === 400) {
      CustomNotify({ type: "error", message: response?.msg });
      // dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
      // scrollToTop();
      // state.fieldError.vat = response?.msg;
    }
  };
  const handleSavePerTab = async (e: React.FormEvent) => {
    const formHasErrors = companyFormHasErrors();
    if (formHasErrors) {
      return;
    }
    e.preventDefault();
    let finalCompanyLocation = null;
    let finalCompanyContact = null;
    if (state.companyLocationTable.length === 0) {
      finalCompanyLocation = [state.companyLocation.data];
    } else {
      const specificLocationFields: Array<keyof LocationTabData> = [
        "locationName",
        "locationPhoneNumber",
        "locationStreet",
        "locationNumber",
        "locationZipCode",
        "locationCountry",
        "locationCity",
      ];

      // Check if all specific fields are filled
      const areSpecificFieldsFilled = specificLocationFields.every((field) => {
        const value = state.companyLocation.data[field];

        // Check if value is a string and apply trim if needed
        if (typeof value === "string") {
          return value.trim() !== ""; // Check for non-empty strings after trimming
        }

        // Otherwise, check for non-null and non-undefined values (for other types)
        return value !== null && value !== undefined;
      });

      // Initialize finalCompanyContact with existing entries
      finalCompanyLocation = [...state.companyLocationTable];

      // Add to finalCompanyContact only if all of the specific fields are filled
      if (areSpecificFieldsFilled) {
        finalCompanyLocation.push(state.companyLocation.data);
      }
    }

    if (state.companyContactPersonTable.length === 0) {
      finalCompanyContact = [state.companyContactPerson.data];
    } else {
      const specificFields: Array<keyof ContactPersonTabData> = [
        "fName",
        "lName",
        "email",
        "mobileNumber",
      ];

      const areSpecificFieldsFilled = specificFields.every((field) => {
        const value = state.companyContactPerson.data[field];

        // Check if value is a string and apply trim if needed
        if (typeof value === "string") {
          return value.trim() !== ""; // Check for non-empty strings after trimming
        }

        // Otherwise, check for non-null and non-undefined values (for other types)
        return value !== null && value !== undefined;
      });

      // Initialize finalCompanyContact with existing entries
      finalCompanyContact = [...state.companyContactPersonTable];

      // Add to finalCompanyContact only if all of the specific fields are filled
      if (areSpecificFieldsFilled) {
        finalCompanyContact.push(state.companyContactPerson.data);
      }
    }
    const formData = {
      basic: state.companyBasic.data,
      address: state.companyAddress.data,
      companyLocation: finalCompanyLocation,
      contacts: finalCompanyContact,
      document: state.companyDocument,
      status: 0,
    };
    const response = await ApiCall.service(
      CREATE_COMPANY,
      "POST",
      formData,
      false,
      M_COMPANY_CREATION
    );
    if (response?.status === 422) {
      CustomNotify({
        type: "warning",
        message: response?.msg,
      });
    }
    if (response?.status === 200) {
      const msg =
        state.companyBasic.data.companyStatus === 1
          ? "Company updated successfully!"
          : "Company created successfully!";
      CustomNotify({
        type: "success",
        message: msg,
      });
      navigate("/manage-companies");
    } else if (response?.status === 400) {
      CustomNotify({ type: "error", message: response?.msg });
      // dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
      // scrollToTop();
      // state.fieldError.vat = response?.msg;
    }
  };
  const handleDraft = async (e: React.FormEvent) => {
    try {
      const formHasErrors = draftValidation();
      if (!formHasErrors) {
        return;
      }

      e.preventDefault();
      let finalCompanyLocation = null;
      let finalCompanyContact = null;
      if (state.companyLocationTable.length === 0) {
        finalCompanyLocation = [state.companyLocation.data];
      } else {
        const specificLocationFields: Array<keyof LocationTabData> = [
          "locationName",
          "locationStreet",
          "locationNumber",
          "locationZipCode",
          "locationCountry",
          "locationCity",
        ];

        // Check if all specific fields are filled
        const areSpecificFieldsFilled = specificLocationFields.every(
          (field) => {
            const value = state.companyLocation.data[field];

            // Check if value is a string and apply trim if needed
            if (typeof value === "string") {
              return value.trim() !== ""; // Check for non-empty strings after trimming
            }

            // Otherwise, check for non-null and non-undefined values (for other types)
            return value !== null && value !== undefined;
          }
        );

        // Initialize finalCompanyContact with existing entries
        finalCompanyLocation = [...state.companyLocationTable];

        // Add to finalCompanyContact only if all of the specific fields are filled
        if (areSpecificFieldsFilled) {
          finalCompanyLocation.push(state.companyLocation.data);
        }
      }

      if (state.companyContactPersonTable.length === 0) {
        finalCompanyContact = [state.companyContactPerson.data];
      } else {
        const specificFields: Array<keyof ContactPersonTabData> = [
          "fName",
          "lName",
          "email",
          "mobileNumber",
        ];

        const areSpecificFieldsFilled = specificFields.every((field) => {
          const value = state.companyContactPerson.data[field];

          // Check if value is a string and apply trim if needed
          if (typeof value === "string") {
            return value.trim() !== ""; // Check for non-empty strings after trimming
          }

          // Otherwise, check for non-null and non-undefined values (for other types)
          return value !== null && value !== undefined;
        });

        // Initialize finalCompanyContact with existing entries
        finalCompanyContact = [...state.companyContactPersonTable];

        // Add to finalCompanyContact only if all of the specific fields are filled
        if (areSpecificFieldsFilled) {
          finalCompanyContact.push(state.companyContactPerson.data);
        }
      }
      const formData = {
        basic: state.companyBasic.data,
        address: state.companyAddress.data,
        companyLocation: finalCompanyLocation,
        contacts: finalCompanyContact,
        document: state.companyDocument,
        status: 1,
      };
      const response = await ApiCall.service(
        CREATE_COMPANY,
        "POST",
        formData,
        false,
        M_COMPANY_CREATION
      );
      if (response?.status === 422) {
        CustomNotify({
          type: "warning",
          message: response?.msg,
        });
      }
      if (response?.status === 200) {
        CustomNotify({
          type: "success",
          message: t("Company drafted successfully"),
        });
        navigate("/manage-companies");
      } else if (response?.status === 400) {
        CustomNotify({ type: "error", message: response?.msg });
        // dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        // scrollToTop();
        // state.fieldError.vat = response?.msg;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const presentOperation = id ? "update" : "create";
  const currentObj: any = {
    permission: "Company",
  };
  currentObj[presentOperation] = true;
  const userData = useSelector(selectAuth);

  const { companyName, companyType, vat } = state.companyBasic.data;

  return (
    <div className="position-relative">
      <AccessControl
        requiredPermissions={[currentObj]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <div
          className="row header-sticky position-sticky"
          style={{ height: "auto" }}
        >
          <div className="col-12">
            <h1
              className="page-title mb-0 d-flex align-items-center"
              style={{ paddingBottom: "0.7vw" }}
            >
              {t(`${type} company`)}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              style={{
                backgroundColor: "#e8f8fb",
                padding: "1.5vw",
                borderRadius: "1vw",
                marginBottom: "2.5vw",
                marginTop: "0.5vw",
              }}
            >
              <h4 className="name-text projectName">
                <strong>{t("Company")} : </strong>
                {companyName && t(companyName)}
              </h4>
              <span className="marginRight1">
                <strong>{t("Company type")} </strong>
                {companyType && t(companyType.label)}
              </span>
              <span className="marginRight1">
                <strong>{t("Vat number")} : </strong>
                {vat && vat}
              </span>
            </div>
          </div>
        </div>

        <nav
          className={`projectTabs project-form company-step-tabs createFormTabs ${
            isSticky
              ? "createTabsSticky position-sticky"
              : "company-step-tabs-absolute position-absolute w-100"
          }`}
        >
          <div
            className="d-flex border-0 w-100 m-auto gap-3"
            id="nav-tab"
            role="tablist"
          >
            <div
              className={`d-flex border-0 w-75 m-auto align-items-center justify-content-center`}
              style={{ gap: "0 1vw" }}
            >
              {state.tabDetails?.map((item: any) => (
                <div className={`flex-1 text-center`} key={item.id}>
                  <div
                    className={`createStepBtn  cursor-pointer ${
                      state.currentTab === item.id ? "active" : "inactive"
                    } ${item.error === true ? "error" : ""}`}
                    onClick={() => handleTabSwitch(item.id)}
                  >
                    {t(item.title)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </nav>

        <>{ConstructTabs(state.currentTab)}</>
        <FormNavigationOrganism
          isFirstTab={state.currentTab === state.tabDetails[0].id}
          isSubmitTab={
            state.currentTab ===
            state.tabDetails[state.tabDetails.length - 1].id
          }
          draftButtonIsVisible={state.mode === FormMode.CREATE}
          formExitBackTo={PATH_PROJECTS_MANAGE}
          handlePreviousStep={handlePreviousStep}
          handleNextStep={handleNextStep}
          {...(type !== "View" && {
            handleSubmit: handleSubmit,
            ...(state?.companyBasic?.data?.companyStatus === 0 ||
            type === "Create" ||
            state?.companyBasic?.data?.companyStatus === 2
              ? { handleDraft: handleDraft }
              : { handleSavePerTab: handleSavePerTab }),
          })}
          isHideNext={
            state.currentTab ===
              state.tabDetails[state.tabDetails.length - 1].id && type == "View"
          }
        />
      </AccessControl>
    </div>
  );
};
export default CompanyForm;
