import { FormMode } from "components/common/CommonEnums";

export const initialState = {
  mode: FormMode.CREATE,
  tabDetails: [
    {
      id: "companyBasic",
      title: "Basic",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "companyAddress",
      title: "Address",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "companyLocation",
      title: "location",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "companyContactPerson",
      title: "Contact person",
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "companyDocument",
      title: "Document",
      showStatus: true,
      error: false,
      draft: false,
    },
  ],
  tabErrors: [],
  companyBasic: {
    data: {
      id:"",
      vat: "",
      companyName: "",
      officialName: "",
      pc: [],
      sector: [],
      domain: [],
      function: [],
      consultant: null,
      naceCodes: [],
      companyType: null,
      isBussinessUnit: false,
      bussinessUnit: null,
      website: "",
      hq: 0,
      parentCompanies: null,
      coEfficient: 0,
      companyStatus: 0,
      active: true,
    },
    errors: {
      vat: "",
      companyName: "",
      officialName: "",
      companyType: "",
      bussinessUnit: "",
    },
  },

  companyLocation: {
    data: {
      locationName: "",
      locationPhoneNumber: "",
      locationStreet: "",
      locationNumber: "",
      locationBox: "",
      locationZipCode: "",
      locationCountry: "",
      locationCity: "",
      initialLat: "",
      initialLn: "",
      id: "",
      locationId: "",
      locationInfo: "",
    },
    errors: {
      locationName: "",
      locationStreet: "",
      locationNumber: "",
      locationZipCode: "",
      locationCountry: "",
      locationCity: "",
    },
  },
  companyAddress: {
    data: {
      street: "",
      number: "",
      box: "",
      zipCode: "",
      country: null,
      city: "",
      biStreet: "",
      biNumber: "",
      biBox: "",
      biZipCode: "",
      biCountry: null,
      biCity: "",
      hqAddress: 0,
      sameAddress: 1,
      initialLat: "",
      initialLn: "",
      biInitialLat: "",
      biInitialLn: "",
    },
    errors: {
      street: "",
      number: "",
      zipCode: "",
      country: "",
      city: "",
      biStreet: "",
      biNumber: "",
      biZipCode: "",
      biCountry: "",
      biCity: "",
      hqAddress: "",
      sameAddress: "",
    },
  },

  companyContactPerson: {
    data: {
      tittle: null,
      fName: "",
      lName: "",
      email: "",
      company: null,
      mobNumber: "",
      language: null,
      functionData: "",
      linkedIn: "",
      roles: [],
      dob: "",
      id: "",
      location: [],
      businessUnit: [],
      info: "",
      userId:"",
    },
    errors: {
      fName: "",
      lName: "",
      email: "",
      mobileNumber: "",
    },
  },
  companyLocationTable: [],
  companyContactPersonTable: [],
  companyDocument: [],
  isViewMode:false,
  currentTab: "companyBasic",

  options: {
    countryList: [],
    pcList: [],
    sectorList: [],
    domainList: [],
    functionList: [],
    categoryList: [],
    consultantList: [],
    locationList: [],
    companiesList: [],
    rolesList: [],
    languageList: [],
    naceCodesList: [],
    bussinessUnitList: [],
    genders: [],
    tittle: [],
    documentCategoryList:[],
  },
};
