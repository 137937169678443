import { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { t } from "pages/microservices/masterData/translation/Translation";
import PhoneInput from "react-phone-number-input";
import Calender from "../../helpers/Calender";
import LabelField from "components/atoms/LabelField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import CheckBoxField from "components/atoms/CheckBoxField";
import { useFormContext } from "../../context/Context";
import { ADD_EMERGENCY_CONTACT_FIELDS, EMERGENCY_CONTACT_DETAILS, GENERAL, OTHERS, REMOVE_EMERGENCY_CONTACT_FIELDS, UPDATE_DOMICILE_ADDRESS_FIELDS, UPDATE_EMERGENCY_CONTACT_DETAILS, UPDATE_EMERGENCY_CONTACT_FIELD_ERROR, UPDATE_ERROR_FIELD, UPDATE_FIELD } from "../../annotations/CandidateConstants";
import { validateEmail, validateEmailCanBeNull, validateForm, validateNumber, validatePhoneNumber, validatePhoneNumberCanBeNull, validateRequired, validateSSN, validateSelectField, validateTextFieldAlpha, validateTextFieldAlphaCanBeNull, validateTextFiled } from "services/validation/ValidationService";
import { Option, ValidationRules } from "utils/TypeAnnotations";
import { Suggestion, YesOrNoOptions, genderOptions, resetSSNRelatedFields } from "../../helpers/CandidateHelperFunctions";
import Button from "components/atoms/Button";
import { TextAreaMolecule } from "components/molecules/TextAreaMolecule";
import Close from "static/images/Close";
import axios from "axios";
import Autocomplete from "components/molecules/AutoComplete";
import { Link, useLocation } from "react-router-dom";
import AddAddressLoactionViaMap, { AddressDetails } from "utils/AddAddressLoactionViaMap";
import ActiveLocation from "static/images/ActiveLocation";
import { SetAddressData } from "../form-data/SetAddressData";
import { ApiCall } from "services/ApiServices";
import { GET_CONSULTANT_BUSINESSUNIT } from "routes/ApiEndpoints";
import { M_COMPANY_CREATION } from "constants/Constants";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const GeneralTab: React.FC = () => {
    const userData = useSelector(selectAuth);
    const { state, dispatch } = useFormContext();
    const location = useLocation();
    const isView = location.pathname?.includes('view');

    const [validationStatus, setValidationStatus] = useState({
        isValid: false,
        type: "",
    });

    const validStatus = (validation: any) => {
        setValidationStatus({
            isValid: validation.isValid,
            type: validation.type,
        });
    };

    const validation = (
        name: string,
        value: any,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            ssn: [validateSSN],
            firstName: [validateRequired, validateTextFiled],
            lastName: [validateRequired, validateTextFiled],
            email: [validateEmail],
            phoneNumber: [validatePhoneNumber],
            mobileNumber: [validatePhoneNumberCanBeNull],
            businessUnit: [validateSelectField],
            countryOfBirth: [validateSelectField],
            street: [validateRequired, validateTextFieldAlpha],
            number: [validateRequired, validateTextFieldAlpha],
            zipCode: [validateRequired, validateNumber],
            city: [validateRequired, validateTextFiled],
            country: [validateSelectField],
            drivingLicense: [validateNumber]
        };
        const validationErrors = validateForm(
            { ...state.general, [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );

        dispatch({
            type: UPDATE_ERROR_FIELD,
            field: name,
            error: validationErrors[name]
        });
        const isFieldValid = Object.keys(validationErrors).length === 0;
        if (isFieldValid) {
            dispatch({
                type: UPDATE_ERROR_FIELD,
                field: name,
                error: ''
            });
        }
        if (Object.keys(validationErrors).length > 0) {
            return false;
        }
        return true;
    };
    const currentDate = new Date();
    const below15 = new Date();
    below15.setFullYear(currentDate.getFullYear() - 15);

    const getAge = (birthDate: any) => {
        if (!birthDate) {
            dispatch({
                type: UPDATE_FIELD,
                tab: GENERAL,
                field: 'age',
                value: '',
            });
            return null;
        }

        const birthYear = birthDate.getFullYear(); // Get birth year

        const currentDate = new Date(); // Get current date
        const currentYear = currentDate.getFullYear(); // Get current year

        const age = currentYear - birthYear; // Calculate and return the age
        dispatch({
            type: UPDATE_FIELD,
            tab: GENERAL,
            field: 'age',
            value: age,
        });
    };

    const getGenderById = (genderId: number) => {
        return genderOptions.find(option => option.value === genderId);
    }
    const getFormattedSSN = (value: string, tab: string) => {
        const numericValue = value.replace(/[^0-9]/g, "");
        let formattedSSN = "";
        let dob = "";

        if (numericValue.length <= 11) {
            for (let i = 0; i < numericValue.length; i++) {
                if (i === 2 || i === 4 || i === 9) {
                    formattedSSN += ".";
                } else if (i === 6) {
                    formattedSSN += "-";
                }
                formattedSSN += numericValue[i];
            }
            const validatioResult = validation("ssn", formattedSSN, true);
            if (
                validatioResult &&
                formattedSSN !== "" &&
                formattedSSN.length === 15
            ) {
                const birthdateString = formattedSSN
                    .substring(0, 8)
                    .replace(/\./g, ""); // Extract YYMMDD part and remove dots
                const year = birthdateString.substring(0, 2);
                let month = parseInt(birthdateString.substring(2, 4), 10);
                const day = parseInt(birthdateString.substring(4, 6));

                const century = parseInt(year) < 50 ? "20" : "19"; // Determine the century based on the year
                const fullYear = parseInt(century + year);

                if (month >= 1 && month <= 12 && day >= 1 && day <= 31) {
                    // Create a Date object with the extracted birthdate
                    // const formattedDate = format(dob, 'yyyy-MM-dd');
                    const dob = new Date(fullYear, month - 1, day);

                    dispatch({
                        type: UPDATE_FIELD,
                        tab: tab,
                        field: "dob",
                        value: dob
                    });
                    getAge(new Date(dob));
                    const genderId =
                        parseInt(formattedSSN.substring(9, 12)) % 2 === 0 ? 2 : 1;

                    const gender: Option | undefined = getGenderById(genderId);
                    if (gender) {
                        dispatch({
                            type: UPDATE_FIELD,
                            tab: tab,
                            field: 'gender',
                            value: gender // Assuming you want to use the value property of Gender
                        });
                    } else {
                        dispatch({
                            type: UPDATE_FIELD,
                            tab: tab,
                            field: 'gender',
                            value: null // Assuming you want to use the value property of Gender
                        });
                    }
                }
            } else {
                resetSSNRelatedFields(dispatch);

            }
        }
        return formattedSSN;
    }

    async function getBICForIBAN(iban: string, tab: string) {
        const url = `https://openiban.com/validate/${iban}?getBIC=true&validateBankCode=true`;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const ibanData = await response.json();
            if (ibanData.valid) {
                dispatch({
                    type: UPDATE_FIELD,
                    tab: tab,
                    field: 'bic',
                    value: ibanData.bankData.bic
                });
            }
        } catch (error) {
            console.error("Error:", error);
            return null;
        }
    }

    const emergencyContactValidation = (
        name: string,
        value: string | boolean | Date | object[] | undefined,
        index: number,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            emergencyContactName: [validateTextFieldAlphaCanBeNull],
            emergencyContactEmail: [validateEmailCanBeNull],
            emergencyContactPhoneNumber: [validatePhoneNumberCanBeNull],
        };
        const validationErrors = validateForm(
            { ...state.emergencyContactDetails[index], [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );
        dispatch({
            type: UPDATE_EMERGENCY_CONTACT_FIELD_ERROR,
            field: name,
            error: validationErrors[name],
            index,
        });
        const isFieldValid = Object.keys(validationErrors).length === 0;

        if (isFieldValid) {
            dispatch({
                type: UPDATE_EMERGENCY_CONTACT_FIELD_ERROR,
                field: name,
                error: "",
                index,
            });
        }

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }

        return true;
    };

    const handleEmergencyDataChange = (e: any, index: number) => {
        let { name, value } = e.target;
        dispatch({
            type: UPDATE_EMERGENCY_CONTACT_DETAILS,
            field: name,
            value: value,
            index: index
        });
        emergencyContactValidation(name, value, index, true);
    }

    const handleEmergencyPhoneNumberChange = (number: any, fieldName: string, index: number) => {
        dispatch({
            type: UPDATE_EMERGENCY_CONTACT_DETAILS,
            field: fieldName,
            value: number,
            index: index
        });
        emergencyContactValidation(fieldName, number, index, true);
    }
    const handleFieldChange = (e: any, tab = GENERAL) => {
        let { name, value, type, checked } = e.target;

        let updatedValue = value;

        if (type === 'checkbox') {
            updatedValue = checked ? 1 : 0;
            dispatch({
                type: UPDATE_DOMICILE_ADDRESS_FIELDS,
                checked: updatedValue
            });
        } else if (name === "ssn") {
            updatedValue = getFormattedSSN(value, tab);
        } else if (name == "iban") {
            getBICForIBAN(value, tab);
        } else if (name === "zipCode") {
            const city = getCityAndCountry(value, tab);
            city.then((response: any) => {
                const { city, country } = response
                dispatch({
                    type: UPDATE_FIELD,
                    tab: tab,
                    field: 'city', // Convert to domFieldName
                    value: city !== 'Error' ? city : ''
                });
                const countryValue: any = country !== 'Error' ? state.dropDownData.countriesList.find((coun: any) => coun?.label?.toLowerCase() === country?.toLowerCase()) : null;
                dispatch({
                    type: UPDATE_FIELD,
                    tab: tab,
                    field: 'country', // Convert to domFieldName
                    value: countryValue
                });
            });
        } else if (name === "domZipCode") {
            const domCity = getCityAndCountry(value, tab);
            domCity.then((response: any) => {
                const { city, country } = response
                dispatch({
                    type: UPDATE_FIELD,
                    tab: tab,
                    field: 'domCity', // Convert to domFieldName
                    value: city !== 'Error' ? city : ''
                });
                const countryValue: any = country !== 'Error' ? state.dropDownData.countriesList.find((coun: any) => coun?.label?.toLowerCase() === country?.toLowerCase()) : null;
                dispatch({
                    type: UPDATE_FIELD,
                    tab: tab,
                    field: 'domCountry', // Convert to domFieldName
                    value: countryValue
                });
            });
        } else if (name === "dependantChildren") {
            updatedValue = Number(updatedValue.replace(/[^0-9,]/g, ''));
        } else if (name === "drivingLicense") {
            updatedValue = updatedValue.replace(/[^0-9,]/g, '');
        } else if (name === "partnerName") {
            const emergencyListIndex = state.emergencyContactDetails && state.emergencyContactDetails?.length - 1;
            dispatch({
                type: UPDATE_EMERGENCY_CONTACT_DETAILS,
                field: "emergencyContactName",
                value: value,
                index: emergencyListIndex
            });
            emergencyContactValidation(name, value, emergencyListIndex, true);
        }


        // Define the fields to synchronize with dom* versions
        const fieldsToSync = ['street', 'number', 'box', 'zipCode', 'city'];

        // Check if the current name is in the fields to sync and sameAsAddress is 1
        if (fieldsToSync.includes(name)) {
            if (state.general.sameAsResidence === 1) {
                dispatch({
                    type: UPDATE_FIELD,
                    tab: tab,
                    field: `dom${name.charAt(0).toUpperCase() + name.slice(1)}`, // Convert to domFieldName
                    value: updatedValue
                });
            }
        }
        // Dispatch the regular field update
        dispatch({
            type: UPDATE_FIELD,
            tab: tab,
            field: name,
            value: updatedValue
        });
        validation(name, value, true);
    }


    const handlePhoneNumberChange = (number: any, fieldName: string, tab = GENERAL) => {
        dispatch({
            type: UPDATE_FIELD,
            tab: tab,
            field: fieldName,
            value: number,
        });
        validation(fieldName, number, false)

    }

    const handleDateChange = (date: Date | null, fieldName: string, tab = "general") => {
        dispatch({
            type: UPDATE_FIELD,
            tab: tab,
            field: fieldName,
            value: date,
        });
        getAge(date);
        validation(fieldName, date, false)

    }

    const handleSelectChange = (selectedOption: any, fieldName: string, tab = "general") => {
        dispatch({
            type: UPDATE_FIELD,
            tab: tab,
            field: fieldName,
            value: selectedOption,
        });
        if (fieldName === "country" && state.general.sameAsResidence === 1) {
            dispatch({
                type: UPDATE_FIELD,
                tab: tab,
                field: 'domCountry',
                value: selectedOption,
            });
        } else if (fieldName === "consultant") {
            if (selectedOption.value) {
                getConsultantBusinessUnit(selectedOption.value);
            } else {
                dispatch({
                    type: UPDATE_FIELD,
                    tab: GENERAL,
                    field: 'businessUnit',
                    value: userData.businessUnit,
                });
            }
        }
        validation(fieldName, selectedOption, false);
    }

    const getConsultantBusinessUnit = async (consultantId: any) => {
        const response = await ApiCall.service(
            GET_CONSULTANT_BUSINESSUNIT,
            "POST",
            { consultant: consultantId },
            false,
            M_COMPANY_CREATION
        );
        if (response?.status === 200) {
            dispatch({
                type: UPDATE_FIELD,
                tab: GENERAL,
                field: 'businessUnit',
                value: response?.data,
            });
        }
    };

    const handleAdd = () => {
        dispatch({ type: ADD_EMERGENCY_CONTACT_FIELDS });
    }

    const handleRemove = (index: number) => {
        dispatch({
            type: REMOVE_EMERGENCY_CONTACT_FIELDS,
            indexToRemove: index
        });
    }

    const getCityAndCountry = async (zipcode: string, tab: string) => {
        try {
            if (zipcode.length === 4) {
                const response = await axios.get(`https://api.zippopotam.us/be/${zipcode}`);
                // Check if the response contains places data and is in the expected format
                if (response.data && response.data.places && response.data.places.length > 0) {
                    return { city: response.data.places?.[0]?.['place name'] || 'Not found', country: response.data?.country || 'Belgium' };
                } else {
                    return { city: 'Not found', country: 'Belgium' };
                }
            }
            else {
                return { city: '', country: '' };
            }
        } catch (error) {
            return { city: 'Error', country: 'Error' };
        }
    };

    const list = state.dropDownData.bankNameList.map(
        (item) => ({
            ...item,
            label: item.label || "", // Ensure label is always a string
            value: item.value?.toString() || "", // Convert value to string
        })
    );

    const [filteredSuggestions, setFilteredSuggestions] = useState(list);

    const handleSuggestChange = (inputValue: string) => {
        dispatch({
            type: UPDATE_FIELD,
            tab: GENERAL,
            field: 'bankName',
            value: inputValue,
        });
        dispatch({
            type: UPDATE_FIELD,
            tab: GENERAL,
            field: 'bankId',
            value: '',
        });

        // Filter suggestions based on inputValue
        const filteredList = list.filter((item) =>
            item.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredSuggestions(filteredList);
    };

    const handleSuggestionSelected = (selectedSuggestion: Suggestion) => {
        dispatch({
            type: UPDATE_FIELD,
            tab: GENERAL,
            field: 'bankName',
            value: selectedSuggestion.label,
        });

        dispatch({
            type: UPDATE_FIELD,
            tab: GENERAL,
            field: "bankId",
            value: selectedSuggestion.value,
        });
    };


    ////////////// ******* Google maps ******************//
    const [showPopup, setShowPopup] = useState(false);

    const handleConfirm = (details: AddressDetails | null) => {
        if (details) {
            SetAddressData(details, dispatch, state);
        }
        setShowPopup(false); // Close the popup after confirming the address
    };

    const handleAddPopup = () => {
        setShowPopup(true); // Close the popup without saving
    };
    const handleClosePopup = () => {
        setShowPopup(false); // or whatever logic you have to close the popup
    };
    //***************** Google maps ************ */

    return <>
        <div className="row">
            <div className="col-12">
                <div className="form-border" style={{ paddingTop: "3vw" }}>
                    <div className="row">
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={false}
                                name="ssn"
                                handleChange={(e) => handleFieldChange(e)}
                                value={state.general.ssn}
                                id="ssn"
                                label={t("SSN number")}
                                type="text"
                                error={state.errors.ssn}
                                placeholder="XX.XX.XX-XXX.XX"
                            />
                        </div>
                        <div className="col">
                            <SelectWithSearch
                                title={t("Contact preference")}
                                isMandatory={false}
                                search={true}
                                options={state.dropDownData.contactPreferenceList}
                                onChange={(e) => handleSelectChange(e, "contactPreference")}
                                isMulti={true}
                                name="contactPreference"
                                value={state.general.contactPreference}
                                placeHolder="Select"
                            />
                        </div>
                        <div className="col">
                            <SelectWithSearch
                                title={t("Selection group")}
                                isMandatory={false}
                                search={true}
                                options={state.dropDownData.selectionGroupList}
                                onChange={(e) => handleSelectChange(e, "selectionGroup")}
                                isMulti={true}
                                name="selectionGroup"
                                value={state.general.selectionGroup}
                                placeHolder="Select"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={true}
                                name="firstName"
                                handleChange={(e) => handleFieldChange(e)}
                                id="firstName"
                                label={t("First name")}
                                type="text"
                                value={state.general.firstName}
                                error={state.errors.firstName}
                            />
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={true}
                                name="lastName"
                                handleChange={(e) => handleFieldChange(e)}
                                id="lastName"
                                label={t("Last name")}
                                type="text"
                                value={state.general.lastName}
                                error={state.errors.lastName}
                            />
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={false}
                                name="shortCode"
                                handleChange={(e) => handleFieldChange(e)}
                                id="shortCode"
                                label={t("Short code")}
                                type="text"
                                value={(state.general.firstName?.slice(0, 3) + state.general.lastName.slice(0, 3))?.toUpperCase()}
                                error={state.errors.shortCode}
                            />
                        </div>
                        <div className="col">
                            <LabelField
                                title={t("Phone number")}
                                isMandatory={true}
                                key="PhoneInput"
                            />
                            <div className="form-control field-shadow">
                                <PhoneInput
                                    name='phoneNumber'
                                    label={t('Phone number')}
                                    required={true}
                                    defaultCountry="BE"
                                    initialValueFormat="national"
                                    placeholder={t("Enter phone number")}
                                    onChange={(mobileNumber) =>
                                        handlePhoneNumberChange(mobileNumber, "phoneNumber")}
                                    value={state.general.phoneNumber}
                                />
                            </div>
                            <div>
                                {state.errors.phoneNumber && <span className="text-danger">{state.errors.phoneNumber}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <LabelField
                                title={t("Mobile number")}
                                isMandatory={false}
                                key="MobileInput"
                            />
                            <div className="form-control field-shadow">
                                <PhoneInput
                                    name='mobileNumber'
                                    label={t('Mobile number')}
                                    required={false}
                                    defaultCountry="BE"
                                    initialValueFormat="national"
                                    placeholder={t("Enter mobile number")}
                                    onChange={(mobileNumber) =>
                                        handlePhoneNumberChange(mobileNumber, "mobileNumber")}
                                    value={state.general.mobileNumber}
                                />
                            </div>
                            <div>
                                {state.errors.mobileNumber && <span className="text-danger">{state.errors.mobileNumber}</span>}
                            </div>
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={true}
                                name="email"
                                handleChange={(e) => handleFieldChange(e)}
                                id="email"
                                label={t("Email address")}
                                type="email"
                                value={state.general.email}
                                error={state.errors.email}
                            />
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <Calender
                                        placeHolder="dd-mm-yyyy"
                                        onChange={(date) => handleDateChange(date, "dob")}
                                        label={t("Date of birth")}
                                        isMandatory={false}
                                        name='dob'
                                        selectedDate={state.general.dob}
                                        maxDate={below15}
                                    ></Calender>
                                </div>
                                <div className="col">
                                    <LabelWithInputField
                                        isMandatory={false}
                                        name="age"
                                        handleChange={(e) => handleFieldChange(e)}
                                        id="age"
                                        label={t("Age")}
                                        type="text"
                                        value={state.general.age}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <SelectWithSearch
                                title={t("Gender")}
                                isMandatory={false}
                                search={true}
                                options={genderOptions}
                                onChange={(e) => handleSelectChange(e, "gender")}
                                isMulti={false}
                                name="gender"
                                value={state.general.gender}
                                placeHolder="Select"
                            />
                        </div>
                    </div >
                    <div className="row">
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={false}
                                name="placeOfBirth"
                                handleChange={(e) => handleFieldChange(e)}
                                id="placeOfBirth"
                                label={t("Place of birth")}
                                type="text"
                                value={state.general.placeOfBirth}
                            />
                        </div>
                        <div className="col">
                            <SelectWithSearch
                                title={t("Country of birth")}
                                isMandatory={true}
                                search={true}
                                options={state.dropDownData.countriesList}
                                onChange={(e) => handleSelectChange(e, "countryOfBirth")}
                                isMulti={false}
                                name="countryOfBirth"
                                value={state.general.countryOfBirth}
                                error={state.errors.countryOfBirth}
                                placeHolder="Select"
                            />
                        </div>
                        <div className="col">
                            <SelectWithSearch
                                title={t("Nationality")}
                                isMandatory={false}
                                search={true}
                                options={state.dropDownData.countriesList}
                                onChange={(e) => handleSelectChange(e, "nationality")}
                                isMulti={false}
                                name="nationality"
                                value={state.general.nationality}
                                placeHolder="Select"
                            />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-4">
                            <SelectWithSearch
                                title={t("Consultant")}
                                isMandatory={false}
                                search={true}
                                options={state.dropDownData.usersList}
                                onChange={(e) => handleSelectChange(e, "consultant")}
                                isMulti={false}
                                name=" consultant"
                                value={state.general.consultant}
                                placeHolder="Select"
                            />
                        </div>
                        <div className="col-4">
                            <SelectWithSearch
                                title={t("Business unit")}
                                isMandatory={true}
                                search={true}
                                options={state.dropDownData.businessUnitList}
                                onChange={(e) => handleSelectChange(e, "businessUnit")}
                                isMulti={false}
                                name=" businessUnit"
                                value={state.general.businessUnit}
                                error={state.errors.businessUnit}
                                placeHolder="Select"
                            />
                        </div>
                        <div className="col">
                            <SelectWithSearch
                                title={t("Contract type")}
                                isMandatory={false}
                                search={true}
                                options={state.dropDownData.contractTypeList}
                                onChange={(e) => handleSelectChange(e, "contractType")}
                                isMulti={false}
                                name=" contractType"
                                value={state.general.contractType}
                                placeHolder="Select"
                            />
                        </div>
                        {state.general.contractType && state.general.contractType?.label?.toLowerCase() === 'freelancer' &&
                            <>
                                <div className="col">
                                    <LabelWithInputField
                                        isMandatory={false}
                                        name="vat"
                                        handleChange={(e) => handleFieldChange(e)}
                                        id="vat"
                                        label={t("VAT number")}
                                        type="text"
                                        value={state.general.vat}
                                    />
                                </div>
                                <div className="col">
                                    <LabelWithInputField
                                        isMandatory={false}
                                        name="companyName"
                                        handleChange={(e) => handleFieldChange(e)}
                                        id="companyName"
                                        label={t("company name")}
                                        type="text"
                                        value={state.general.companyName}
                                    />
                                </div></>
                        }
                        {state.general.contractType && state.general.contractType?.label?.toLowerCase() === 'temporary' &&
                            <>
                                <div className="col">
                                    <LabelWithInputField
                                        isMandatory={false}
                                        name="tempAgency"
                                        handleChange={(e) => handleFieldChange(e)}
                                        id="cotempAgencympanyName"
                                        label={t("Temp agency")}
                                        type="text"
                                        value={state.general.tempAgency}
                                    />
                                </div>
                            </>
                        }

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <LabelField title={t("Payment info")} className="tab-subtitle" />
                        </div>
                        {/* <div className="col">
                    <SelectWithSearch
                        title={t("IBAN-country")}
                        isMandatory={false}
                        search={true}
                        options={state.dropDownData.countriesList}
                        onChange={(e) => handleSelectChange(e, "ibanCountry")}
                        isMulti={false}
                        name="ibanCountry"
                        value={state.general.ibanCountry}
                        error={""}
                        placeHolder="Select"
                    />
                </div> */}
                        <div className="col">
                            <Autocomplete
                                value={state.general.bankName} // Access the correct field value
                                label="Bank name"
                                suggestions={filteredSuggestions} // Pass suggestions for the specific index
                                onSuggestionSelected={(selectedSuggestion) =>
                                    handleSuggestionSelected(selectedSuggestion)
                                }
                                onInputValueChange={(inputValue) =>
                                    handleSuggestChange(inputValue)
                                }
                                className="undefined color-dark-pink"
                                isMandatory={false}
                            />
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={false}
                                name="iban"
                                handleChange={(e) => handleFieldChange(e)}
                                id="iban"
                                label={t("IBAN")}
                                type="text"
                                value={state.general.iban}
                                error={state.errors.iban}
                            />
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={false}
                                name="bic"
                                handleChange={(e) => handleFieldChange(e)}
                                id="bic"
                                label={t("BIC code")}
                                type="text"
                                value={state.general.bic}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <LabelField
                                title={t("Residence address")}
                                className="tab-subtitle pb-0"
                            />
                            {!isView &&
                                <>
                                    <div style={{ marginLeft: "1vw" }}>
                                        <div className="d-flex align-items-center">
                                            <Link
                                                to={""}
                                                title={t("Search address via google map...")}
                                                onClick={handleAddPopup}
                                                className="color-dark-pink marginRightPoint5"
                                            >
                                                <ActiveLocation />
                                            </Link>
                                            <Link
                                                to={""}
                                                className="link-color"
                                                onClick={handleAddPopup}
                                            >
                                                {t("Search address via google map...")}
                                            </Link>
                                        </div>
                                        <AddAddressLoactionViaMap
                                            initialLat={
                                                state.general.initialLat === ""
                                                    ? 50.8503
                                                    : parseFloat(state.general.initialLat)
                                            } // Replace with your initial latitude
                                            initialLng={
                                                state.general.initialLn === ""
                                                    ? 4.3517
                                                    : parseFloat(state.general.initialLn)
                                            } // Replace with your initial longitude
                                            initialAddress={{
                                                street: state.general.street,
                                                nr: state.general.number,
                                                bus: state.general.box,
                                                postal: state.general.zipCode,
                                                city: state.general.city,
                                                country: state.general.country?.label || "",
                                            }}
                                            onConfirm={handleConfirm}
                                            showAddPopup={showPopup}
                                            onHide={handleClosePopup} // Pass the onHide function from the parent
                                        />
                                    </div>
                                </>}
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={true}
                                name="street"
                                handleChange={(e) => handleFieldChange(e)}
                                id="street"
                                label={t("Street")}
                                type="text"
                                value={state.general.street}
                                error={state.errors.street}
                            />
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={true}
                                name="number"
                                handleChange={(e) => handleFieldChange(e)}
                                id="number"
                                label={t("Number")}
                                type="text"
                                value={state.general.number}
                                error={state.errors.number}
                            />
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={false}
                                name="box"
                                handleChange={(e) => handleFieldChange(e)}
                                id="box"
                                label={t("Box")}
                                type="text"
                                value={state.general.box}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={true}
                                name="zipCode"
                                handleChange={(e) => handleFieldChange(e)}
                                id="zipCode"
                                label={t("Zip code")}
                                type="text"
                                value={state.general.zipCode}
                                error={state.errors.zipCode}
                            />
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={true}
                                name="city"
                                handleChange={(e) => handleFieldChange(e)}
                                id="city"
                                label={t("City")}
                                type="text"
                                value={state.general.city}
                                error={state.errors.city}
                            />
                        </div>
                        <div className="col">
                            <SelectWithSearch
                                title={t("Country")}
                                isMandatory={true}
                                search={true}
                                options={state.dropDownData.countriesList}
                                onChange={(e) => handleSelectChange(e, "country")}
                                isMulti={false}
                                name="country"
                                value={state.general.country}
                                error={state.errors.country}
                                placeHolder="Select"
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <CheckBoxField
                                label={t("Same as residence address")}
                                name="sameAsResidence"
                                onChangeHandler={(e) => handleFieldChange(e)}
                                isChecked={state.general.sameAsResidence === 1}
                                className="document-checkbox"
                                id="address"
                                lineHeight="1.7vw"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <LabelField title={t("Domicile")} className="tab-subtitle" />
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={false}
                                name="domStreet"
                                handleChange={(e) => handleFieldChange(e)}
                                id="domStreet"
                                label={t("Street")}
                                type="text"
                                value={state.general.domStreet}
                            />
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={false}
                                name="domNumber"
                                handleChange={(e) => handleFieldChange(e)}
                                id="domNumber"
                                label={t("Number")}
                                type="text"
                                value={state.general.domNumber}
                            />
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={false}
                                name="domBox"
                                handleChange={(e) => handleFieldChange(e)}
                                id="domBox"
                                label={t("Box")}
                                type="text"
                                value={state.general.domBox}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={false}
                                name="domZipCode"
                                handleChange={(e) => handleFieldChange(e)}
                                id="domZipCode"
                                label={t("Zip code")}
                                type="text"
                                value={state.general.domZipCode}
                            />
                        </div>
                        <div className="col">
                            <LabelWithInputField
                                isMandatory={false}
                                name="domCity"
                                handleChange={(e) => handleFieldChange(e)}
                                id="city"
                                label={t("City")}
                                type="text"
                                value={state.general.domCity}
                            />
                        </div>
                        <div className="col">
                            <SelectWithSearch
                                title={t("Country")}
                                isMandatory={false}
                                search={true}
                                options={state.dropDownData.countriesList}
                                onChange={(e) => handleSelectChange(e, "domCountry")}
                                isMulti={false}
                                name="domCountry"
                                value={state.general.domCountry}
                                placeHolder="Select"
                            />
                        </div>
                    </div>
                    <div id="others">
                        <div className="row">
                            <div className="col-12">
                                <LabelField title={t("Personal details")} className="tab-subtitle" />
                            </div>
                            <div className="col">
                                <SelectWithSearch
                                    title={t("Civil status")}
                                    isMandatory={false}
                                    search={true}
                                    options={state.dropDownData.civilStatusList}
                                    onChange={(e) => handleSelectChange(e, "civilStatus", OTHERS)}
                                    isMulti={false}
                                    name="civilStatus"
                                    value={state.others.civilStatus}
                                    placeHolder="Select"
                                />
                            </div>
                            <div className="col">
                                <Calender
                                    onChange={(date) => handleDateChange(date, "civilStateDate", OTHERS)}
                                    label={t('Civil state date')}
                                    isMandatory={false}
                                    name='civilStateDate'
                                    selectedDate={state.others.civilStateDate}
                                    maxDate={new Date()}
                                // minDate={new Date()} //enable dates from current date
                                ></Calender>
                            </div>
                            <div className="col">
                                <Calender
                                    onChange={(date) => handleDateChange(date, "partnerDateOfBirth", OTHERS)}
                                    label={t('Partner date of birth')}
                                    isMandatory={false}
                                    name='partnerDateOfBirth'
                                    selectedDate={state.others.partnerDateOfBirth}
                                    maxDate={new Date()}
                                // minDate={new Date()} //enable dates from current date
                                ></Calender>
                            </div>
                            <div className="col">
                                <LabelWithInputField
                                    isMandatory={false}
                                    name="partnerName"
                                    handleChange={(e) => handleFieldChange(e, OTHERS)}
                                    id="partnerName"
                                    label={t("Partner name")}
                                    type="text"
                                    value={state.others.partnerName}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <SelectWithSearch
                                    title={t("Dependant spouse")}
                                    isMandatory={false}
                                    search={true}
                                    options={YesOrNoOptions}
                                    onChange={(e) => handleSelectChange(e, "dependantSpouse", OTHERS)}
                                    isMulti={false}
                                    name="dependantSpouse"
                                    value={state.others.dependantSpouse}
                                    placeHolder="Select"
                                />
                            </div>
                            <div className="col">
                                <LabelWithInputField
                                    isMandatory={false}
                                    name="dependantChildren"
                                    handleChange={(e) => handleFieldChange(e, OTHERS)}
                                    id="dependantChildren"
                                    label="Dependant children"
                                    type="number"
                                    value={state.others.dependantChildren}
                                    min={0}
                                    step={1}
                                />
                            </div>
                            <div className="col">
                                <SelectWithSearch
                                    title={t("Language of documents")}
                                    isMandatory={false}
                                    search={true}
                                    options={state.dropDownData.languagesList}
                                    onChange={(e) => handleSelectChange(e, "languageOfDocuments", OTHERS)}
                                    isMulti={false}
                                    name="languageOfDocuments"
                                    value={state.others.languageOfDocuments}
                                    placeHolder="Select"
                                />
                            </div>
                            <div className="col">
                                <LabelWithInputField
                                    isMandatory={false}
                                    name="drivingLicense"
                                    handleChange={(e) => handleFieldChange(e, OTHERS)}
                                    id="drivingLicense"
                                    label={t("Driving license number")}
                                    type="text"
                                    value={state.others.drivingLicense}
                                    error={state.errors.drivingLicense}
                                />
                            </div>
                            <div className="col">
                                <SelectWithSearch
                                    title={t("Transport")}
                                    isMandatory={false}
                                    search={true}
                                    options={state.dropDownData.transportList}
                                    onChange={(e) => handleSelectChange(e, "transport", OTHERS)}
                                    isMulti={false}
                                    name="transport"
                                    value={state.others.transport}
                                    placeHolder="Select"
                                />
                            </div>
                            <div className="col-12">
                                <LabelField title={t("Emergency contact details")} className="tab-subtitle" />
                            </div>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-12">
                            <div className="internalContentWrapper" >
                                {state.emergencyContactDetails && state.emergencyContactDetails.length > 0 && state.emergencyContactDetails.map((contactDetails: any, index) => (
                                    <div className="row" key={index} >
                                        {index != 0 && <div className="text-end " onClick={() => handleRemove(index)}>
                                            <span className="table-action-btn cursor-pointer" title={t("Close")}>
                                                <Close />
                                            </span></div>}
                                        <div className="col">
                                            <LabelWithInputField
                                                isMandatory={false}
                                                name="emergencyContactName"
                                                handleChange={(e) => handleEmergencyDataChange(e, index)}
                                                id="emergencyContactName"
                                                label={t("Name")}
                                                type="text"
                                                value={contactDetails.emergencyContactName}
                                                error={state.emergencyContactErrors[index]?.emergencyContactName}
                                            />
                                        </div>
                                        <div className="col">
                                            <LabelWithInputField
                                                isMandatory={false}
                                                name="emergencyContactEmail"
                                                handleChange={(e) => handleEmergencyDataChange(e, index)}
                                                id="emergencyContactEmail"
                                                label={t("Email")}
                                                type="email"
                                                value={contactDetails.emergencyContactEmail}
                                                error={state.emergencyContactErrors[index]?.emergencyContactEmail}
                                            />
                                        </div>
                                        <div className="col">
                                            <LabelField
                                                title={t("Phone number")}
                                                isMandatory={false}
                                                key="PhoneInput"
                                            />
                                            <div className="form-control field-shadow">
                                                <PhoneInput
                                                    defaultCountry="BE"
                                                    international
                                                    placeholder="Enter phone number"
                                                    onChange={(mobileNumber) =>
                                                        handleEmergencyPhoneNumberChange(mobileNumber, "emergencyContactPhoneNumber", index)
                                                    }
                                                    value={contactDetails.emergencyContactPhoneNumber}
                                                // error={state.emergencyContactErrors[index].emergencyContactPhoneNumber}
                                                />
                                            </div>
                                            <div>
                                                {state.emergencyContactErrors[index]?.emergencyContactPhoneNumber &&
                                                    <span className="text-danger">{state.emergencyContactErrors[index]?.emergencyContactPhoneNumber}</span>}
                                            </div>
                                        </div>

                                    </div>

                                ))
                                }
                                <div className="row">
                                    <div className="col-12">
                                        <Button handleClick={() => { handleAdd() }} className="form-button float-end" title={t("+ Add another")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <TextAreaMolecule
                                label={t('Additional')}
                                name='additional'
                                id='additional'
                                placeholder={"Additional info"}
                                handleChange={(e) => handleFieldChange(e, OTHERS)}
                                isMandatory={false}
                                value={state.others.additional}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col">
                            Any form of discrimination based on sex, perceived race, colour, descent, nationality or ethnic origin, sexual orientation, marital status, birth, property, age, religious or philosophical beliefs, current or future state of health, disability or physical characteristic is prohibited.
                        </div>
                    </div>
                </div >
            </div>
        </div >


        <div style={{ padding: "1vw 0" }}>
            <Navigation validStatus={validStatus} />
        </div>

    </>
}
export default GeneralTab;

