import { useState } from "react";
import { useFormContext } from "../../context/Context";
import { JOB_DESCRIPTION, UPDATE_FIELD_ERROR, UPDATE_FIELDS } from "../../annotation/VacancyConstants";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import FileUpload from "components/atoms/FileUpload";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import Navigation from "../form-navigation/Navigation";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const VacancyJobDescriptionDetails = () => {
  const { state, dispatch } = useFormContext();
  const userAuth = useSelector(selectAuth);
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: ""
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type
    });
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch({ type: UPDATE_FIELDS, template: JOB_DESCRIPTION, field: name, value: value });
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    dispatch({ type: UPDATE_FIELDS, template: JOB_DESCRIPTION, field: name, value: content });
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: UPDATE_FIELDS, template: JOB_DESCRIPTION, field: name,
      value: Array.isArray(selectedOption) ? selectedOption?.map((item: any) => item?.value) : selectedOption?.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  };

  const handleFileChange = async (event: any, field: any) => {
    let fileData: any = null;
    if (event !== null) {
      fileData = await handleSingleFileUpload(event, "vacancy/document");
      const fileName = event?.name;
      const index = fileName?.lastIndexOf(".");
      const extension = index !== -1 ? fileName?.slice(index + 1) : "";
      fileData = {
        ...fileData,
        fileExtension: extension,
      }
    }
    dispatch({ type: UPDATE_FIELDS, template: JOB_DESCRIPTION, field, value: fileData ?? null, });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "3vw" }}>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <SelectWithSearch
                  title={t("Reason for closure")}
                  name="reasonForClosure"
                  placeHolder={t("Select")}
                  search={true}
                  options={state.options.ReasonForClosure}
                  value={state.jobDescription.reasonForClosure}
                  onChange={(e) =>
                    handleSelectChange(e, "reasonForClosure")}
                  isMulti={false}
                  className="select-field"
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <LabelWithInputField
                  isMandatory={false}
                  name="extraInfo"
                  handleChange={handleFieldChange}
                  value={state.jobDescription.extraInfo}
                  id="extraInfo"
                  label={t("Extra info")}
                  type="text"
                />
              </div>
              <div className="col-4 fileUploadFullWidth">
                <MaxiconFileUpload
                  label={t("File upload")}
                  name={t("fileUpload")}
                  id={t("fileUpload")}
                  fileObj={JSON.stringify(state?.jobDescription?.file)}
                  formats={"value.formats"}
                  handleChange={(e: any) => handleFileChange(e, "file")}
                  maxFileSize={10000}
                />
              </div>
              <div className="col-md-12">
                <LabelWithCKEditorField
                  label={t("Info")}
                  name="info"
                  value={state.jobDescription.info}
                  placeholder={t("Type here") + "..."}
                  handleChange={(event, editor) =>
                    handleCKEditorChange(event, editor, "info")}
                // className="field-shadow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} />
    </form>
  );
};

export default VacancyJobDescriptionDetails;