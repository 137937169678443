import CustomNotify from "components/atoms/CustomNotify";
import { M_COMPANY_CREATION } from "constants/Constants";
import { GET_CONSULTANT_BUSINESSUNIT } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";

export const checkUniqueness = (data: any, fieldName: string, dataList: any) => {
    const result = dataList.find((eachItem: any) => {
        return (eachItem?.[fieldName] === data?.[fieldName])
    });
    return result;
}

export const apiObject: any = {
    consultant: {
        method: 'POST',
        endPoint: GET_CONSULTANT_BUSINESSUNIT,
        load: false,
        microservice: M_COMPANY_CREATION,
    }
}

export const getOptions = async (data: any, field: string) => {
    try {
        const apiField: any = apiObject[field];
        const response = await ApiCall.service(
            apiField?.endPoint,
            apiField?.method,
            { [field]: data?.value },
            apiField?.load,
            apiField?.microservice
        );
        if (response?.status == 200) {
            return response;
        }
        else {
            CustomNotify({ type: "error", message: response?.message });
        }
    }
    catch (error) {
        console.log(error);
    }
}