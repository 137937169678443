import Button from "components/atoms/Button";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelField from "components/atoms/LabelField";
import PhoneInput from "react-phone-number-input";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { useEffect, useState } from "react";
import { ValidationRules } from "utils/TypeAnnotations";
import {
    validateForm,
    validateRequired,
    validateTextFieldAlpha,
    validateEmail,
    validatePhoneNumber,
    validateSelectField,
    scrollToTop,
} from "services/validation/ValidationService";
import { ApiCall } from "services/ApiServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as END_POINTS from "routes/ApiEndpoints";
import CustomNotify from "components/atoms/CustomNotify";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { M_COMPANY_CREATION } from "constants/Constants";
import { E164Number } from "libphonenumber-js";

import { t } from "pages/microservices/masterData/translation/Translation";
import DeleteIcon from "static/images/DeleteIcon";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
import TextEllipsis from "components/atoms/TextEllipsis";
import Calender from "pages/microservices/CandidateCreation/helpers/Calender";
import { useFormContext } from "../context/Context";
import PhoneNumber from "components/molecules/PhoneNumber";
import { ContactsTabSubformValidationRules, ContactsTabValidationRules } from "../validationRules/TempAgencyGeneralRules";
import { checkUniqueness } from "../Utils";

const Contacts = () => {
    const { state, dispatch } = useFormContext();
    const [validationStatus, setValidationStatus] = useState({
        isValid: false,
        type: "",
    });

    const location = useLocation();
    // Check if 'mode=view' is present in the query string
    const isViewMode = location.search.includes("mode=view");

    const validation = (
        name: string,
        value: any,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            fName: [validateRequired, validateTextFieldAlpha],
            lName: [validateRequired, validateTextFieldAlpha],
            email: [validateRequired, validateEmail],
            mobNumber: [validatePhoneNumber],
        };
        const validationErrors = validateForm(
            { ...state.contactsPersons.data, [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );

        dispatch({
            type: "UPDATE_CONTACT_LOCATION_FIELD_ERROR",
            fieldName: name,
            error: validationErrors[name],
            tab: 'contactsPersons',
        });

        const isFieldValid = Object.keys(validationErrors).length === 0;

        // if (isFieldValid) {
        //     state.fieldError[name] = "";
        // } else {
        //     state.fieldError[name] = validationErrors[name];
        // }

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }

        return true;
    };

    const handleFieldChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type } = event.target;
        const processedValue = type === "number" ? String(value) : value;
        if (name === "phoneNumber" && /\D/.test(value)) {
            return;
        }
        dispatch({
            type: "UPDATE_LOCATION_OR_CONTACT_FIELD",
            field: name,
            value: processedValue,
            tab: 'contactsPersons'
        });
        validation(name, processedValue, true);
    };

    const handlePhoneNumberChange = (
        mobileNumber: string | E164Number | undefined, name: string
    ) => {
        const processedValue = mobileNumber ? String(mobileNumber) : "";
        dispatch({
            type: "UPDATE_LOCATION_OR_CONTACT_FIELD",
            field: name,
            value: processedValue,
            tab: 'contactsPersons'
        });
        validation(name, processedValue, true);
    };

    const validateAllFields = (): boolean => {
        const validationErrors = validateForm(state.contactsPersons.data, ContactsTabSubformValidationRules);
        if (Object.keys(validationErrors).length > 0) {
            dispatch({
                type: "UPDATE_ALL_CONTACT_OR_LOCATION_FIELD",
                data: state.contactsPersons.data,
                error: validationErrors,
                tab: 'contactsPersons',
            });
        }

        let dataList = [...state?.contactsPersons?.contactsList];
        if (state?.contactsPersons?.editIndex !== null) {
            dataList.splice(state?.contactsPersons?.editIndex, 1);
        }
        const isUnique = checkUniqueness(state.contactsPersons.data, 'email', dataList);
        if (isUnique == undefined && Object.keys(validationErrors).length == 0) {
            return true;
        }
        CustomNotify({ type: "warning", message: 'Email should be unique' });
        return false;
    };

    const handleAddContact = () => {
        const isValid = validateAllFields();
        if (isValid) {
            dispatch({
                type: "UPDATE_LOCATION_CONTACT_LIST_FIELD",
                value: state.contactsPersons?.data,
                tab: 'contactsPersons',
                list: 'contactsList',
                isEdit: false,
            });

            const comapanyContactPersonFields = [
                { field: "userId", value: "" },
                { field: "fName", value: "" },
                { field: "fName", value: "" },
                { field: "lName", value: "" },
                { field: "email", value: "" },
                { field: "locations", value: "" },
                { field: "dob", value: "" },
                { field: "mobNumber", value: "" },
                { field: "tittle", value: "" },
                { field: "functionData", value: "" },
                { field: "linkedIn", value: "" },
                { field: "language", value: "" },
                { field: "info", value: "" },
                { field: "roles", value: "" },
                { field: "businessUnit", value: "" },
            ];

            let contactData: any = {};
            let contactsError: any = {};
            comapanyContactPersonFields.forEach((field) => {
                contactData[field?.field] = field?.value;
                contactsError[field?.field] = "";
            });

            dispatch({
                type: "UPDATE_ALL_CONTACT_OR_LOCATION_FIELD",
                data: contactData,
                error: contactError,
                tab: 'contactsPersons',
            });
        }
    };
    const currentDate = new Date();
    const below15 = new Date();
    below15.setFullYear(currentDate.getFullYear() - 15);

    const handleSelectChange = (selectedOption: any, name: string) => {
        dispatch({
            type: "UPDATE_LOCATION_OR_CONTACT_FIELD",
            field: name,
            value: selectedOption,
            tab: 'contactsPersons'
        });
        validation(name, selectedOption, true);
    };

    const checkForDuplicates = () => {
        const emailSet = new Set();
        const phoneSet = new Set();
        let hasDuplicate = false;

        // state.contacts.forEach((contact, index) => {
        //   if (contact.email.trim() !== "") {
        //     if (emailSet.has(contact.email)) {
        //       state.dynamicErrors[index].email = "Duplicate email";
        //       hasDuplicate = true;
        //     } else {
        //       emailSet.add(contact.email);
        //     }
        //   }

        //   if (contact.mobileNumber.trim() !== "") {
        //     if (phoneSet.has(contact.mobileNumber)) {
        //       state.dynamicErrors[index].mobileNumber = "Duplicate phone number";
        //       hasDuplicate = true;
        //     } else {
        //       phoneSet.add(contact.mobileNumber);
        //     }
        //   }
        // });

        return hasDuplicate;
    };

    const handleEdit = (entryId: number) => {
        dispatch({
            type: "EDIT_PARTICULAR_CONTACT_LOCATION_FIELD",
            index: entryId,
            tab: "contactsPersons",
            list: 'contactsList',
        });
    };

    const handleArchive = (deleteId: number) => {
        dispatch({
            type: "DELETE_CONTACT_LOCATION_INDEX",
            index: deleteId,
            tab: 'contactsPersons',
            list: 'contactsList',
        });
    };

    const handleSaveParticularData = () => {
        if (validateAllFields()) {
            dispatch({
                type: "UPDATE_LOCATION_CONTACT_LIST_FIELD",
                value: state.contactsPersons?.data,
                tab: 'contactsPersons',
                list: 'contactsList',
                isEdit: true,
            });
            const comapanyContactPersonFields = [
                { field: "userId", value: "" },
                { field: "id", value: "" },
                { field: "fName", value: "" },
                { field: "tittle", value: "" },
                { field: "lName", value: "" },
                { field: "email", value: "" },
                { field: "locations", value: "" },
                { field: "mobNumber", value: "" },
                { field: "functionData", value: "" },
                { field: "linkedIn", value: "" },
                { field: "dob", value: "" },
                { field: "language", value: "" },
                { field: "info", value: "" },
                { field: "roles", value: "" },
                { field: "businessUnit", value: "" },
            ];

            let contactData: any = {};
            let contactsError: any = {};
            comapanyContactPersonFields.forEach((field) => {
                contactData[field?.field] = field?.value;
                contactsError[field?.field] = "";
            });
            dispatch({
                type: "UPDATE_ALL_CONTACT_OR_LOCATION_FIELD",
                data: contactData,
                error: contactsError,
                tab: 'contactsPersons'
            });
        }
    };
    const handleDateChange = (date: Date | null, name: string) => {
        if (date && !isNaN(date.getTime())) {
            const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1
                }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
            dispatch({
                type: "UPDATE_LOCATION_OR_CONTACT_FIELD",
                field: name,
                value: formattedDate,
                tab: 'contactsPersons'
            });
        } else {
            console.error("Invalid date selected");
        }
    };

    const contactsData = state?.contactsPersons?.data;
    const contactError = state?.contactsPersons?.errors;

    const editIndex = state?.contactsPersons?.editIndex;

    const locationOptions = state?.location?.locationList?.map((eachLoc: any, index: number) => {
        return { id: eachLoc?.id, value: index + 1, label: eachLoc?.locationName }
    });

    return (
        <form>
            <div className="row">
                <div className="col-12">
                    <div className="form-border" style={{ paddingTop: "3vw" }}>
                        {state?.contactsPersons?.contactsList?.length > 0 && (
                            <div className="bg-white locationBg">
                                <table className="table table-hover createCompanyContactsTable">
                                    <thead className="TableHeader">
                                        <tr>
                                            <th>{t("Name")}</th>
                                            <th>{t("Email")}</th>
                                            <th>{t("Mobile number")}</th>
                                            <th>{t("Function")}</th>
                                            <th>{t("Action")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.contactsPersons.contactsList.map(
                                            (eachContactPersonData: any, index: number) => (
                                                <tr key={index}>
                                                    <td className="align-middle">{`${eachContactPersonData?.fName} ${eachContactPersonData?.lName}`}</td>
                                                    <td className="align-middle">
                                                        {eachContactPersonData?.email}
                                                    </td>
                                                    <td className="align-middle">
                                                        {eachContactPersonData?.mobNumber}
                                                    </td>
                                                    <td className="align-middle">
                                                        <TextEllipsis
                                                            title={eachContactPersonData?.functionData}
                                                            label={eachContactPersonData?.functionData}
                                                            width="16vw"
                                                        />
                                                    </td>
                                                    <td className="table-action-icons">
                                                        <span
                                                            onClick={() => handleEdit(index)}
                                                            className="table-action-btn cursor-pointer marginRightPoint5"
                                                            title={isViewMode ? t("View") : t("Edit")}
                                                        >
                                                            {isViewMode ? <ViewIcon /> : <EditIcon />}
                                                        </span>
                                                        {!isViewMode && (
                                                            <span
                                                                onClick={() => handleArchive(index)}
                                                                className="table-action-btn cursor-pointer"
                                                                title={t("Delete")}
                                                            >
                                                                <DeleteIcon />
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <div className="row">
                            {!isViewMode && (
                                <>
                                    <div className="col-2">
                                        <SelectWithSearch
                                            title={t("Title")}
                                            name="tittle"
                                            isMandatory={false}
                                            search={true}
                                            options={state?.options?.tittle}
                                            placeHolder={t("Select")}
                                            value={contactsData.tittle}
                                            onChange={(e) => handleSelectChange(e, "tittle")}
                                            isMulti={false}
                                            className="select-field"
                                            isDisabled={isViewMode}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-5">
                                        <LabelWithInputField
                                            isMandatory={true}
                                            name="fName"
                                            handleChange={(event) => handleFieldChange(event)}
                                            value={contactsData.fName ?? ""}
                                            label="First name"
                                            placeholder="First name"
                                            error={contactError.fName}
                                            isDisabled={isViewMode}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-5">
                                        <LabelWithInputField
                                            isMandatory={true}
                                            name="lName"
                                            handleChange={(event) => handleFieldChange(event)}
                                            value={contactsData.lName ?? ""}
                                            label="Last name"
                                            placeholder="Last name"
                                            error={contactError.lName}
                                            isDisabled={isViewMode}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <LabelWithInputField
                                            isMandatory={true}
                                            name="email"
                                            handleChange={(event) => handleFieldChange(event)}
                                            value={contactsData.email ?? ""}
                                            label="Email"
                                            placeholder="Email"
                                            type="email"
                                            error={contactError?.email}
                                            isDisabled={isViewMode}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <PhoneNumber
                                            label={t("Phone number")}
                                            name="mobNumber"
                                            value={contactsData?.mobNumber ?? ""}
                                            defaultCountry='BE'
                                            placeholder={t('Mobile number')}
                                            disabled={isViewMode}
                                            handleChange={(event: any) => handlePhoneNumberChange(event, "mobNumber")}
                                            required={true}
                                            error={contactError?.mobNumber}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <Calender
                                            onChange={(date) => handleDateChange(date, 'dob')}
                                            label={t("Date of birth")}
                                            isMandatory={false}
                                            name={"dob"}
                                            selectedDate={
                                                contactsData?.dob ? new Date(contactsData.dob) : null
                                            }
                                            maxDate={new Date()}
                                            placeHolder="dd-mm-yyyy"
                                        // minDate={new Date()} //enable dates from current date
                                        ></Calender>
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <SelectWithSearch
                                            title="Roles"
                                            name="role"
                                            isMandatory={false}
                                            search={true}
                                            options={state.options?.roles}
                                            placeHolder="Select"
                                            value={contactsData.roles ?? ""}
                                            onChange={(e) => handleSelectChange(e, "roles")}
                                            isMulti={true}
                                            className="select-field"
                                            isDisabled={isViewMode}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <LabelWithInputField
                                            isMandatory={false}
                                            name="functionData"
                                            handleChange={(event) => handleFieldChange(event)}
                                            value={contactsData.functionData ?? ""}
                                            label="Function"
                                            placeholder="function"
                                            isDisabled={isViewMode}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <SelectWithSearch
                                            title="Language"
                                            name="language"
                                            isMandatory={false}
                                            search={true}
                                            options={state.options?.language}
                                            placeHolder="Select"
                                            value={contactsData.language}
                                            onChange={(e) => handleSelectChange(e, "language")}
                                            isMulti={false}
                                            className="select-field"
                                            isDisabled={isViewMode}
                                        />
                                    </div>

                                    <div className="col-sm-12 col-md-4">
                                        <LabelWithInputField
                                            isMandatory={false}
                                            name="linkedIn"
                                            handleChange={(event) => handleFieldChange(event)}
                                            value={contactsData.linkedIn ?? ""}
                                            label="Linkedin website url"
                                            placeholder="LinkedIn url"
                                            isDisabled={isViewMode}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <SelectWithSearch
                                            title="Location"
                                            name="locations"
                                            isMandatory={false}
                                            search={true}
                                            options={locationOptions}
                                            placeHolder="Select"
                                            value={contactsData.locations ?? ""}
                                            onChange={(e) => handleSelectChange(e, "locations")}
                                            isMulti={true}
                                            className="select-field"
                                            isDisabled={isViewMode}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <SelectWithSearch
                                            title="Business unit"
                                            name="businessUnit"
                                            isMandatory={false}
                                            search={true}
                                            options={state.options?.businessUnit}
                                            placeHolder="Select"
                                            value={contactsData.businessUnit ?? ""}
                                            onChange={(e) => handleSelectChange(e, "businessUnit")}
                                            isMulti={false}
                                            className="select-field"
                                            isDisabled={isViewMode}
                                        />
                                    </div>
                                    <div className="col-lg-12">
                                        <LabelWithTextAreaField
                                            name="info"
                                            handleChange={(event) => handleFieldChange(event)}
                                            value={contactsData.info ?? ""}
                                            label="Info"
                                            isDisabled={isViewMode}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {!isViewMode && (
                                    <Button
                                        title={
                                            editIndex !== null
                                                ? t("Save")
                                                : t("+ Add another")
                                        }
                                        handleClick={
                                            editIndex !== null
                                                ? handleSaveParticularData
                                                : handleAddContact
                                        }
                                        className="form-button float-end"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Contacts;
