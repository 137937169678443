import React, { useEffect, useState } from "react";
import TitleFieldMolecule from "components/molecules/TitleField";
import { DocumentHeader } from "TableHeader";
import { Link, useNavigate } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { PATH_MASTER_DATA } from "constants/Paths";
import Popup from "components/molecules/Popup";
import { ApiCall } from "services/ApiServices";
import { GET_DOCUMENT_MODULE_OPTIONS, GET_DOCUMENTS } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import Search from "components/atoms/Search";
import CustomNotify from "components/atoms/CustomNotify";
import Button from "components/atoms/Button";
import Pagination from "components/atoms/Pagination";
import DocView from "components/molecules/DocView";
import AccessControl from "services/AccessControl";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import FilterOrganism from "components/organism/FilterOrganism";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";

interface Document {
  id: number;
  emp_doc_id: number;
  fileName: string;
  filePath: string;
  title: string;
  url: string;
  type: string;
}

const ManageDocumentPage: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}

  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [submit, setSubmit] = useState(true);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [search, setSearch] = useState({
    file_name: queryParams.get("name") ?? "",
    company: queryParams.get("company") ?? "",
    employee: queryParams.get("employee") ?? "",
  });
  const navigate = useNavigate();
  const [selectedDocument, setSelectedDocument] = useState<Document | null>({
    id: 0,
    emp_doc_id: 0,
    fileName: "",
    filePath: "",
    title: "Document viewer",
    url: "",
    type: "",
  });

  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    initialCall: true,
    refresh: true,
  });

  const [options, setOptions] = useState({
    documentModule: [],
  });

  const getSearchData = () => {
    return {
      fileName: history?.filters?.fileName ?? "",
      documentType: history?.filters?.documentType ?? "",
      documentModule: history?.filters?.documentModule ?? "",
      documentEntityName: history?.filters?.documentEntityName ?? "",
      fileSize: history?.filters?.fileSize ?? "",
      startDate: history?.filters?.startDate ?? "",
      endDate: history?.filters?.endDate ?? "",
      employee: history?.filters?.employee ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    }
  };

  const getOptions = async () => {
    try {
      const response = await ApiCall.getService(
        GET_DOCUMENT_MODULE_OPTIONS,
        "GET",
        M_MASTER_DATA,
        false
      );
      if (response?.status == 200) {
        setOptions((prevOptions: any) => ({ ...prevOptions, documentModule: response?.data?.documentModule }));
      }
      else {
        CustomNotify({ type: "warning", message: response?.message });
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    const data = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall
    };
    fetchDocuments(data);
  }, [pageData?.refresh, pageData?.initialCall]);

  const fetchDocuments = async (data: any) => {
    try {
      const response = await ApiCall.service(
        GET_DOCUMENTS,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setDocuments(response?.data?.data);
        setPageData((prev: any) => ({ ...prev, totalPages: response?.data?.totalPages, totalRecords: response?.data?.totalRecords }))
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (name: string, value: string | number) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any, clear: boolean = false) => {
    e.preventDefault();
    if (clear) {
      navigate({ search: "" });
      setSearch({
        file_name: "",
        company: "",
        employee: "",
      });
    } else {
      const queryParams = Object.entries(search)
        .filter(([key, value]) => value !== undefined && value !== "")
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");
      if (queryParams !== "") {
        navigate(`?${queryParams}`);
      } else {
        CustomNotify({
          type: "warning",
          message: t("Search input not provided."),
        });
      }
    }
    setSubmit(!submit);
    setCurrentPage(1);
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const debounceTime = 10;
    clearTimeout((handleSearchChange as any).timeoutId);
    (handleSearchChange as any).timeoutId = setTimeout(() => {
      handleSearch(name, value);
    }, debounceTime);
  };

  const handleAction = (document: Document, action: string) => {
    console.log(document)
    if (!document || !document?.filePath) {
      console.error("Invalid document data");
      return;
    }
    const fileInfo = JSON.parse(document?.filePath);
    const { fileName, accessUrl } = fileInfo;
    const fileType = fileName?.split(".").pop()?.toLowerCase();
    if (fileType !== undefined && fileType !== "") {
      setSelectedDocument((prevState: any) => ({
        ...prevState,
        title: fileName,
        url: accessUrl,
        type: fileType,
      }));
    }

    switch (action) {
      case "view":
        openPopup();
        break;
      case "download":
        downloadFile(fileName, accessUrl);
        break;
      default:
        console.error("Invalid action");
    }
  };

  const openPopup = () => setShowPopup(true);
  const closePopup = () => setShowPopup(false);

  const downloadFile = (fileName: string, accessUrl: string) => {
    fetch(accessUrl)
      ?.then((response) => response?.blob())
      ?.then((blob) => {
        const blobURL = URL?.createObjectURL(blob);
        const link = document?.createElement("a");
        link.href = blobURL;
        link.download = fileName;
        document?.body?.appendChild(link);
        link?.click();
        document?.body?.removeChild(link);
      })
      ?.catch((error) => console.error("Error downloading file:", error));
  };

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const userData = useSelector(selectAuth);

  const filterFields = [
    { name: 'fileName', fieldType: "text", placeholder: "File name", filterType: 'search' },
    { name: 'documentType', fieldType: "text", placeholder: "Document type", filterType: 'search' },
    { name: 'documentModule', fieldType: "singleSelect", options: options?.documentModule, placeholder: "Document module", filterType: 'search' },
    { name: 'documentEntityName', fieldType: "text", placeholder: "Candidate/Company/Project/Fleet/Candidate", filterType: 'search' },
    // { name: 'fileSize', fieldType: "text", placeholder: "File size", filterType: 'search' },
    { name: 'startDate', fieldType: "date", end: history?.filters?.endDate, placeholder: "Start date", filterType: 'search' },
    { name: 'endDate', fieldType: "date", start: history?.filters?.startDate, placeholder: "End date", filterType: 'search' },
    { name: 'employee', fieldType: "text", placeholder: "Employee", filterType: 'search' },
  ]

  return (
    <AccessControl
      renderNoAccess={true}
      requiredPermissions={[
        {
          permission: "Document",
          read: true,
        },
      ]}
      override={userData.isSuperAdmin}
    >
      <div>
        <div className="search-bar">
          <div className="row">
            <Title title={t("Documents")} />
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive manageDocuments tableSection">
                <FilterOrganism
                  dispatch={dispatch}
                  history={history}
                  updatePageFilters={updatePageFilters}
                  filters={filterFields}
                  handleRefresh={handleRefresh}
                />
                <div className="manageDocumentsTable">
                  <DynamicTableStructure
                    isAction
                    headers={DocumentHeader}
                    data={documents}
                    handleView={(document: Document) =>
                      handleAction(document, "view")
                    }
                    handleDownload={(document: Document) =>
                      handleAction(document, "download")
                    }
                    permission={"Document"}
                    history={history}
                    handleRefresh={handleRefresh}
                  />
                </div>
                <div
                  className=""
                  style={{ paddingTop: "0.25vw" }}
                >
                  <PaginationWithPerPage
                    handleRefresh={handleRefresh}
                    dispatch={dispatch}
                    history={history}
                    pageData={pageData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPopup && documents.length > 0 && selectedDocument && (
          <Popup
            title={t(selectedDocument.title)}
            body={
              <>
                {selectedDocument.type === "pdf" ? (
                  <iframe
                    src={selectedDocument.url}
                    title={selectedDocument.title}
                    style={{ width: "100%", height: "70vh" }}
                  />
                ) : (
                  <DocView
                    fileType={selectedDocument.type}
                    fileUrl={selectedDocument.url}
                  />
                )}
              </>
            }
            close={closePopup}
            modalSize={"xl"}
            notext="Close"
            cancel={closePopup}
          />
        )}

        {/* <div className="row">
          <div className="col-12">
            <div style={{ padding: "0.85vw 0" }}>
              <BackButton />
            </div>
          </div>
        </div> */}
      </div>
    </AccessControl>
  );
};

export default ManageDocumentPage;
