import React, { useState } from "react";
import CheckBoxField from "components/atoms/CheckBoxField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import "./css/company.css";
// import { useFormContext } from "pages/microservices/companyCreation/context/Context";
// import { Navigation } from "../formNavigation/Navigation";
import { ValidationRules } from "utils/TypeAnnotations";

import {
  validateForm,
  validateRequired,
  validateAlphaNumeric,
  validateMaxLength,
  Option,
  validateMinLength,
  scrollToTop,
  validateNoSpaces,
  validateSelectField,
  validateVatNumber,
  validateFormField,
  validateMultiSelectField,
} from "services/validation/ValidationService";
import { ApiCall } from "services/ApiServices";
import {
  REGISTER_COMPANY,
  COMPANY_VAT_VALIDATION,
  COMPANY_VAT_EXISTENCE,
  GET_CONSULTANT_BUSINESSUNIT,
} from "routes/ApiEndpoints";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
// import {
//   M_COMPANY_CREATION,
//   M_WEB_CONNECTOR,
// } from "../../../../../constants/Constants";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import { t } from "pages/microservices/masterData/translation/Translation";
import { SetJobProfileData } from "pages/microservices/CandidateCreation/create-candidate/form-data/SetJobProfileData";
import { M_COMPANY_CREATION, M_WEB_CONNECTOR } from "constants/Constants";
import { useFormContext } from "../context/Context";
import {
  SET_FIELD_ERROR_COMPANY_BASIC_TAB,
  UPDATE_COMPANY_ADDRESS_ERROR,
  UPDATE_COMPANY_ADDRESS_FIELD,
  UPDATE_COMPANY_BASIC_FIELD,
  UPDATE_COMPANY_CONTACTS_FIELD,
} from "../context/Constants";
import { BasicTabData, BasicTabError } from "../context/Interfaces";
import { CompanyBasicTabValidationRules } from "../validationRules/CompanyValidationRules";
interface ICompanyFormBasicTabProps {
  type?: string | any;
}

const CompanyBasic: React.FC<ICompanyFormBasicTabProps> = (type) => {
  const { state, dispatch } = useFormContext();
  const companyBasicData: BasicTabData = state.companyBasic.data;
  const isViewMode = state.isViewMode;

  const companyBasicErrors: BasicTabError = state.companyBasic.errors;
  const userData = useSelector(selectAuth);
  const location = useLocation();
  // Check if 'mode=view' is present in the query string

  const validation = (
    name: string,
    value: string | boolean | number | Option | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      vat: [
        validateRequired,
        (value: string) => validateVatNumber(value),
        validateNoSpaces,
      ],
      companyName: [validateRequired],
      officialName: [validateRequired],
      companyType: [validateSelectField],
    };

    if (state.companyBasic.data.isBussinessUnit) {
      validationRules.bussinessUnit = [validateMultiSelectField];
    }
    const validationErrors = validateForm(
      { ...state.companyBasic.data, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    dispatch({
      type: SET_FIELD_ERROR_COMPANY_BASIC_TAB,
      fieldName: name,
      error: validationErrors[name],
    });

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({
        type: SET_FIELD_ERROR_COMPANY_BASIC_TAB,
        fieldName: name,
        error: "",
      });
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      if (name === "hq" && checked) {
        dispatch({
          type: UPDATE_COMPANY_BASIC_FIELD,
          field: "parentCompanies",
          value: null,
        });
        dispatch({
          type: UPDATE_COMPANY_BASIC_FIELD,
          field: "coEfficient",
          value: 0,
        });
      }
      const newValue = checked ? 1 : 0;
      dispatch({
        type: UPDATE_COMPANY_BASIC_FIELD,
        field: name,
        value: newValue,
      });

      let validatedFieldError = validateFormField(
        companyBasicData as { [key: string]: any },
        CompanyBasicTabValidationRules,
        name,
        value
      );

      dispatch({
        type: SET_FIELD_ERROR_COMPANY_BASIC_TAB,
        fieldName: name,
        error: validatedFieldError,
      });
    } else {
      const formattedValue =
        name === "companyName" || name === "officialName"
          ? value.toUpperCase()
          : value;
      dispatch({
        type: UPDATE_COMPANY_BASIC_FIELD,
        field: name,
        value: formattedValue,
      });
      let validatedFieldError = validateFormField(
        companyBasicData as { [key: string]: any },
        CompanyBasicTabValidationRules,
        name,
        value
      );
      dispatch({
        type: SET_FIELD_ERROR_COMPANY_BASIC_TAB,
        fieldName: name,
        error: validatedFieldError,
      });
    }
  };
  const getConsultantBusinessUnit = async (consultantId: any) => {
    const response = await ApiCall.service(
      GET_CONSULTANT_BUSINESSUNIT,
      "POST",
      { consultant: consultantId },
      false,
      M_COMPANY_CREATION
    );
    if (response?.status === 200) {
      dispatch({
        type: UPDATE_COMPANY_BASIC_FIELD,
        field: "bussinessUnit",
        value: response?.data,
      });
      dispatch({
        type: UPDATE_COMPANY_CONTACTS_FIELD,
        field: "businessUnit",
        value: response?.data,
      });
    }
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    if (name === "pc") {
      SetJobProfileData(dispatch, selectedOption, name, "", "company");
      dispatch({
        type: UPDATE_COMPANY_BASIC_FIELD,
        field: "domain",
        value: null,
      });
      dispatch({
        type: UPDATE_COMPANY_BASIC_FIELD,
        field: "function",
        value: null,
      });
    }
    if (name === "consultant") {
      if (selectedOption.value) {
        getConsultantBusinessUnit(selectedOption.value);
      } else {
        dispatch({
          type: UPDATE_COMPANY_BASIC_FIELD,
          field: "bussinessUnit",
          value: userData.businessUnit,
        });
        dispatch({
          type: UPDATE_COMPANY_CONTACTS_FIELD,
          field: "businessUnit",
          value: userData.businessUnit,
        });
      }
    }
    if (name === "domain") {
      SetJobProfileData(dispatch, selectedOption, name, "", "company");
      dispatch({
        type: UPDATE_COMPANY_BASIC_FIELD,
        field: "function",
        value: null,
      });
    }

    if (name === "companyType") {
      if (selectedOption.value === 2 && selectedOption.label === "External") {
        dispatch({
          type: UPDATE_COMPANY_BASIC_FIELD,
          field: "isBussinessUnit",
          value: true,
        });
        dispatch({
          type: UPDATE_COMPANY_BASIC_FIELD,
          field: name,
          value: selectedOption,
        });
        dispatch({
          type: UPDATE_COMPANY_BASIC_FIELD,
          field: "bussinessUnit",
          value: userData.businessUnit,
        });
        dispatch({
          type: UPDATE_COMPANY_BASIC_FIELD,
          field: "consultant",
          value: userData.userId,
        });
        validation(name, selectedOption, true);
      } else if (
        selectedOption.value === 1 &&
        selectedOption.label === "Internal"
      ) {
        dispatch({
          type: UPDATE_COMPANY_BASIC_FIELD,
          field: name,
          value: selectedOption,
        });
        dispatch({
          type: UPDATE_COMPANY_BASIC_FIELD,
          field: "isBussinessUnit",
          value: false,
        });
        dispatch({
          type: UPDATE_COMPANY_BASIC_FIELD,
          field: "bussinessUnit",
          value: null,
        });
        validation(name, selectedOption, true);
      }
    } else {
      dispatch({
        type: UPDATE_COMPANY_BASIC_FIELD,
        field: name,
        value: selectedOption,
      });

      validation(name, selectedOption, true);
    }
  };
  const verifyVatHandler = async (
    e: any,
    field: string,
    value: string,
    companyStatus: any,
    id: any
  ) => {
    validation(field, value, true);
    const formData = { [field]: value };
    if (state.companyBasic.errors.vat || !value) {
      return;
    }
    const vatData = {
      vat_number: value,
      key: `${process.env.REACT_APP_WEBCONNECTOR_UNIQUE_KEY}`,
    };
    if (companyStatus === 0 && !id) {
      const vatExistence = await ApiCall.service(
        COMPANY_VAT_EXISTENCE,
        "POST",
        vatData,
        false,
        M_COMPANY_CREATION
      );
      if (vatExistence?.status === 400) {
        dispatch({
          type: SET_FIELD_ERROR_COMPANY_BASIC_TAB,
          fieldName: "vat",
          error: vatExistence?.msg,
        });
        scrollToTop();
        return;
      }
    }

    const url = COMPANY_VAT_VALIDATION;
    const response = await ApiCall.service(
      url,
      "POST",
      vatData,
      true,
      M_WEB_CONNECTOR,
      false
    );
    if (response?.status !== 200) {
      CustomNotify({ type: "error", message: response?.error });
      dispatch({
        type: UPDATE_COMPANY_BASIC_FIELD,
        field: "companyName",
        value: "",
      });
      dispatch({
        type: UPDATE_COMPANY_BASIC_FIELD,
        field: "officialName",
        value: "",
      });
      handleAddressUpdate("", "", "", "", "");
      //dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
      scrollToTop();
      //  state.fieldError.vat = response?.error;
    } else {
      const value = response?.data?.name;
      const { street, number, box, postal, city } = response?.data?.address;

      handleAddressUpdate(street, number, box, postal, city);
      dispatch({
        type: SET_FIELD_ERROR_COMPANY_BASIC_TAB,
        fieldName: "companyName",
        error: "",
      });
      dispatch({
        type: UPDATE_COMPANY_BASIC_FIELD,
        field: "officialName",
        value: value.toUpperCase(),
      });
      dispatch({
        type: SET_FIELD_ERROR_COMPANY_BASIC_TAB,
        fieldName: "officialName",
        error: "",
      });
      dispatch({
        type: UPDATE_COMPANY_BASIC_FIELD,
        field: "companyName",
        value: value.toUpperCase(),
      });
      CustomNotify({ type: "success", message: "VAT number is verified" });
      //  dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: false });
    }
  };
  const handleAddressUpdate = (
    street: string,
    number: string,
    unit: string,
    postal: string,
    city: string,
    countryValue: { value: number; label: string } = {
      value: 29,
      label: "Belgium",
    }
  ) => {
    const addressFields = [
      { field: "street", value: street },
      { field: "number", value: number },
      { field: "box", value: unit },
      { field: "zipCode", value: postal },
      { field: "city", value: city },
      { field: "country", value: countryValue },
    ];

    addressFields.forEach((field) => {
      dispatch({
        type: UPDATE_COMPANY_ADDRESS_FIELD,
        field: field.field,
        value: field.value,
      });
      dispatch({
        type: UPDATE_COMPANY_ADDRESS_ERROR,
        fieldName: field.field,
        error: "",
      });
      dispatch({
        type: UPDATE_COMPANY_ADDRESS_FIELD,
        field: `bi${
          field.field.charAt(0).toUpperCase() + field.field.slice(1)
        }`,
        value: field.value,
      });
      dispatch({
        type: UPDATE_COMPANY_ADDRESS_ERROR,
        fieldName: `bi${
          field.field.charAt(0).toUpperCase() + field.field.slice(1)
        }`,
        error: "",
      });
    });
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="form-border" style={{ paddingTop: "3vw" }}>
          <div className="row">
            <div className="col-6 generalTabVAT position-relative">
              <div className="position-relative">
                <div className="position-absolute fieldPrefix">BE</div>
                <LabelWithInputField
                  isMandatory={true}
                  name="vat"
                  handleChange={handleFieldChange}
                  value={state.companyBasic.data.vat}
                  id="vat"
                  label="VAT number"
                  type="text"
                  error={state.companyBasic.errors.vat}
                  isDisabled={
                    state.companyBasic.data.companyStatus == 1 || isViewMode
                  }
                />
                <Button
                  type="button"
                  title="Verify"
                  handleClick={(e) =>
                    verifyVatHandler(
                      e,
                      "vat",
                      state.companyBasic.data.vat,
                      state.companyBasic.data.companyStatus,
                      state.companyBasic.data.id
                    )
                  }
                  className={`form-button companyVatVerify`}
                  disabled={state.companyBasic.data.companyStatus === 1}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <LabelWithInputField
                isMandatory={true}
                name="companyName"
                handleChange={handleFieldChange}
                value={state.companyBasic.data.companyName}
                id="company"
                label="Company name"
                type="text"
                error={state.companyBasic.errors.companyName}
                isDisabled={isViewMode}
              />
            </div>
            <div className="col-6">
              <LabelWithInputField
                isMandatory={true}
                name="officialName"
                handleChange={handleFieldChange}
                value={state.companyBasic.data.officialName}
                id="official"
                label="Official name"
                type="text"
                error={state.companyBasic.errors.officialName}
                isDisabled={isViewMode}
              />
            </div>
            <div className="col-6">
              <LabelWithInputField
                isMandatory={false}
                name="website"
                handleChange={handleFieldChange}
                value={state.companyBasic.data.website}
                id="website"
                label="Website"
                type="text"
                isDisabled={isViewMode}
                //  error={state.fieldError.website}
              />
            </div>
            <div className="col-sm-4">
              <SelectWithSearch
                title="Paritair comite"
                name="pc"
                isMandatory={false}
                search={true}
                options={state.options.pcList}
                placeHolder="Select"
                value={state.companyBasic.data.pc}
                onChange={(e) => handleSelectChange(e, "pc")}
                isMulti={true}
                className="select-field"
                // error={state.fieldError.pc}
                isDisabled={isViewMode}
              />
            </div>
            <div className="col-sm-4">
              <SelectWithSearch
                title="Sector"
                name="sector"
                isMandatory={false}
                search={true}
                options={state.options.sectorList}
                placeHolder="Select"
                value={state.companyBasic.data.sector}
                onChange={(e) => handleSelectChange(e, "sector")}
                isMulti={true}
                className="select-field"
                // error={state.fieldError.pc}
                isDisabled={isViewMode}
              />
            </div>
            <div className="col-sm-4">
              <SelectWithSearch
                title="Domian"
                name="domain"
                isMandatory={false}
                search={true}
                options={state.options.domainList}
                placeHolder="Select"
                value={state.companyBasic.data.domain}
                onChange={(e) => handleSelectChange(e, "domain")}
                isMulti={true}
                className="select-field"
                // error={state.fieldError.pc}
                isDisabled={isViewMode}
              />
            </div>
            <div className="col-sm-4">
              <SelectWithSearch
                title={t("Function")}
                name="function"
                isMandatory={false}
                search={true}
                options={state.options.functionList}
                placeHolder="Select"
                value={state.companyBasic.data.function}
                onChange={(e) => handleSelectChange(e, "function")}
                isMulti={true}
                className="select-field"
                // error={state.fieldError.pc}
                isDisabled={isViewMode}
              />
            </div>
            <div className="col-sm-4">
              <SelectWithSearch
                title="NACE codes"
                name="naceCodes"
                isMandatory={false}
                search={true}
                options={state.options.naceCodesList}
                placeHolder="Select"
                value={state.companyBasic.data.naceCodes}
                onChange={(e) => handleSelectChange(e, "naceCodes")}
                isMulti={true}
                className="select-field"
                isDisabled={isViewMode}
                //  error={state.fieldError.naceCodes}
              />
            </div>

            <div className="col-sm-12 col-md-4">
              <SelectWithSearch
                title="Company type"
                name="companyType"
                isMandatory={true}
                search={true}
                options={[
                  { value: 1, label: "Internal" },
                  { value: 2, label: "External" },
                ]}
                placeHolder="Select"
                value={state.companyBasic.data.companyType}
                onChange={(e) => handleSelectChange(e, "companyType")}
                isMulti={false}
                className="select-field"
                error={state.companyBasic.errors.companyType}
                isDisabled={
                  isViewMode ||
                  userData.role.includes("HR") ||
                  userData.role.includes("SALES")
                }
              />
            </div>
            {state.companyBasic.data.isBussinessUnit && (
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title="Consultant"
                  name="consultant"
                  isMandatory={false}
                  search={true}
                  options={state.options.consultantList}
                  placeHolder="Select"
                  value={state.companyBasic.data.consultant}
                  onChange={(e) => handleSelectChange(e, "consultant")}
                  isMulti={false}
                  className="select-field"
                  isDisabled={isViewMode}
                />
              </div>
            )}
            {state.companyBasic.data.isBussinessUnit && (
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title="Bussiness unit"
                  name="bussinessUnit"
                  isMandatory={true}
                  search={true}
                  options={state.options.bussinessUnitList}
                  placeHolder="Select"
                  value={state.companyBasic.data.bussinessUnit}
                  onChange={(e) => handleSelectChange(e, "bussinessUnit")}
                  isMulti={true}
                  className="select-field"
                  error={state.companyBasic.errors.bussinessUnit}
                  isDisabled={isViewMode}
                />
              </div>
            )}

            <div className="col-md-12" style={{ marginBottom: "1vw" }}>
              <CheckBoxField
                label="This is parent company"
                name="hq"
                onChangeHandler={handleFieldChange}
                isChecked={state.companyBasic.data.hq === 1}
                disable={isViewMode}
                id="hq"
                lineHeight="1.6vw"
              />
            </div>
            <div className="col-6">
              <SelectWithSearch
                title="Select parent company"
                name="parentCompanies"
                isMandatory={false}
                search={true}
                options={state.options.companiesList}
                placeHolder="Select"
                isDisabled={state.companyBasic.data.hq === 1 || isViewMode}
                value={state.companyBasic.data.parentCompanies}
                onChange={(e) => handleSelectChange(e, "parentCompanies")}
                isMulti={false}
                className="select-field"
                //  error={state.fieldError.parentCompanies}
              />
            </div>
            <div className="col-md-12">
              <CheckBoxField
                label="Active"
                name="active"
                onChangeHandler={handleFieldChange}
                isChecked={state.companyBasic.data.active}
                disable={isViewMode}
                id="active"
                lineHeight="1.7vw"
              />
            </div>
            {/* {state.companyBasic.hq === 0 && (
            <div className="col-md-12">
              <CheckBoxField
                label="Follow the same coefficient as the parent company"
                name="coEfficient"
                onChangeHandler={handleFieldChange}
                isChecked={state.basic.coEfficient === 1}
              />
            </div>
          )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyBasic;
