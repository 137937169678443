import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import AddPlusIcon from "static/images/AddPlusIcon";
import { t } from "../translation/Translation";
import CloseFile from "static/images/CloseFile";
import CheckBoxField from "components/atoms/CheckBoxField";

const CommonComponent = (props: any) => {
  const { handleChange, formData, errors, handleSelectChange, changeHandler,handelDefaultCheckbox } =
    props;

  const rangeOptions = [
    { value: 4, label: "1-4" },
    { value: 6, label: "1-6" },
  ];

  return (
    <>
      <div className="form-group col-md-4">
        <LabelWithInputField
          isMandatory={true}
          name="competenceName"
          handleChange={(e) => changeHandler(e)}
          value={formData.competenceName}
          id="competenceName"
          label={t("Name of competences")}
          placeholder={t("Name of competences")}
          type="text"
          error={errors.competenceName}
        />
      </div>
      <div className="form-group col-md-4">
        <SelectWithSearch
          isMandatory={true}
          name="competenceRange"
          onChange={(e) => handleSelectChange(e, "competenceRange")}
          value={formData.competenceRange}
          id="competenceRange"
          title={t("Competence range")}
          placeHolder={t("Select")}
          search={true}
          options={rangeOptions}
          error={errors.competenceRange}
        />
      </div>
      <div className="col-md-12">
        <LabelWithTextAreaField
          name="info"
          handleChange={(event) => changeHandler(event)}
          value={formData.info}
          label={t("Info")}
          isMandatory={true}
          error={errors.info}
        />
      </div>
      <div className="row">
        <div className="col-md-12">
          <CheckBoxField
            label={t("Default competence")}
            name="defaultCompetence"
            onChangeHandler={(event) =>
                handelDefaultCheckbox(event)
            }
            isChecked={formData.defaultStatus}
            id="questionStatus"
            lineHeight="1.5vw"
          />
        </div>
      </div>
    </>
  );
};
export default CommonComponent;
