import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormContext } from "../../context/Context";
import { DOCUMENTS, GENERAL, INTERNAL_INFO, NEXT_STEP, PREVIOUS_STEP, UPDATE_ERROR_FIELD, UPDATE_TAB_DETAILS } from "../../annotations/CandidateConstants";
import { t } from "pages/microservices/masterData/translation/Translation";
import Button from "components/atoms/Button";
import LoadingIcon from "utils/LoadingIcon";
import { ApiCall } from "services/ApiServices";
import { M_IDENTITY_MANAGER, M_MASTER_DATA } from "constants/Constants";
import { CANDIDATE_CREATION, DYNAMIC_TODO_CREATION, FETCH_CANDIDATE_SALARY_TYPE } from "routes/ApiEndpoints";
import { validateMandatoryFields } from "./FormValidation";
import CustomNotify from "components/atoms/CustomNotify";
import { MANAGE_CANDIDATES } from "constants/Paths";
import { UPDATE_TAB_ERROR } from "pages/microservices/vacancies/annotation/VacancyConstants";
import BackButton from "components/atoms/BackButton";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

export interface MandatoryFields {
  [key: string]: string[];
}

interface NavProps {
  validStatus: (value: { isValid: boolean; type: string }) => void;
  isLoading?: boolean;
}

const Navigation: React.FC<NavProps> = ({ validStatus, isLoading = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isView = location?.pathname?.includes('view');
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useFormContext();
  const { id } = useParams<{ id: string }>();
  const user = useSelector(selectAuth);

  const currentTab = state.tabDetails.find((tab) => tab.showStatus);
  const isGeneralTab = currentTab && currentTab.id === GENERAL;
  const isEmployeeInternalInfoTab = currentTab && currentTab.id === INTERNAL_INFO;
  const formStatus = state.jobProfile.formStatus;

  const handleNextStep = () => {
    dispatch({ type: NEXT_STEP });
    if (!isView) {
      handleSubmitFormData("next");
    }
  };

  const handlePreviousStep = () => {
    dispatch({ type: PREVIOUS_STEP });
  };

  const handleSubmitFormData = async (type: string) => {
    const { dropDownData, errors, tabDetails, emergencyContactErrors, workExperienceErrors, ...postData } = state;
    const formStatus = type === "submit" ? 1 : state.jobProfile.formStatus;
    Object.assign(postData, { 'id': id }, { 'formStatus': formStatus }, { 'competence': state.competence });

    if (
      state.general.firstName !== "" &&
      state.general.lastName !== "" &&
      state.general.email !== "" &&
      state.general.phoneNumber !== ""
    ) {
      const response = await ApiCall.service(CANDIDATE_CREATION, "POST", postData, true, M_IDENTITY_MANAGER);
      if (response?.status === 200) {
        if (type === 'draft') {
          CustomNotify({ type: 'success', message: "Candidate drafted successfully" });
          navigate(MANAGE_CANDIDATES);
        } else if (type === 'submit') {
          const formStatus = state.jobProfile.formStatus;
          const message = formStatus === 1 ? t("Candidate updated successfully") : t("Candidate created successfully");
          CustomNotify({ type: 'success', message: message });
          navigate(MANAGE_CANDIDATES);
          //todo creation
          const consultantId = state.general?.consultant && state.general?.consultant?.value;
          const todoData = {
            'name': 'candidate',
            'entityId': response?.data?.userId ?? null,
            'description': "Candidate registered successfully",
            'loginUserId': user?.userId,
            'userId': [consultantId],//HR is not confirmed
            'todoType': 'register'
          };
          await ApiCall.service(DYNAMIC_TODO_CREATION, 'POST', todoData, false, M_MASTER_DATA);
        } else if (type === "next") {
          if (currentTab?.id === DOCUMENTS) {
            const userId = response?.data?.userId;
            validateDocuments(userId);
          }
          navigate(`/candidate/create/${response.data.userId}`);
          // navigate(`/candidate/create/${response.data.userId}?tabId=${nextTab.id}`);
        }
      } else if (response?.success === false) {
        if (response.message.length > 0) {
          dispatch({
            type: UPDATE_TAB_ERROR,
            tabIndex: 0,
            error: true,
          });
          response?.message.forEach((msg: string) => {
            // Extract the field name from the message
            let fieldName = msg.split(" ")[0] ?? "";
            const field = fieldName
              .replace(/([A-Z])/g, ' $1') // Add space before each capital letter
              .replace(/^./, char => char.toUpperCase()) // Capitalize the first letter
              .replace(/\s+[A-Z]/g, char => char.toLowerCase()); // Lowercase letters after spaces

            const updatedMessage = msg.replace(fieldName, field);
            if (field) {
              dispatch({
                type: UPDATE_ERROR_FIELD,
                field: fieldName,
                error: updatedMessage,
              });
            }
          });
          CustomNotify({ type: 'error', message: "Please check some errors are there" });
        }
      }
    }
  }

  const handleDraft = () => {
    const update = state.tabDetails.map((tab: any, index: any) => ({
      ...tab,
      draft: true,
    }));

    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: update });
    const isValid = validateMandatoryFields(state, dispatch, 'draft');

    if (isValid) {
      handleSubmitFormData('draft');
    }
  };

  const handleSubmit = async () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: false,
    }));
    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: update });
    const isValid = validateMandatoryFields(state, dispatch, 'submit');
    if (isValid) {
      handleSubmitFormData('submit');
    }
  };

  const validateDocuments = async (userId: any) => {
    const functions = state.jobProfile?.desiredFunctions;
    const response = await ApiCall.service(
      FETCH_CANDIDATE_SALARY_TYPE,
      "POST",
      functions,
      true,
      M_MASTER_DATA
    );

    if (response?.data?.documentTypeIds && response?.data?.documentTypeIds?.length > 0) {
      const documentValues = state.documents?.map((item: any) => item?.category ? item?.category.value : null);
      const certificateValues = state.certificates?.map((item: any) => item?.attestType ? item?.attestType?.value : null);
      const docCondition = response?.data?.documentTypeIds?.every((document: any) => documentValues.includes(document.document_type_id));
      const certCondition = response?.data?.documentTypeIds?.every((document: any) => certificateValues.includes(document.document_type_id));
      if (!docCondition && !certCondition) {
        dispatch({
          type: UPDATE_TAB_ERROR,
          tabIndex: 4,
          error: true,
        });
        dispatch({
          type: UPDATE_ERROR_FIELD,
          field: "documentValidation",
          error: "Please make sure all the function related documents uploaded"
        });
        //todo creation
        const consultantId = state.general?.consultant && state.general?.consultant?.value;
        const todoData = {
          'name': 'candidate',
          'entityId': userId ?? null,
          'description': "Please upload function related required documents",
          'loginUserId': user?.userId,
          'userId': [consultantId],//HR is not confirmed
          'todoType': 'candidate document'
        };
        await ApiCall.service(DYNAMIC_TODO_CREATION, 'POST', todoData, false, M_MASTER_DATA);

      } else {
        dispatch({
          type: UPDATE_ERROR_FIELD,
          field: "documentValidation",
          error: ""
        });
      }
    }
  }

  return (
    <>
      <div className="row" style={isView ? { pointerEvents: "auto" } : {}}>
        <div className="col-md-6 align-self-center">
          {isGeneralTab && (
            <BackButton />
          )}
          {!isGeneralTab && (
            <Button
              type="button"
              title={t("BACK")}
              handleClick={handlePreviousStep}
              className="back-btn btn border-0 text-decoration-underline p-0"
            />
          )}
        </div>
        <div className="col-md-6 ">
          {!loading ? (!(isEmployeeInternalInfoTab && isView) &&
            <Button
              type="submit"
              title={isEmployeeInternalInfoTab ? t("Submit") : t("Next")}
              handleClick={isEmployeeInternalInfoTab ? handleSubmit : handleNextStep}
              className="float-end form-button ms-3 shadow-none"
            />
          ) : (
            <LoadingIcon
              iconType="bars"
              color="#e5007d"
              className="ms-auto mb-3"
              height={"3vw"}
              width={"3vw"}
            />
          )}
          {formStatus !== 1 && !isEmployeeInternalInfoTab && !isView && (
            <Button
              type="submit"
              title={t("Save as draft")}
              handleClick={handleDraft}
              className="float-end form-button shadow-none"
            />
          )}

        </div>
      </div>
    </>
  );
};

export default Navigation;