import { M_MASTER_DATA } from "constants/Constants";
import { Suspense, useEffect, useState } from "react";
import { MANAGE_BUSINESS_UNIT_CITIES } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { Option } from "utils/TypeAnnotations";
import { t } from "../translation/Translation";
import AccessControl from "services/AccessControl";
import { Link } from "react-router-dom";
import Title from "components/atoms/Title";
import FilterOrganism from "components/organism/FilterOrganism";
import { useDispatch, useSelector } from "react-redux";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import EditIcon from "static/images/EditIcon";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import LinkTo from "components/atoms/LinkTo";
import { PATH_CREATE_BUSINESS_UNIT_CITIES } from "constants/Paths";
import { selectAuth } from "features/auth/AuthSlice";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface ManageDataProps {
    id: number;
    businessUnits: string;
    cities: string;
}

const ManageBusinessUnitCities: React.FC = () => {
    const userData = useSelector(selectAuth);
    const [businessUnitList, setBusinessUnitList] = useState<Option[]>([]);
    const [loading, setLoading] = useState(true);
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState({
        totalPages: 0,
        totalRecords: 0,
        refresh: true,
        initialCall: true
    });
    const [manageData, setManageData] = useState<ManageDataProps[]>([]);

    const getSearchData = () => {
        return {
            businessUnitId: history?.filters?.businessUnitId ?? "",
        }
    };

    const filters = [
        { name: 'businessUnitId', fieldType: "multiSelect", options: businessUnitList, placeholder: "Business unit", filterType: 'search' },
    ];

    useEffect(() => {
        fetchBUCityDetails();
    }, [pageData?.refresh, pageData?.initialCall]);



    const fetchBUCityDetails = async () => {
        const data = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
            search: getSearchData(),
            initialCall: pageData?.initialCall,
        };
        const response = await ApiCall.service(
            MANAGE_BUSINESS_UNIT_CITIES,
            "POST",
            data,
            false,
            M_MASTER_DATA
        );
        if (response?.status === 200) {
            setBusinessUnitList(response?.data?.businessUnitList);
            setManageData(response?.data?.manageData);
            setPageData((prev: any) => ({ ...prev, totalPages: response?.data?.totalPages, totalRecords: response?.data?.totalRecords, initialCall: false }));
        }
    };

    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    return (
        <AccessControl
            requiredPermissions={[
                {
                    permission: "Business unit cities",
                    read: true,
                },
            ]}
            override={userData.isSuperAdmin}
            renderNoAccess={true}
        >
            <div>
                <Suspense
                    fallback={
                        <div className="text-center text-danger">{t("Loading")}</div>
                    }
                >
                    <Title title={t("Business unit cities")} />
                    <FilterOrganism
                        dispatch={dispatch}
                        history={history}
                        updatePageFilters={updatePageFilters}
                        filters={filters}
                        handleRefresh={handleRefresh}
                    />
                    <div className="position-relative tableMainWrapper">
                        <AccessControl
                            requiredPermissions={[
                                {
                                    permission: "Business unit cities",
                                    create: true,
                                },
                            ]}
                            override={userData.isSuperAdmin}
                        >
                            <div className="row">
                                <div className="ManageCreateBtn">
                                    <LinkTo
                                        pagelink="/create/business-unit-cities"
                                        title={t("Create business unit cities")}
                                        icon={faPlus}
                                    />
                                </div>
                            </div>
                        </AccessControl>
                        <div className="table-responsive tableSection">
                            <table className="table table-hover">
                                <thead>
                                    <tr className="TableHeader">
                                        <th className="align-middle" style={{ width: "30%" }}>
                                            {t("Business units")}
                                        </th>
                                        <th className="align-middle" style={{ width: "60%" }}>
                                            {t("Cities")}
                                        </th>
                                        <th>{t("Actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {manageData && manageData.length > 0 ? (
                                        manageData.map((item: ManageDataProps, index: number) => (
                                            <tr
                                                key={index}
                                                className="border mb-3 box-shadow align-middle"
                                            >
                                                <td className="text-break" data-label={t("Offices")}>
                                                    {item.businessUnits}
                                                </td>
                                                <td className="text-break" data-label={t("Cities")}>
                                                    {item.cities}
                                                </td>
                                                <td className="table-action-icons px-2">
                                                    <AccessControl
                                                        requiredPermissions={[
                                                            {
                                                                permission: "Business unit cities",
                                                                update: true,
                                                            },
                                                        ]}
                                                        override={userData.isSuperAdmin}
                                                    >
                                                        <Link
                                                            to={`${PATH_CREATE_BUSINESS_UNIT_CITIES}/${item.id}`}
                                                            className="btn p-0 border-0 me-2"
                                                            title={t("Edit")}
                                                        >
                                                            <EditIcon />
                                                        </Link>
                                                    </AccessControl>
                                                </td>
                                            </tr>
                                        ))
                                    ) : ("No records found"
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row backPadding">
                        <div className="col-md-6 align-self-center">
                            <Link
                                to="/config/settings"
                                className="back-btn text-decoration-underline"
                            >
                                {t("BACK")}
                            </Link>
                        </div>
                    </div>
                </Suspense>
                {
                    !loading && <div className="">
                        <PaginationWithPerPage
                            handleRefresh={handleRefresh}
                            dispatch={dispatch}
                            history={history}
                            pageData={pageData}
                        />
                    </div>
                }
            </div>
        </AccessControl>
    );
};

export default ManageBusinessUnitCities;
