import { t } from "pages/microservices/masterData/translation/Translation";
import { candidateFormData } from "./CandidateInterface";
import { emergencyContactInitialData, studiesInitialState, workExperienceInitialState } from "../helpers/CandidateHelperFunctions";

export const CandidateIntialState: candidateFormData = {
  tabDetails: [
    {
      id: "general",
      title: t("General"),
      showStatus: true,
      error: false,
      draft: false
    },
    {
      id: "studies",
      title: t("Studies"),
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "workExperience",
      title: t("Experience"),
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "jobProfile",
      title: t("Desired job"),
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "documents",
      title: t("Documents"),
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "competence",
      title: t("Competence"),
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "internalInfo",
      title: t("Internal info"),
      showStatus: false,
      error: false,
      draft: false
    }
  ],

  profileCard: {
    uploadCv: {
      id: null,
      accessUrl: "", // Initialize with an empty string
      fileName: "", // Initialize with an empty string
    },
    profilePicture: {
      id: null,
      accessUrl: "", // Initialize with an empty string
      fileName: "", // Initialize with an empty string
    }
  },
  competence: [],
  general: {
    ssn: '',
    contactPreference: null,
    selectionGroup: null,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    mobileNumber: '',
    dob: null,
    age: '',
    gender: null,
    shortCode: '',
    consultant: null,
    businessUnit: null,
    contractType: null,
    vat: '',
    companyName: '',
    tempAgency: '',
    placeOfBirth: '',
    countryOfBirth: null,
    nationality: null,
    ibanCountry: null,
    iban: '',
    bic: '',
    bankId: '',
    bankName: '',
    street: '',
    number: '',
    box: '',
    zipCode: '',
    country: null,
    city: '',
    sameAsResidence: 0,
    domStreet: '',
    domNumber: '',
    domBox: '',
    domZipCode: '',
    domCountry: null,
    domCity: '',
    initialLat: '',
    initialLn: '',
  },

  emergencyContactDetails: [emergencyContactInitialData],
  emergencyContactErrors: [
    {
      emergencyContactName: "",
      emergencyContactEmail: "",
      emergencyContactPhoneNumber: "",
    }
  ],

  others: {
    id: null,
    civilStatus: null,
    dependantSpouse: null,
    dependantChildren: undefined,
    // educationLevel: null,
    languageOfDocuments: null,
    drivingLicense: '',
    transport: null,
    additional: "",
    civilStateDate: null,
    partnerName: "",
    partnerDateOfBirth: null,
  },

  studies: [studiesInitialState],

  studyErrors: [
    {
      completed: "",
      educationLevel: ""
    }
  ],

  workExperience: [workExperienceInitialState],

  workExperienceErrors: [
    {
      name: '',
      email: '',
      phoneNumber: ''
    }
  ],

  jobProfile: {
    desiredSector: [],
    desiredCategory: [],
    desiredFunctions: [],
    desiredJobsInfo: '',
    maximunDistanceFromHome: 30,
    desiredRegimen: [],
    preferredEmployment: null,
    salaryExpectation: null,
    minimunGrossSalary: '',
    niceToHave: '',
    formStatus: 0,
    undesiredSector: [],
    undesiredFunction: []
  },

  certificates: [],

  europeanCitizen: {
    isEuropeanCitizen: 1,
    workPermit: {
      id: null,
      accessUrl: '',  // Initialize with an empty string
      fileName: '',   // Initialize with an empty string
    },
    from: null,
    to: null
  },

  documents: [],

  internalInfo: {
    active: 0,
    inflowChannel: null,
    externalReference: "",
    selectionPartner: null,
    blocked: 0,
    reasonForBlocking: "",
    blockedBy: null,
    infoBlocking: "",
    internalInfo: "",
  },

  dropDownData: {
    usersList: [],
    countriesList: [],
    contractTypeList: [],
    businessUnitList: [],
    selectionGroupList: [],
    contactPreferenceList: [],
    civilStatusList: [],
    educationLevelList: [],
    languagesList: [],
    transportList: [],
    additionalList: [],
    diplomaList: [],
    experienceList: [],
    companiesList: [],
    sectorList: [],
    pcCategoriesList: [],
    pcFunctionsList: [],
    regimenList: [],
    preferredEmploymentList: [],
    salaryExpectationsList: [],
    documentList: [],
    // attestTypeList: [],
    // groupList: [],
    // competence: [],
    // categoryList: [],
    pcCategoryInitialList: [],
    pcFunctionInitialList: [],
    selectionPartnerList: [],
    inflowChannelList: [],
    reasonForDepartureList: [],
    bankNameList: [],
    competenceOptions: [],
  },

  errors: {
    ssn: '',
    firstName: '',
    lastName: '',
    email: '',
    shortCode: '',
    phoneNumber: '',
    mobileNumber: '',
    countryOfBirth: '',
    businessUnit: '',
    iban: '',
    street: '',
    number: '',
    zipCode: '',
    city: '',
    country: '',
    drivingLicense: '',
    desiredFunctions: '',
    documentValidation: ''
  }
}
